import {makeStyles} from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  containerClassName: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    padding: '0 5px',
    marginLeft: '8px',
    borderRadius: '4px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    lineHeight: '12px',
    fontSize: '10px',
  },
  infoIconClassName: {
    width: '9px',
    marginLeft: '4px',
  }
}))
