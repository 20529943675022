import styled from '@emotion/styled'

export const Overlay = styled('div')`
  background-color: rgba(60, 60, 60, 0.77);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
`
