import {Trans, withNamespaces, WithNamespaces} from 'react-i18next'
import messages from '../../../../../../../assets/messages'
import {ManagerOffer} from '../../useOffers'
import {Column} from './components/Column'
import {Container} from './components/Container'
import {OfferName} from './components/OfferName'
import {OfferString} from './components/OfferString'
import {PerformanceFee} from './components/PerformanceFee'
import {LargeText} from './components/LargeText'
import {Amount, GreenAmount} from './components/GreenAmount'
import {getFormattedAmount} from '../../../../../../../common/utils'
import Button from '@material-ui/core/Button'
import {config} from '../../../../../../../config'
import {WithCopyToClipboard} from '../../../../../../Common/WithCopyToClipboard'
import {useParams} from 'react-router-dom'
import {FeeStructureHeader} from './components/FeeStructureHeader'
import {InfoTooltip} from './components/InfoTooltip'

type OfferTileProps = {
  offer: ManagerOffer;
} & WithNamespaces;

export const mapIntervalNameToI18nKeyPlural: Record<ManagerOffer['settings']['tradingInterval']['type'], {i18nKey: string, defaults: string}> = {
  'Days': messages.days,
  'Weeks': messages.weeks,
  'Months': messages.months,
  'CalendarMonths': messages.calendarMonths,
}

export const mapIntervalNameToI18nKeySingular: Record<ManagerOffer['settings']['tradingInterval']['type'], {i18nKey: string, defaults: string}> = {
  'Days': messages.day,
  'Weeks': messages.week,
  'Months': messages.month,
  'CalendarMonths': messages.calendarMonth,
}

const _OfferTile = ({offer, t}: OfferTileProps) => {
  const params = useParams<{accountId?: string}>()
  const {name} = offer
  const joinLink = `${config.frontendUrl}?pammManager=${params.accountId}&pammOffer=${offer.id}`
  return (
    <Container>
      <Column>
        <OfferName>
          {name}
        </OfferName>
        <OfferString>
          <Trans {...messages.investors} values={{activeInvestmentCount: offer.activeInvestmentCount}} />
        </OfferString>
      </Column>
      <Column>
        <FeeStructureHeader>
          <Trans {...messages.feeStructureHeader} />
          <InfoTooltip content={t('feeStructureHeaderTooltip')} />
        </FeeStructureHeader>
        <PerformanceFee fees={offer.settings.performanceFees} />
      </Column>
      <Column>
        <OfferString>
          <Trans {...messages.tradingInterval} />
        </OfferString>
        <OfferString>
          <LargeText>
            {offer.settings.tradingInterval.count}
            {' '}
            <Trans {...(offer.settings.tradingInterval.count === 1 ? mapIntervalNameToI18nKeySingular : mapIntervalNameToI18nKeyPlural)[offer.settings.tradingInterval.type]} />
          </LargeText>
        </OfferString>
      </Column>
      <Column>
        <OfferString>
          <Trans {...messages.minDeposit} />
        </OfferString>
        <OfferString>
          <GreenAmount>
            {getFormattedAmount({amount: offer.settings.minDeposit, currency: offer.currency})}
          </GreenAmount>
        </OfferString>
      </Column>
      <Column>
        <OfferString>
          <Trans {...messages.minWithdrawal} />
        </OfferString>
        <OfferString>
          <Amount>
            {getFormattedAmount({amount: offer.settings.minWithdrawal, currency: offer.currency})}
          </Amount>
        </OfferString>
      </Column>
      <Column>
        <OfferString>
          <Trans {...messages.minInitialInvestment} />
        </OfferString>
        <OfferString>
          <Amount>
            {getFormattedAmount({amount: offer.settings.minInitialInvestment, currency: offer.currency})}
          </Amount>
        </OfferString>
      </Column>
      <Column>
        <WithCopyToClipboard text={joinLink}>
          <Button variant="contained" color="primary">
            <Trans {...messages.copyLink} />
          </Button>
        </WithCopyToClipboard>
      </Column>
    </Container>
  )
}

export const OfferTile = withNamespaces()(_OfferTile)
