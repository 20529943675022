
import React from 'react'
import {flowRight as compose, isEmpty, map, filter, get, includes, size, find, orderBy, toUpper} from 'lodash'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import {withNamespaces, Trans} from 'react-i18next'
import messages from '../../../assets/messages'
import Button from '@material-ui/core/Button'
import {isMAMScSubAccount, isMAMAccount, isIntroducingBrokerAccount, isAffiliatesAccount, isMt5ForexAccount, getAccountSubtype,
  isBitnukAccount, isForexAccount, isCentAccount, getAccountLabel} from '../../../common/utils/accounts'
import {isMobile, isIos} from '../../../common/utils/browser'
import {AlertDialog} from '../../Common/Dialog'
import Typography from '@material-ui/core/Typography'
import AppContext from '../../Common/contexts/AppContext'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Amount from '../../Common/Amount'
import {config} from '../../../config'
import Mt5TradeModal from '../Mt5TradeModal'
import {accountTypes} from '@bdswiss/common-enums'
import {graphql} from 'react-apollo'
import {CLIENT_DATA_QUERY} from '../../../graphql/queries'
import {InnerAppContext} from '../../../common/types'

const styles = theme => ({
  tradeNowButton: {
    padding: isMobile() ? '5px 20px' : '10px 50px',
    minHeight: 'auto',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none'
    }
  },
  tradeNowLink:{
    cursor: 'pointer'
  },
  textLeft:{
    textAlign: 'left' as const
  },
  error:{
    color: theme.palette.red.color,
  },
  checkboxLabel: {
    padding: '10px 0',
  },
  alertBodyClass: {
    [theme.breakpoints.up('md')]: {
      minWidth: 600
    }
  }
})

class ForexTradeNow extends React.Component<any,any> {
  static propTypes = {
    children: PropTypes.any,
    classes: PropTypes.object.isRequired,
    alignLeft: PropTypes.bool,
  }
  static contextType = AppContext
  context!: InnerAppContext
    state = {
      showMobileTradeModal: false,
      showAccountsModal: false,
      accountSelected: '',
      showMt5Popup: false
    } as any

    onTrade(redirect?, demoAccounts?, liveAccounts?, mt5Accounts?) {
      const {account, history, accounts} = this.props
      const {selectedAccount} = this.state
      const {common: {appLinks}} = config
      const login = selectedAccount || get(account, 'login') || get(liveAccounts, '[0].login') || get(demoAccounts, '[0].login')
      const liveAccountsSize = size(liveAccounts)
      const demoAccountsSize = size(demoAccounts)

      if (mt5Accounts && accounts && size(mt5Accounts) === size(accounts)) {
        this.setState({showMt5Popup: true})
      } else {
        // temporary: until the mobile application will ready
        if (isMobile() && false) {
          const appLink = isIos() ? get(appLinks, 'ios') : get(appLinks, 'android')
          window.open(appLink, '_blank')
        } else {
          if (isEmpty(accounts) || redirect || liveAccountsSize === 1 || (!liveAccountsSize && demoAccountsSize === 1)) {
            history.push(`/trade/forex/${login}`)
          }
          else {
            this.setState({showAccountsModal: true})
          }
        }
      }
    }

    getLabel(account) {
      const {classes, t} = this.props
      const {selectAccountError} = this.state
      const {downloadTrader} = config
      const {locale} = this.context
      const accountType = find(accountTypes, {key: get(account, '__typename')})
      const downloadTraderInfo = get(downloadTrader, get(accountType, 'category') as any)

      return <React.Fragment>
        <Typography variant="subtitle1" className={selectAccountError ? classes.error: ''}>
          {`${get(getAccountSubtype(get(account, 'accountSubtype')), 'label') || getAccountLabel(account, locale) || ''} 
          ${toUpper(t(messages[account.isDemo ? 'demo' : 'live'].i18nKey, messages[account.isDemo ? 'demo' : 'live'].defaults))} ${get(downloadTraderInfo, 'shortLabel', '')} - ${account.remoteId}`}
        </Typography>
        <Typography variant="subtitle1" className={selectAccountError ? classes.error: ''}>
          <Amount
            value={account.balance} currency={account.currency} locale='en-US' classes={{
              root: classes.amount,
              currency: classes.amountCurrency,
              value: classes.amountValue,
              fraction: classes.amountFraction
            }} />
        </Typography>
      </React.Fragment>
    }

    render() {
      const {account, classes, type, alignLeft, buttonClass, accounts, t, sideBar, viewer} = this.props
      const {showAccountsModal, selectAccountError, selectedAccount, showMt5Popup} = this.state
      const {disabledWebtrader: disabledTrading} = config

      let renderedObject
      const mt5Accounts = orderBy(filter(accounts, (account) => isMt5ForexAccount(get(account, '__typename'))), ['balance'], ['desc'])
      const filteredAccounts = filter(accounts, (account) => !isAffiliatesAccount(account) && !isIntroducingBrokerAccount(account)
        && !includes(map(mt5Accounts, 'id'), account.id) && !isMAMAccount(account) && (isForexAccount(account) || isCentAccount(account))
        && !account.isArchived && !account.isViewOnly && !isBitnukAccount(account)
      )
      const liveAccounts = orderBy(filter(filteredAccounts, (account) => !account.isDemo), ['balance'], ['desc'])
      const demoAccounts = orderBy(filter(filteredAccounts, (account) => account.isDemo), ['balance'], ['desc'])
      const orderedAccounts = [...liveAccounts, ...demoAccounts]
      const buttonDisabled = viewer.fundManager || disabledTrading
      if (type === 'button') {
        const TradingButton = <span>
          <Button
            onClick={() => this.onTrade(false, demoAccounts, liveAccounts, mt5Accounts)}
            variant={sideBar ? 'text' : 'contained'}
            size="large"
            color="primary"
            disabled={buttonDisabled}
            className={classNames(classes.tradeNowButton, (alignLeft) ? classes.textLeft : '', buttonClass)}
          >
            <Trans {...messages.tradeNow} />
          </Button>
        </span>
        buttonDisabled
          ? renderedObject = <></>
          : renderedObject = TradingButton
      } else {
        renderedObject = <Typography
          variant='body2'
          onClick={() => this.onTrade()}
          color="primary"
          className={classNames(classes.tradeNowLink, (alignLeft) ? classes.textLeft : '')}
        >
          <Trans {...messages.tradeNow} />
        </Typography>
      }

      return (<Grid container>
        {((account && !isMAMScSubAccount(account) && !account.isArchived && !account.isViewOnly) || !isEmpty(accounts)) && renderedObject}
        {<AlertDialog
          open={showAccountsModal}
          onClose={() => this.setState({showAccountsModal: false})}
          title={t(messages.selectTradingAccount.i18nKey, messages.selectTradingAccount.defaults)}
          agreeText={t(messages.confirm.i18nKey, messages.confirm.defaults)}
          disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
          onAgree={() => this.setState({showAccountsModal: false}, () => {this.onTrade(true)})}
          alertBodyClass={classes.alertBodyClass}>
          <Grid container spacing={2}>
            {get(config, 'webtrader.MT5Msg') && <Grid item xs={12}>
              <Typography variant="body1"><Trans {...messages.noteAccessMt5} /></Typography>
            </Grid>}
            <Grid item xs={12}>
              <RadioGroup
                name="account"
                value={selectedAccount}
                onChange={(event) => this.setState({selectedAccount: event.target.value})}
              >
                {map(orderedAccounts, (account) =>
                  <FormControlLabel
                    classes={{label:classes.checkboxLabel}}
                    key={account.login} value={account.login}
                    control={<Radio color="primary" className={selectAccountError ? classes.error: ''}/>}
                    label={this.getLabel(account)} />
                )}
              </RadioGroup>
            </Grid>
          </Grid>
        </AlertDialog>}
        <Mt5TradeModal showMt5Popup={showMt5Popup} onClose={() => this.setState({showMt5Popup: false})}
          mt5Account={find(mt5Accounts, (account) => !account.isDemo && !account.isArchived && !account.isViewOnly)}/>
      </Grid>)
    }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading, viewer}}:any) => ({error, loading, viewer})
  }),
)(ForexTradeNow)
