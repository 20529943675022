import React, {ElementType, Ref} from 'react'
import Intl from 'intl' //TODO: try to use builtin intl
import PropTypes from 'prop-types'
import {keyBy} from 'lodash'
import NumberFormat, {NumberFormatProps, NumberFormatValues} from 'react-number-format'
import withStyles, {
  CSSProperties,
  WithStyles,
} from '@material-ui/core/styles/withStyles'
import FormControl from '@material-ui/core/FormControl'
import TextField, {TextFieldProps} from '@material-ui/core/TextField'
import {InputAdornment, InputBaseComponentProps, Theme} from '@material-ui/core'

const styles = (theme: Theme): Record<string, CSSProperties> => ({
  inputRoot: {
    fontWeight: theme.typography.fontWeightLight as number,
    lineHeight: 'normal',
  },
  inputRootJumbo: {
    fontSize: 85,
    fontWeight: theme.typography.fontWeightLight as number,
    lineHeight: 'normal',
    direction: 'ltr' as const,
  },
  inputAdornmentRoot: {
    color: theme.palette.grey[400],
    padding: 5,
  },
})

type AmountInputProps = {
  currency?: string;
  id?: string;
  jumbo?: boolean;
  label?: string;
  locale?: string;
  name?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => void;
  TextFieldProps?: TextFieldProps;
  value: string;
} & WithStyles<typeof styles>;


//TODO: refactor it. Match component types
type AmountFormatProps = {
  inputRef?: Ref<HTMLInputElement>
} & NumberFormatProps
export const AmountFormat = (props: AmountFormatProps) => {
  const {inputRef, onChange, value, ...otherProps} = props

  const handleOnValueChange = (values: NumberFormatValues): void => {
    const target = {
      value: values.value,
    } as unknown as EventTarget & HTMLInputElement

    // @ts-ignore
    onChange?.({target})
  }

  return (
    <NumberFormat
      {...otherProps}
      value={value}
      getInputRef={inputRef}
      onValueChange={handleOnValueChange}
    />
  )
}


AmountFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

const AmountInput = withStyles(styles, {withTheme: true})(
  (props: AmountInputProps) => {
    const {
      locale = 'en',
      classes,
      onChange,
      value,
      name,
      id,
      currency = 'EUR',
      jumbo,
      TextFieldProps,
    } = props
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    })
    const testValue = '100000.00'
    const valueFormattedToParts = keyBy(
      formatter.formatToParts(testValue),
      'type'
    )
    const valueFormatted = formatter.format(testValue)
    const formattedCurrency = valueFormattedToParts['currency'].value
    const decimalSeparator = valueFormattedToParts['decimal'].value
    const thousandSeparator = valueFormattedToParts['group'].value
    const currencyPrefixed = valueFormatted.indexOf(formattedCurrency) === 0
    const adornmentKey = currencyPrefixed ? 'startAdornment' : 'endAdornment'
    const adornmentProp = {
      [adornmentKey]: (
        <InputAdornment
          classes={{root: classes.inputAdornmentRoot}}
          position={'end'}
        >
          {' '}
          {formattedCurrency}{' '}
        </InputAdornment>
      ),
    }
    const amountFormatProps = {
      decimalSeparator,
      thousandSeparator,
      isNumericString: true,
    }
    const inputRootClass = jumbo ? classes.inputRootJumbo : classes.inputRoot

    return (
      <FormControl className={classes.formControl} fullWidth>
        <TextField
          id={id}
          name={name}
          value={value}
          onChange={(e) => onChange(e, e.target.value)}
          InputProps={{
            ...adornmentProp,
            inputComponent: AmountFormat as ElementType<InputBaseComponentProps> ,
            inputProps: {...amountFormatProps},
            placeholder: '0',
            classes: {root: inputRootClass},
          }}
          {...TextFieldProps}
        />
      </FormControl>
    )
  }
)

AmountInput.propTypes = {
  jumbo: PropTypes.bool,
  value: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  locale: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  TextFieldProps: PropTypes.object, // For Additional Text Field Props
}

export default AmountInput
