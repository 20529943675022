import React from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import Login from './components/Auth/Login'
import ForgotPassword from './components/Auth/ForgotPassword'
import NotFound from './components/NotFound'
import Migrate from './components/Migrate'
import App from './components/App'
import EntitySelection from './components/Auth/EntitySelection'
import RegisterStep1 from './components/Auth/Registration/RegisterStep1'
import RegisterStep2 from './components/Auth/Registration/RegisterStep2'
import RegisterStep3 from './components/Auth/Registration/RegisterStep3'
// import ChooseDocument from './components/Settings/Documents/ChooseDocument'
import Language from './components/Auth/Language'
import InitiateDeposit from './components/Payments/Deposits/InitiateDeposit'
import WebinarDialog from './components/Common/WebinarDialog'
import LoginVerification from './components/Auth/LoginVerification'
import {config} from './config'
import BannerRedirectLink from './components/RedesignedWLComponents/Lydian/BannerRedirectLink'
import DueDiligence from './components/Settings/Profile/DueDiligence'
import {OtpVerification} from './components/Auth/components/OtpVerification'

const {key, disabledRegistration} = config
let CustomLoginComponent
let CustomSignupComponent

switch (key) {
  case 'lydian':
    CustomLoginComponent = BannerRedirectLink
    CustomSignupComponent = BannerRedirectLink
    break
  default:
    CustomLoginComponent = Login
    CustomSignupComponent = RegisterStep1
}

const TopLevelRoutes = () => (
  <Switch>
    <Route path={'/settings/profile/due-diligence'} component={DueDiligence}/>
    <Route path={'/migrate'} component={Migrate}/>
    <Route exact path="/login" component={CustomLoginComponent}/>
    <Route exact path="/forgot-password" component={ForgotPassword}/>
    <Route exact path="/choose-entity" component={EntitySelection}/>
    <Route exact path="/register/step2" component={RegisterStep2}/>
    <Route exact path="/register/step3" component={RegisterStep3}/>
    {/*<Route exact path="/register/step3/:category" component={ChooseDocument} />*/}
    {!disabledRegistration && <Route exact path="/register" component={CustomSignupComponent}/>}
    {!disabledRegistration && <Route exact path="/register/:type?/:subType?" component={RegisterStep1}/> }
    <Route exact path="/reset-password/:resetPasswordToken" component={ForgotPassword}/>
    <Route exact path={'/language'} component={Language}/>
    <Route exact path={'/initiate-deposit'} component={InitiateDeposit}/>
    <Redirect exact from={'/reset-password'} to={'/forgot-password'}/>
    <Redirect exact from={'/register-step1'} to={'/register'}/>
    {/*<Redirect exact from={'/register-demo'} to={'/register/demo'} />*/}
    <Route exact path={'/webinar'} component={WebinarDialog}/>
    <Route exact path="/otp-verification" component={OtpVerification}/>
    <Route exact path="/login-verification" component={LoginVerification}/>
    <Route path="/" component={App}/>
    <Route component={NotFound}/>
  </Switch>
)

export default TopLevelRoutes
