import 'react-app-polyfill/ie9.js'
import React from 'react'
import ReactDOM from 'react-dom'
import {ApolloProvider} from 'react-apollo'
import {BrowserRouter} from 'react-router-dom'
import * as serviceWorker from './serviceWorker.js'
import {getApolloClient} from './graphql/utils.js'
import './common/utils/polyfill.js'

import AppProvider from './AppProvider'
import './index.css'
import './checkout.css'
import {ErrorBoundaryWrapper} from './components/ErrorBoundaryWrapper'


const apolloClient = getApolloClient()

ReactDOM.render(
  <ErrorBoundaryWrapper>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <AppProvider />
      </BrowserRouter>
    </ApolloProvider>
  </ErrorBoundaryWrapper>,
  document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
