import React from 'react'
import {graphql} from 'react-apollo'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import {withNamespaces, Trans} from 'react-i18next'
import {get, omit, flowRight as compose} from 'lodash'
import messages from '../../../assets/messages'
import {ACCOUNTS_QUERY} from '../../../graphql/queries'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import {DesktopWindows, Smartphone} from '@material-ui/icons'
import {config} from '../../../config'
import Images from '../../Common/Images'
import Paper from '@material-ui/core/Paper'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import ClientNotificationBar from '../ClientNotificationBar'
import PageSubTitle from '../../Common/PageSubTitle'
import {isIos, isAndroid, isMobile} from '../../../common/utils/browser'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import AppContext from '../../Common/contexts/AppContext'
import {accountTypes, mt4Servers, whiteLabels} from '@bdswiss/common-enums'
import {isWhiteLabel} from '../../../common/utils'
import {getForcedServerName} from '../../../common/utils/accounts'
import {Loading} from '../../Common/Loading'
import {InnerAppContext} from '../../../common/types'
import {WithCopyToClipboard} from '../../Common/WithCopyToClipboard'

const styles = (theme) => ({
  textBlue: {
    color: `${theme.palette.primary.main}`,
  },
  listItem: {
    paddingLeft: '0px',
  },
  icon: {
    margin: '0',
    width: '24px',
    height: '24px',
  },
  informationLink: {
    paddingTop: '20px',
  },
  loginText: {
    color: theme.palette.secondary.main,
    fontSize: '16px',
  },
  loginDetails: {
    fontWeight: 300,
    lineHeight: '2.5rem',
  },
  textItalic: {
    fontStyle: 'italic',
  },
  textCenter: {
    textAlign: 'center' as const,
  },
  mt4download: {
    padding: '1.5rem 1.5rem 0',
    maxWidth: '100%',
  },
  tableCell: {
    paddingLeft: 0,
    borderBottom: 'none',
    paddingBottom: 9,
    paddingTop: 5,
  },
  table: {
    marginBottom: 40,
    marginTop: 15,
  },
  list: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 5,
    },
  },
})

class ForexAccountDownloadTrader extends React.Component<any, any> {
  static contextType = AppContext;
  context!: InnerAppContext;
  state = {
    arrowRef: null,
  };

  handleArrowRef = (node) => {
    this.setState({
      arrowRef: node,
    })
  };

  getLink = (account, config, item) => {
    const {downloadTrader} = config
    const traderLinks =
      accountTypes[account.__typename] &&
      downloadTrader[accountTypes[account.__typename].category]
        ? downloadTrader[accountTypes[account.__typename].category]
        : downloadTrader.default

    if (item === 'iosAndroid' && isIos()) {
      return traderLinks.appUrls.ios
    } else if (item === 'iosAndroid' && isAndroid()) {
      return traderLinks.appUrls.android
    } else {
      return traderLinks.Links[item]
    }
  };

  getIcon = (item, classes) => {
    switch (item) {
      case 'windows':
        return (
          <img
            className={classes.icon}
            alt="windows"
            src={Images['windows-brands.svg']}
          />
        )
      case 'iosAndroid':
        return <Smartphone />
      case 'mac':
        return (
          <img
            className={classes.icon}
            alt="mac"
            src={Images['apple-brands.svg']}
          />
        )
      default:
        return <DesktopWindows />
    }
  };

  render() {
    const {classes, account, t, loading} = this.props
    const {downloadTrader, brand} = config
    const {locale, companyObject} = this.context
    if (loading) return <Loading />
    const category = accountTypes[account.__typename].category
    const traderConfig =
      accountTypes[account.__typename] && downloadTrader[category]
        ? downloadTrader[category]
        : downloadTrader.default
    const links = !isMobile()
      ? omit(traderConfig.Links, 'iosAndroid')
      : traderConfig.Links

    const {forcedServerName, forcedServerExampleImage} = getForcedServerName(
      account,
      Images
    )
    const whiteLabel = isWhiteLabel()

    const {key} = config

    const fxmt4SupportedCountries = ['bdSwissMauritius', 'tauroMarkets']

    const serverName = forcedServerName ||
      (account.server &&
      (!whiteLabel ||
        (whiteLabel && get(whiteLabels[brand], 'internal')))
        ? get(
          mt4Servers[account.server],
          `name[${companyObject['key']}]`
        )
        : account.isDemo
          ? traderConfig.serverName.demo
          : whiteLabel && !get(whiteLabels[brand], 'internal')
            ? get(traderConfig.serverName, account.server)
            : traderConfig.serverName.real)

    const imageSrc = forcedServerExampleImage ||
      (account.isDemo
        ? Images[traderConfig.exampleImages.demo]
        : Images[traderConfig.exampleImages[account.server]] ||
          Images[traderConfig.exampleImages.real])

    return (
      <Grid container spacing={3}>
        <ClientNotificationBar account={account} />
        <Grid item sm={12} md={6}>
          <PageSubTitle>
            <Trans
              {...messages.downloadMT4Subtitle}
              values={{product: traderConfig.shortLabel}}
            />
          </PageSubTitle>
          <List className={classes.list}>
            {Object.keys(links).map((item, index) => (
              <a
                href={this.getLink(account, config, item)}
                key={index}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItem className={classes.listItem}>
                  <ListItemIcon
                    className={classNames(classes.textBlue, classes.icon)}
                  >
                    {this.getIcon(item, classes)}
                  </ListItemIcon>
                  {fxmt4SupportedCountries.includes(key) && item === 'web' ? (
                    <div>
                      <ListItemText
                        inset
                        primary={
                          <Trans
                            {...messages['webtraderMT4']}
                            values={{product: traderConfig.shortLabel}}
                          />
                        }
                        className={classes.textBlue}
                        disableTypography={true}
                      />
                      <ListItemText
                        inset
                        primary={
                          <Trans
                            {...messages[item]}
                            values={{product: traderConfig.shortLabel}}
                          />
                        }
                        className={classes.textBlue}
                        disableTypography={true}
                      />
                    </div>
                  ) : (
                    <ListItemText
                      inset
                      primary={
                        <Trans
                          {...messages[item]}
                          values={{product: traderConfig.shortLabel}}
                        />
                      }
                      className={classes.textBlue}
                      disableTypography={true}
                    />
                  )}
                </ListItem>
              </a>
            ))}
          </List>
          {traderConfig.downloadTraderInfo && (
            <Typography variant="body1" className={classes.informationLink}>
              <a
                className={classNames(classes.textBlue, classes.loginDetails)}
                href={traderConfig.downloadTraderInfo.replace('{lang}', locale)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Trans
                  {...messages.mt4MoreInfo}
                  values={{product: traderConfig.shortLabel}}
                />
              </a>
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <PageSubTitle>
            {t(messages.loginDetails.i18nKey, messages.loginDetails.defaults)}
          </PageSubTitle>
          <Grid container spacing={3}>
            <Table className={!isMobile() ? classes.table : ''}>
              <TableBody>
                <TableRow>
                  <TableCell
                    className={classNames(classes.tableCell, classes.loginText)}
                  >
                    {t(messages.login.i18nKey, messages.login.defaults)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <WithCopyToClipboard text={account.login}>
                      <Typography variant="body1">
                        {account.login}
                      </Typography>
                    </WithCopyToClipboard>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className={classNames(classes.tableCell, classes.loginText)}
                  >
                    {t(messages.password.i18nKey, messages.password.defaults)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      variant="body1"
                      className={classNames(
                        classes.textItalic,
                        classes.loginDetails
                      )}
                    >
                      *<Trans {...messages.yourPasswordText} />*
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className={classNames(classes.tableCell, classes.loginText)}
                  >
                    {t(messages.server.i18nKey, messages.server.defaults)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <WithCopyToClipboard text={serverName}>
                      <Typography
                        variant="body1"
                        className={classes.loginDetails}
                      >
                        {serverName}
                      </Typography>
                    </WithCopyToClipboard>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {!isMobile() && imageSrc && (
              <Paper>
                <img
                  alt="download"
                  className={classes.mt4download}
                  src={imageSrc}
                />
                <Typography
                  variant="body1"
                  className={classNames(
                    classes.textItalic,
                    classes.textCenter,
                    classes.loginDetails
                  )}
                >
                  <Trans
                    {...messages.mt4Example}
                    values={{product: traderConfig.shortLabel}}
                  />
                </Typography>
              </Paper>
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {error, loading}, data, ownProps: {match}}: any) => {
      const {accountId} = match.params
      const account = get(data, 'viewer.accounts', []).find(
        (account) => account.id === Number(accountId)
      )
      return {
        error,
        loading,
        account,
      }
    },
  })
)(ForexAccountDownloadTrader)
