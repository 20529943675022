export const styles = theme => ({
  affiliate: {
    color: theme.palette.yellow.color,
    backgroundColor:  theme.palette.lightyellow.color,
  },
  availableAccounts: {
    position: 'absolute' as const,
    [theme.direction === 'rtl' ? 'left' : 'right']: 10,
    top: 10,
    color: theme.palette.primary.main,
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
  },
  badgeDiv:{
    paddingTop: 4
  },
  cardRoot: {
    padding: 20,
    margin: '20px 0px',
    cursor: 'pointer',
    position: 'relative' as const,
  },
  forexMt4: {
    color: theme.palette.red.color,
    backgroundColor:  theme.palette.lightred.color,
  },
  forexMt5: {
    color: theme.palette.red.color,
    backgroundColor:  theme.palette.lightred.color,
  },
  label:{
    padding: '6px 0px'
  },
  ib: {
    color: theme.palette.green.color,
    backgroundColor:  theme.palette.lightgreen.color,
  },
  pamm: {
    backgroundColor: '#fff8e1',
    color: '#ff9800',
  },
  pointer:{
    cursor: 'pointer'
  },
  typeBadge: {
    height: 40,
    width: 40,
    fontSize: 15,
    lineHeight: '40px',
    borderRadius: 20,
    textTransform: 'uppercase' as const,
    textAlign: 'center' as const,
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 18,
  },
})


