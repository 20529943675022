import React from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import Accounts from './index'
import LydianRootAccounts from '../RedesignedWLComponents/Lydian/RootAccounts'
import AccountView from './AccountView'
import AccountVerification from './AccountVerification/index'
import AddAccount from '../Accounts/AddAccount'
import {config} from '../../config'
import LydianAccountView from '../RedesignedWLComponents/Lydian/LydianAccountView'
import {PammManagerSettingsView} from './PammManagerSettingsView'

const {key} = config
let AccountsWL
switch (key) {
  case 'lydian':
    AccountsWL = LydianRootAccounts
    break
  default:
    AccountsWL = Accounts
}

const routes = ({location :{search}, match: {path}}) =>
  (path === '/' //@ts-ignore todo: fix, most likely it doesn't work
    ? <Redirect to={{pathname:'/accounts', query:{search}}} />
    : (
      <Switch>
        <Route path={`${path}/add-account`} component={AddAccount} />
        <Route path={`${path}/verification`}  component={AccountVerification}/>
        <Route path={`${path}/verification/:accountId`}  component={AccountVerification} />
        <Route path={`${path}/pammManagerSettings/:accountId`} component={PammManagerSettingsView} />
        <Route path={`${path}/:accountId/external`} component={LydianAccountView} />
        <Route path={`${path}/:accountId`} component={AccountView} />
        <Route exact path={`${path}`} component={AccountsWL} />
      </Switch>
    )
  )

export default routes
