import {useEffect, useState} from 'react'
import {backendGetRequest} from '../../../common/utils/requests'

type AcceptedOffer = {
  id: number,
  accountId: number
}

const fetchAcceptedQueries = async () => {
  const acceptedOffersResponse = await backendGetRequest('/api/v2/dashboard/pamm/investors/offers/accepted', true)
  if (!acceptedOffersResponse.ok) {
    throw new Error(`Failed to fetch accepted offers ${acceptedOffersResponse.status} ${acceptedOffersResponse.statusText}`)
  }

  return await acceptedOffersResponse.json()
}

export const useAcceptedOffers = () => {
  const [error, setError] = useState<Error | null>(null)
  const [loading, setLoading] = useState(true)
  const [acceptedOffers, setAcceptedOffers] = useState<AcceptedOffer[]>([])

  useEffect(() => {
    fetchAcceptedQueries().then((data) => {
      setLoading(false)
      setAcceptedOffers(data.acceptedOffers)
    }, (error) => {
      // eslint-disable-next-line no-console
      console.error('Error fetching me:', error)
      setLoading(false)
      setError(error)
    })
  }, [])

  return {error, loading, acceptedOffers}
}
