/*@ts-check*/
import {InputBaseComponentProps} from '@material-ui/core'
import TextField, {TextFieldProps} from '@material-ui/core/TextField'
import {FunctionComponent} from 'react'
import {AccountSearchSelectProps} from '..'

function inputComponent({inputRef, ...props}) {
  return <div ref={inputRef} {...props} />
}

type SelectProps = {
  textFieldProps: TextFieldProps
}   & Pick<AccountSearchSelectProps, 'classes'>


type ControlProps = {
  selectProps: SelectProps
} & InputBaseComponentProps

export function Control(props: ControlProps) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent: inputComponent as FunctionComponent<InputBaseComponentProps>,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}
