/*@ts-check*/
import Typography from '@material-ui/core/Typography'
import {AccountSearchSelectProps} from '..'
import {PropsWithChildren} from 'react'

type PlaceholderProps = {
  selectProps: Pick<AccountSearchSelectProps, 'classes'>
  innerProps: Parameters<typeof Typography>
}

export function Placeholder(props: PropsWithChildren<PlaceholderProps>) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      variant={'body2'}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}
