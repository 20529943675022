import styled from '@emotion/styled'

export const Container = styled.div`
  align-items: center;
  background-color: #E8F7FE;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 0 0 0 2rem;
  padding: 1.5rem;
`
