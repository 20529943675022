import {makeStyles} from '@material-ui/core'

export const useStyles = makeStyles({
  infoRowClassName: {
    marginTop: '12px',
    fontSize: '14px',
    lineHeight: '22px',
  },
  okButtonClassName: {
    width: '100%',
    textTransform: 'uppercase',
    marginTop: '12px',
  }
})
