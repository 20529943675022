import {accountTypes, IAccountTypeEnum, mt4Servers, whiteLabels} from '@bdswiss/common-enums'
import {Account, BaseForexAccount} from '../../../graphql/types'
import invariant from 'tiny-invariant'
import {getForcedServerName} from '../../../common/utils/accounts'
import {findCompany, isWhiteLabel} from '../../../common/utils'
import {config} from '../../../config'
import {get} from 'lodash'

export const getMetadataFromAccount = (account: Account): Record<string, unknown> =>
  'metadata' in account ? JSON.parse(account.metadata as string) : {}


export const getAccountType = ({__typename}: Account): IAccountTypeEnum => {
  const accountType = accountTypes[__typename ?? '']

  invariant(accountType, `Unknown account type: ${__typename}`)

  return accountType
}

export const isBaseForexAccount = (account: Account): account is BaseForexAccount => 'accountType' in account && 'server' in account && ['forexMt4', 'forexMt5'].includes((account.accountType as any).category)

export const isMt5 = (account: Account): boolean => 'accountType' in account && 'forexMt5' === (account.accountType as any).category
export const isMt4 = (account: Account): boolean => 'accountType' in account && 'forexMt4' === (account.accountType as any).category

export const serverName = (account: Account): string => {
  if (!isBaseForexAccount(account)) return ''
  if (!account.__typename) return ''
  if (!account.server) return ''

  const {downloadTrader, key} = config

  const {forcedServerName} = getForcedServerName(account)
  const whiteLabel = isWhiteLabel()
  const traderConfig = isBaseForexAccount(account) && (accountTypes[account.__typename] ? downloadTrader[accountTypes[account.__typename].category] : downloadTrader.default)
  const companyObject = findCompany()

  return forcedServerName || ((account.server && (!whiteLabel|| (whiteLabel && get(whiteLabels[key], 'internal')))) ? get(mt4Servers[account.server], `name[${companyObject['key']}]`):
    (account.isDemo ? traderConfig.serverName.demo : whiteLabel && !get(whiteLabels[key], 'internal') ? get(traderConfig.serverName, account.server) : traderConfig.serverName.real))
}
