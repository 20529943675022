import React from 'react'
import {flowRight as compose} from 'lodash'
import PropTypes from 'prop-types'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import withWidth from '@material-ui/core/withWidth'
import {AlertDialog} from '../../Common/Dialog'
import messages from '../../../assets/messages'
import {config} from '../../../config'

const styles = {
  subtitleGrid: {
    padding: 8,
  },
  styledText: {
    whiteSpace: 'pre-line' as const,
    fontSize: 16,
    lineHeight: 1.5,
  },
  subtitle1Strong: {
    fontWeight: 500,
    textDecoration: 'underline',
  },
}

const EnableAcademyAccessModal = (props) => {
  const {open, classes, t, onSubmit, onClose} = props
  const {supportEmail} = config

  return (
    <AlertDialog
      open={open}
      agreeText={t(messages.contactSupport.i18nKey, messages.contactSupport.defaults)}
      onAgree={() => onSubmit()}
      disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
      onDisagree={() => onClose()}
    >
      <Grid container direction="row" alignContent="center" justifyContent="center">
        <Grid item xs={12} className={classes.subtitleGrid}>
          <p className={classes.styledText}>
            <Trans
              {...messages.contactToEnableTradingAcademy}
              components={[<a href={`mailto:${supportEmail}`} className={classes.subtitle1Strong}>Support Team</a>]}
              values={{supportEmail}}
            />
          </p>
        </Grid>
      </Grid>
    </AlertDialog>
  )
}

EnableAcademyAccessModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withWidth(),
  withNamespaces(),
)(EnableAcademyAccessModal)
