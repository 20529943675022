import {depositVendors, safechargePaymentTypes, cardpayPaymentTypes} from '@bdswiss/common-enums'
import DLocalProvider from './DLocalProvider'
import KalixaProvider from './KalixaProvider'
import PaypalProvider from './PaypalProvider'
import CryptoProvider from './CryptoProvider'
import RPNPayProvider from './RPNPayProvider'
import KlapayProvider from './KlapayProvider'
import PayzoffProvider from './PayzoffProvider'
import DusupayProvider from './DusupayProvider'
import FuntoPayProvider from './FuntoPayProvider'
import Help2PayProvider from './Help2PayProvider'
import NetellerProvider from './NetellerProvider'
import NganLuongProvider from './NganLuongProvider'
import BridgerPayProvider from './BridgerPayProvider'
import CreditCardProvider from './CreditCardProvider'
import RPNPayUSDTProvider from './RPNPayUSDTProvider'
import JMFinancialProvider from './JMFinancialProvider'
import PayRetailersProvider from './PayRetailersProvider'
import CardpayCryptoProvider from './CardpayCryptoProvider'
import GenericIFramedProvider from './GenericIFramedProvider'
import GenericRedirectProvider from './GenericRedirectProvider'
import BankWireTransferProvider from './BankWireTransferProvider'
import GenericSelfPostingProvider from './GenericSelfPostingProvider'
import MifinityProvider from './MifinityProvider'

const implementedProviders = [
  'kalixa', 'skrill', 'wirecard', 'fasapay', 'dlocal',
  'dotpay', 'zotapay', 'astropay', 'paytrust', 'coinbase',
  'globepay', 'funtopay', 'payzoff', 'safecharge', 'cardpay',
  'bankWire', 'rpnpay', 'isignthis', 'rave', 'paymentAsia',
  'easyeft', 'nganLuong', 'neteller', 'thunderxpay', 'paypal', 'cashu', 'directa24',
  'bridgerPay', 'help2pay', 'appex', 'checkout', 'dusupay', 'powercash21', 'btcproxy', 'klarpay', 'payRetailers',
  'jmFinancial', 'volt', 'mifinity', 'echelonpay', 'paypugs', 'lionPay', 'vaultspay', 'coinpayments',
  'perfectmoney', 'azulpay', 'openup', 'ikajo', 'pwclick', 'eupaymentz', 'paymentcenter', 'cpay', 'bitolo',
  'jmfinancialkw', 'enovipay', 'localpay'
]

export const PaymentProviderFactory = {
  get: (providerKey, paymentKey) => {
    switch (providerKey) {
      case depositVendors.dotpay.value:
      case depositVendors.fasapay.value:
      case depositVendors.globepay.value:
      case depositVendors.paymentAsia.value:
      case depositVendors.cashu.value:
      case depositVendors.appex.value:
      case depositVendors.thunderxpay.value:
      case depositVendors.lionPay.value:
      case depositVendors.perfectmoney.value:
      case depositVendors.paymentcenter.value:
        return GenericSelfPostingProvider
      case depositVendors.zotapay.value:
      case depositVendors.skrill.value:
      case depositVendors.astropay.value:
      case depositVendors.directa24.value:
      case depositVendors.powercash21.value:
      case depositVendors.easyeft.value:
      case depositVendors.volt.value:
      case depositVendors.echelonpay.value:
      case depositVendors.paypugs.value:
      case depositVendors.vaultspay.value:
      case depositVendors.coinpayments.value:
      case depositVendors.azulpay.value:
      case depositVendors.openup.value:
      case depositVendors.ikajo.value:
      case depositVendors.pwclick.value:
      case depositVendors.eupaymentz.value:
      case depositVendors.cpay.value:
      case depositVendors.bitolo.value:
      case depositVendors.jmfinancialkw.value:
      case depositVendors.localpay.value:
      case depositVendors.enovipay.value:
        return GenericRedirectProvider
      case depositVendors.funtopay.value:
        return FuntoPayProvider
      case depositVendors.dinpay.value:
      case depositVendors.isignthis.value:
      case depositVendors.rave.value:
        return GenericIFramedProvider
      case depositVendors.coinbase.value:
      case depositVendors.btcproxy.value:
        return CryptoProvider
      case depositVendors.dlocal.value:
        return DLocalProvider
      case depositVendors.kalixa.value:
      case depositVendors.paytrust.value:
        return KalixaProvider
      case depositVendors.cardpay.value:
        if (paymentKey === cardpayPaymentTypes.creditCard.value) {
          return CreditCardProvider
        } else if (paymentKey === cardpayPaymentTypes.bitcoin.value) {
          return CardpayCryptoProvider
        } else {
          return GenericRedirectProvider
        }
      case depositVendors.wirecard.value:
      case depositVendors.checkout.value:
        return CreditCardProvider
      case depositVendors.safecharge.value:
        return paymentKey === safechargePaymentTypes.creditCard.value ?
          CreditCardProvider : GenericIFramedProvider
      case depositVendors.bankWire.value:
        return BankWireTransferProvider
      case depositVendors.payzoff.value:
        return PayzoffProvider
      case depositVendors.nganLuong.value:
        return NganLuongProvider
      case depositVendors.neteller.value:
        return NetellerProvider
      case depositVendors.paypal.value:
        return PaypalProvider
      case depositVendors.help2pay.value:
        return Help2PayProvider
      case depositVendors.bridgerPay.value:
        return BridgerPayProvider
      case depositVendors.rpnpay.value:
        return paymentKey === 'USDT' ? RPNPayUSDTProvider : RPNPayProvider
      case depositVendors.dusupay.value:
        return DusupayProvider
      case depositVendors.payRetailers.value:
        return PayRetailersProvider
      case depositVendors.klarpay.value:
        return KlapayProvider
      case depositVendors.jmFinancial.value:
        return JMFinancialProvider
      case depositVendors.mifinity.value:
        return MifinityProvider
      default:
        return null
    }
  },
  hasImplementation: (providerKey) => implementedProviders.includes(providerKey)
}
