import {Info} from '@material-ui/icons'
import {ManagerOffer} from '../../../Accounts/PammManagerSettingsView/components/PammManagerOffers/useOffers'
import {FeeStructureTitle} from './components/FeeStructureTitle'
import {Trans} from 'react-i18next'
import messages from '../../../../assets/messages'
import Tooltip from '@material-ui/core/Tooltip'
import {Row} from '../Row'
import {Column} from '../Column'
import {Label} from '../Label'
import {Separator} from '../Separator'
import {getFormattedAmount} from '../../../../common/utils'
import {OfferValue} from './components/OfferValue'

type FeeStructureProps = {
  offer: ManagerOffer
}

export const FeeStructure = ({offer}: FeeStructureProps) => (
  <>
    <FeeStructureTitle>
      <Trans {...messages.feeStructureTitle} />
      <Tooltip title={<Trans {...messages.feeStructureTooltip} />} arrow placement='top' id="fee-structure-tooltip">
        <Info fontSize='small'/>
      </Tooltip>
    </FeeStructureTitle>
    <Row>
      <Column>
        <Label>
          <Trans {...messages.equity} />
        </Label>
      </Column>
      <Column>
        <Label>
          <Trans {...messages.performanceFees} />
        </Label>
      </Column>
    </Row>
    <Separator />
    <Row>
      <Column>
        {offer.settings.performanceFees.levels.map(fee =>
          <OfferValue key={fee.level}>
            {getFormattedAmount({amount: fee.level, currency: offer.currency})}
          </OfferValue>
        )}
      </Column>
      <Column>
        {offer.settings.performanceFees.levels.map(fee =>
          <OfferValue key={fee.level}>
            {fee.value}%
          </OfferValue>
        )}
      </Column>
    </Row>
  </>
)
