import styled from '@emotion/styled'

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 3.5rem;

  & > button {
    width: 274px;
  }
`
