import {Theme} from '@material-ui/core'

export const styles = (theme: Theme) => ({
  error: {
    color: theme.palette.red.color
  },
  checkboxLabel: {
    fontSize: 14,
    lineHeight: 1.5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  link: {
    color:theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 400
  },
  closeIcon: {
    position: 'absolute' as const,
    top: 5,
    right: 5,
    color: theme.palette.grey[400],
    cursor: 'pointer'
  },
  container: {
    position: 'relative' as const
  },
  textCenter: {
    textAlign: 'center' as const
  }
})
