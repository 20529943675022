import {Account} from '../../../../../graphql/types'
import {Action, ActionType} from './actions'
import {isApprovedAccount} from '../../../../../common/utils/accounts'

export type State = {
  account: Account;
  showIbAccountPendingPopup: boolean;
}

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.Click: {
      const {account} = state
      if (!isApprovedAccount(account)) {
        return {
          ...state,
          showIbAccountPendingPopup: true,
        }
      }
      return state
    }
    case ActionType.CloseModal: {
      return {
        ...state,
        showIbAccountPendingPopup: false,
      }
    }
    default: {
      return state
    }
  }
}
