import styled from '@emotion/styled'

export const StepText = styled.div`
  color: #908F8F;
  font-size: 14px;
  margin-bottom: 1rem;

  & > a {
    color: #007AFF;
    cursor: pointer;
  }

  & > b {
    color: black;
  }
`
