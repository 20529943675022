import {withNamespaces, WithNamespaces} from 'react-i18next'
import {PammManagerSettingsTitle} from './components/Title'
import PageBody from '../../Common/PageBody'
import {PammManagerOffers} from './components/PammManagerOffers'

const _PammManagerSettingsView = ({t}: WithNamespaces) => (
  <>
    <PammManagerSettingsTitle />
    <PageBody>
      <PammManagerOffers/>
    </PageBody>
  </>
)


export const PammManagerSettingsView = withNamespaces()(_PammManagerSettingsView)
