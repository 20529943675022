import {ReactNode} from 'react'
import {Overlay} from '../components/shared/Overlay'
import {SuccessModalContainer} from './components/SuccessModalContainer'
import {CloseButton} from '../components/shared/CloseButton'
import {TickIcon} from './components/TickIcon'
import {SuccessModalTitle} from '../components/shared/SuccessModalTitle'
import {SuccessModalContent} from '../components/shared/SuccessModalContent'

type MfaSuccessModalProps = {
  content: ReactNode;
  onClose: () => void;
  title: ReactNode;
}

export const MfaSuccessModal = ({content, onClose, title}: MfaSuccessModalProps) => (
  <>
    <Overlay onClick={onClose} />
    <SuccessModalContainer>
      <CloseButton onClick={onClose} />
      <TickIcon />
      <SuccessModalTitle>{title}</SuccessModalTitle>
      <SuccessModalContent>{content}</SuccessModalContent>
    </SuccessModalContainer>
  </>

)
