import {useParams} from 'react-router-dom'
import {PammOfferTitle} from './components/Title'
import {useOffers} from '../Accounts/PammManagerSettingsView/components/PammManagerOffers/useOffers'
import {Trans} from 'react-i18next'
import {Info} from './components/Info'
import messages from '../../assets/messages'
import Loading from '../Common/Loading'
import {Row} from './components/Row'
import {Column} from './components/Column'
import {OfferName} from './components/OfferName'
import {FeeStructure} from './components/FeeStructure'
import {VerticalSpace} from './components/VerticalSpace'
import {Conditions} from './components/Conditions'
import {PasswordForm} from './components/PasswordForm'
import {useEffect} from 'react'
import {useAcceptedOffers} from './hooks/useAcceptedOffers'
import {AlreadyAccepted} from './components/AlreadyAccepted'

const clearCookie = (name: string) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;Path=/;`
}

export const PammOffer = () => {
  const params = useParams<{ pammManagerId: string, pammOfferId: string }>()
  const {loading: loadingOffers, offers} = useOffers(Number(params.pammManagerId))
  const {loading: loadingAcceptedOffers, acceptedOffers} = useAcceptedOffers()
  const offer = (offers ?? []).find(offer => offer.id === Number(params.pammOfferId))
  const alreadyAcceptedOffer = acceptedOffers?.find(acceptedOffer => acceptedOffer.id === offer?.id)

  const loading = loadingOffers || loadingAcceptedOffers

  useEffect(() => {
    clearCookie('pammOffer')
  }, [])

  if (loading) return <Loading />

  return (
    <>
      <PammOfferTitle />
      <Info>
        <Trans {...messages.linkReuseInfo} />
      </Info>
      {offer && (
        <>
          <Row>
            <Column>
              <OfferName>{offer.name}</OfferName>
              <FeeStructure offer={offer} />
              <VerticalSpace />
              <Conditions offer={offer} />
            </Column>
            <Column>
              <VerticalSpace />
              {!alreadyAcceptedOffer && <PasswordForm />}
              {alreadyAcceptedOffer && <AlreadyAccepted pammId={alreadyAcceptedOffer.accountId} />}
            </Column>
          </Row>
        </>
      )}
    </>
  )
}
