import styled from '@emotion/styled'

export const ButtonArea = styled('div')`
  display: flex;
  justify-content: center;

  & > button {
    background-color: rgba(237, 28, 36, 1);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;

    &:hover {
      background-color: rgba(237, 28, 36, 1);
    }
  }
`
