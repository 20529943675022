import styled from '@emotion/styled'

export const FieldLabel = styled.div`
  color: #000;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 116.7%; /* 21.006px */
`
