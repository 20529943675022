import MenuItem from '@material-ui/core/MenuItem'
import messages from '../../../../assets/messages'
import {Trans} from 'react-i18next'
import classNames from 'classnames'
import {getAccountLabel} from '../../../../common/utils/accounts'
import {getFormattedAmount} from '../../../../common/utils'
import {AccountSearchSelectProps} from '..'
import {Account, Client} from '../../../../graphql/types'
import {PropsWithChildren} from 'react'

type OptionSelectProps = Pick<
  AccountSearchSelectProps,
  'classes' | 'clients' | 'accounts' | 'hiddenBalance'
>;

type OptionProps = {
  data: {value: string | number};
  innerProps: Parameters<typeof MenuItem>;
  isFocused: boolean;
  isSelected: boolean;
  locale: string;
  selectProps: OptionSelectProps;
};

export function CustomOption(props: PropsWithChildren<OptionProps>) {
  const {
    selectProps: {accounts, clients, hiddenBalance},
    locale,
  } = props
  const suggestions: (Account | Client)[] = accounts || clients
  const option: Account =
    accounts &&
    suggestions.filter((suggestion) => suggestion.id === props.data.value)[0] as Account
  const optionClient =
    clients &&
    suggestions.filter((suggestion) => suggestion.id === props.data.value)[0] as Client
  const accountsOption = accounts && option
  const formattedBalance =
    accountsOption &&
    getFormattedAmount({
      locale,
      amount: option.balance,
      currency: option.currency,
    })
  const hasBalance = accountsOption && option.balance != null && (option.balance || option.balance >= 0)
  return (
    <MenuItem
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      classes={{root: props.selectProps.classes.menuItemRoot}}
      {...props.innerProps}
    >
      {accountsOption ? (
        <span className={props.selectProps.classes.fontLight}>
          {getAccountLabel(option, locale)}
          <span className={props.selectProps.classes.grey}>
            {' '}
            - {option.remoteId}
          </span>
          {!hiddenBalance && hasBalance && (
            <span
              className={classNames(
                props.selectProps.classes.grey,
                props.selectProps.classes.spanBalanceRight
              )}
            >
              {formattedBalance}
            </span>
          )}
        </span>
      ) : clients && optionClient ? (
        <span className={props.selectProps.classes.fontLight}>
          {optionClient.firstName} {optionClient.lastName}
          <span
            className={classNames(
              props.selectProps.classes.grey,
              props.selectProps.classes.spanBalanceRight
            )}
          >
            <Trans {...messages.login} />: {optionClient.id}
          </span>
        </span>
      ) : (
        <span className={props.selectProps.classes.fontLight}>
          {props.children}
        </span>
      )}
    </MenuItem>
  )
}
