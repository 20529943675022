import styled from '@emotion/styled'

export const FeeStructureHeader = styled.div`
  align-items: center;
  color: #818080;
  display: flex;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  gap: 0.125rem;
  letter-spacing: 0.152px;
`
