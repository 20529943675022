import _ from 'lodash'
import url from 'url'
import Intl from 'intl'
import moment from 'moment'
import {proofOfResidencyDocumentTypes, proofOfIdentityDocumentTypes, proofOfFundsDocumentTypes, appropTestStatuses,
  kycStatuses, whiteLabels, companies, rawSubscriptionPlans, currencies, partnerDocumentTypes,
  clientTypes, countries, themePreferenceOptions, personalDocsDocumentTypes, languages, nationalities} from '@bdswiss/common-enums'
import {config} from '../../config'
import {getItem} from './localStorage'
import {commonIbanCountries, momentLocales} from './uioptions'

export const safeParseJSON = (value) => {
  let parsed
  try {
    parsed = JSON.parse(value)
  } catch (e) {
    //
  }
  return parsed
}


export const keysEqual = (obj, keys) => _.isEqual(_.keys(obj).sort(), keys.sort())

export const isEmptyStr = (string) => {
  //remove all spaces including new lines and zero width unicode
  const convertedString = string && string.toString()
  return !convertedString || !convertedString.replace(/[\s\u200B-\u200D\uFEFF]/g, '')
}


export const checkLanguage = (permitted, language) => {
  if (language && permitted.includes(language.toLowerCase())) {
    return language
  } else {
    return 'en'
  }
}

const getAnswerScore = (question, field, productPoints, value) => {
  const answerEnum = _.values(question.options).find((a) => a[field] === value)
  return _.get(answerEnum, [productPoints])
}

export const calculateAppropTestScore = (productPoints, answers, appropTestQuestions, field = 'value') => {
  let score = 0
  _.values(appropTestQuestions).forEach((question) => {
    if (!question.options) return
    const val = _.get(answers, [question.key])
    if (val) {
      if (question.multiselect) {
        // split string answer and calculate foreach answer
        val.split(';').forEach((value) => {
          const points = getAnswerScore(question, field, productPoints, value)
          score = points + score
        })
      } else {
        const points = getAnswerScore(question, field, productPoints, val)
        score = points + score
      }
    }
  })

  return score
}

const getAnswerScoreV2 = (question, field, productPoints, value) => {
  const answers = _.omitBy(question.options, 'disabled')
  const answerEnum = _.values(answers).find((a) => a[field] === value)
  return answerEnum.forex_points_v2
}


export const calculateAppropTestScoreV2 = (productPoints, answers, appropTestQuestionsV2, field = 'value') => {
  let score = 0
  let pointsTransactionsIn = 0
  let pointsWithLeverage = 0
  _.values(appropTestQuestionsV2).forEach((question) => {
    if (!question.options) return
    const val = _.get(answers, [question.key])

    if (val) {
      if (question.multiselect) {
        //not relevant to v2
        return
      } else {
        let points = getAnswerScoreV2(question, field, productPoints, val)

        if (question.question === 'appropTransactionsInForex'
          || question.question === 'appropTransactionsInCommodities') {
          pointsTransactionsIn = (points > pointsTransactionsIn) ? points : pointsTransactionsIn
          points = 0
        }

        if (question.question === 'appropWithLeverageForex'
        || question.question === 'appropWithLeverageCommodities') {
          pointsWithLeverage = (points > pointsWithLeverage) ? points : pointsWithLeverage
          points = 0
        }
        score = points + score
      }
    }
  })
  return Math.ceil(score + pointsTransactionsIn + pointsWithLeverage)
}

/**
 * Replaces all occurences of `{argument}` in `text` by `parameters.argument`. This argument style is used in
 * configuration file.
 *
 * @param text - text to be replaced
 * @param parameters - a key value collection. The const support nested objects, `something.else` wont work.
 * @returns string
 */
export const replacePlaceholders = (text, parameters) => text.replace(/\{([a-zA-Z0-9_]*)\}/g, (match, group) => parameters[group] || '')

export const transformLocale = (locale) => {
  switch (locale) {
    case 'no':
      return 'nb'
    case 'ph':
      return 'fil'
    default:
      return locale
  }
}

export const hasValue = (object, path) => !_.isEmpty(_.get(object, path))

export const hasValues = (object, paths) => paths.map((p) => !_.isEmpty(_.get(object, p))).every((p) => p)

export const isTestEmail = (email) => (/@bdswiss|@swissmarkets|@example|@test/i.test(email))

export const isWeekend = () => {
  const currentDay = moment(new Date()).day()
  return ((currentDay === 6) || (currentDay === 0))
}

export const stringsAreEqual = (a, b, ignoreCase = true) => {
  if (ignoreCase) {
    return _.toLower(a) === _.toLower(b)
  }
  return a === b
}

export const getCompanyConfig = (companyConfigs) => {
  const {hostname} = window.location
  const assumedCompanyConfig = _.find(companyConfigs, (companyConfig) => {
    const parsedFrontEndUrl = url.parse(companyConfig.frontendUrl)
    return  parsedFrontEndUrl.hostname === hostname
  })
  return assumedCompanyConfig || companyConfigs.bdswiss
}

export const getFormattedAmount = ({currency, amount, locale}:{currency:any,amount:any,locale?: any}) => {
  const isCrypto = _.get(currencies[currency], 'isCrypto', false)
  const noCents = _.get(currencies[currency], 'noCents', false)
  return Intl.NumberFormat(locale || {}, {
    style: 'currency',
    currency,
    minimumFractionDigits: noCents ? 0 : isCrypto ? 8 : 2,
  }).format(amount)
}

export const getTextWidth = (text, font) => {
  // re-use canvas object for better performance
  //@ts-ignore todo: wtf is that?
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  const context = canvas.getContext('2d')
  context.font = font
  const metrics = context.measureText(text)
  return metrics.width
}


export const getPositionSuffix = (number) => {
  const j = number % 10
  const k = number % 100
  if (j === 1 && k !== 11) {
    return 'st'
  }
  if (j === 2 && k !== 12) {
    return 'nd'
  }
  if (j === 3 && k !== 13) {
    return 'rd'
  }
  return 'th'
}

export const DOCUMENT_CATEGORY_TYPES = [
  {
    types: proofOfIdentityDocumentTypes,
    category: 'poi',
    show: () => true,
    mandatory: true
  },
  {
    types: proofOfResidencyDocumentTypes,
    category: 'por',
    show: () => true,
    mandatory: true
  },
  {
    types: proofOfFundsDocumentTypes,
    category: 'pof',
    show: ({depositedAmount}) => (depositedAmount || 0) > 0,
    mandatory: false
  },
  {
    types: personalDocsDocumentTypes,
    category: 'personal',
    show: ({address}) => {
      const country = _.get(address, 'country', '')?.toUpperCase()
      const checkCountry = _.some(personalDocsDocumentTypes, (c)=> _.includes(c.applicableCountries, country))
      return checkCountry
    },
    mandatory: false
  },
  {
    types: partnerDocumentTypes,
    category: 'partner',
    show: ({clientType}) => [clientTypes.affiliate.value,
      clientTypes.affiliateCorporate.value,
      clientTypes.ib.value,
      clientTypes.ibCorporate.value,
    ].includes(clientType),
    mandatory: false
  }
]

export const getMissingDocs = (viewer, optional = false) => {
  const requiredDocs = _.filter(DOCUMENT_CATEGORY_TYPES, (t) => t.show(viewer) &&
    (t.mandatory || (optional && t.category ==='partner')))
  const missingDocs = {}

  requiredDocs.forEach((t) => {
    missingDocs[t.category] = _.get(_.get(viewer.pendingUploadDocuments, t.category.toLowerCase()),'allowUpload')
  })

  return missingDocs
}

export const getMissingVerifications = (clientData, aptest, globalQuestionnaire, docs, mandatoryVerifications) => {
  const missingVerifications : string[] = []
  if (_.includes(mandatoryVerifications, 'profile') && !hasValues(clientData, [
    'firstName',
    'lastName',
    'phone',
    'nationality',
    'birthday',
    'address.line1',
    'address.city',
    'address.country',
    'address.zip'])) {
    missingVerifications.push('profile')
  }

  if (_.includes(mandatoryVerifications, 'economicProfile') && !globalQuestionnaire) {
    missingVerifications.push('economicProfile')
  }

  if (_.includes(mandatoryVerifications, 'appropriatenessTest')) {
    if (!aptest || aptest.status !== appropTestStatuses.approved.value) missingVerifications.push('appropriatenessTest')
  }

  if  (_.includes(mandatoryVerifications, 'kycStatus')) {
    if (!_.isEmpty(docs)) {
      if (!_.isEmpty(_.reject(getMissingDocs(clientData), (doc) => doc === false)) && clientData.kycStatus !== kycStatuses.approved.value) missingVerifications.push('kycStatus')
    } else {
      if (clientData.kycStatus !== kycStatuses.approved.value) {
        missingVerifications.push('kycStatus')
      }
    }
  }
  return missingVerifications
}

export function hasHeldDeposit(accounts) {
  return accounts && _.some(accounts, 'hasHeldDeposits')
}

export function getEurXRate(currency) {
  switch (currency.toUpperCase()) {
    case 'EUR' : return 1
    case 'GBP' : return 1.2
    case 'USD' : return 0.9
    case 'CHF' : return 0.9
    case 'PLN' : return 0.22
    case 'SEK' : return 0.106
    case 'NOK' : return 0.133
    case 'DKK' : return 0.134
    case 'ETH' : return 500
    case 'BCH' : return 800
    case 'BTC' : return 6000
    default: throw new Error(`This currency ${currency} is unsupported by this method use getCurrencyRate instead`)
  }
}

const currentYear = new Date().getFullYear()
export const monthRange = _.range(1, 13, 1)
export const yearRange = _.range(currentYear, currentYear + 20, 1)

export function formatGooglePlacesAddress(addressComponents: any[]) {
  const addressForm = {
    line1: [] as string[],
    city: '',
    zip: '',
    region: [] as string[],
    country: '',
    houseNumber: '',
  }

  for (const address of addressComponents) {
    if (_.includes(address.types, 'street_number')) addressForm.houseNumber = address.long_name
    if (_.includes(address.types, 'route')) addressForm.line1.push(address.long_name)

    if (_.includes(address.types, 'postal_code')) addressForm.zip = address.long_name

    if (_.includes(address.types, 'postal_town')) addressForm.city = address.long_name

    if (_.includes(address.types,'administrative_area_level_2')) addressForm.region.push(address.long_name)
    if (_.includes(address.types,'administrative_area_level_1')) {
      addressForm.city === '' ? addressForm.city = address.long_name : addressForm.region.push(address.long_name)
    }

    if (_.includes(address.types,'administrative_area_level_3')) {
      addressForm.city === '' ? addressForm.city = address.long_name : addressForm.region.push(address.long_name)
    }

    if (_.includes(address.types, 'locality')) addressForm.region.push(address.long_name)

    if (_.includes(address.types, 'country')) addressForm.country = address.short_name.toLowerCase()
  }

  return addressForm
}

export function getPendingVerificationsCount(viewer, appropTests, globalQuestionnaire, documents, clientType, blockedDeposit?) {
  const latestApTest = _.first(appropTests) || []
  const {productConfigs, accountVerification} = config

  let verificationFields
  if (blockedDeposit && clientType && productConfigs[clientType]) {
    verificationFields = productConfigs[clientType].accountVerification
  } else {
    verificationFields = accountVerification
  }

  // appropriatenessTest shouldn't be in notifications, since it's not visible SKYG-754
  // TODO: check if this should be removed, since we removed AP test for now SKYG-787
  verificationFields = verificationFields.filter(item => item !== 'appropriatenessTest')

  const missingVerifications  = getMissingVerifications(viewer, latestApTest, globalQuestionnaire, documents, verificationFields)

  if (_.includes(missingVerifications, 'profile') && _.includes(missingVerifications, 'economicProfile')) {
    return missingVerifications.length - 1
  } else {
    return missingVerifications.length
  }
}
export function isFirstRawPlan(plan) {
  const firstPlan=_.first(_.orderBy(rawSubscriptionPlans, ['order']))
  return plan === firstPlan
}
export function  getSubscriptionStatusMsg(subscription, pendingPlan, volumeTraded, isInFallback, positionFlag?) {
  const pendingPlanNote = (pendingPlan &&  isFirstRawPlan(pendingPlan)) ? 'subscriptionPendingFree' : 'subscriptionPendingChange'
  const maxVolumeReached =  volumeTraded >= rawSubscriptionPlans[subscription['plan']].maxVolume
  const topPlan = rawSubscriptionPlans[subscription['plan']].order === _.size(rawSubscriptionPlans)
  const cancelledNote = maxVolumeReached ?
    topPlan ? 'cancelledMaxProVolume' : 'cancelledMaxVolume'
    :  'subscriptionCancelled'

  const freeFallBack = isInFallback && isFirstRawPlan(rawSubscriptionPlans[subscription['plan']])
  const activeNote = (positionFlag === 'volumeBar') ? (!topPlan ? (freeFallBack ? 'freeFallBack' : 'growRawConsumptionNote') : maxVolumeReached ? 'maxProVolume' : '') : (freeFallBack ? 'freeFallBack' : 'subscriptionActive')


  if (subscription.lastPaymentFailed) {
    return 'subscriptionFailedPayment'
  }

  return subscription.isActive ?
    subscription.pendingPlan ? pendingPlanNote : activeNote
    : subscription.isExpired ? 'subscriptionExpired' : cancelledNote
}

export function getPlanAmount(subscriptionPlan, pendingPlan?) {
  const amountPlan = pendingPlan ? pendingPlan['defaultAmount'] : subscriptionPlan['defaultAmount']
  const currencyPlan =  pendingPlan ? currencies[pendingPlan['currency']]['symbol'] : currencies[subscriptionPlan['currency']]['symbol']
  return `${currencyPlan}${amountPlan}`
}

export function getPlanCommissions(subscriptionPlan) {
  const commissionsCurrency = subscriptionPlan && currencies[subscriptionPlan.commissions.currency].symbol
  const overTheLimitCommission = subscriptionPlan && subscriptionPlan.commissions.overTheLimitCommission &&`${commissionsCurrency}${subscriptionPlan.commissions.overTheLimitCommission}`
  const fallBackCommission = subscriptionPlan && `${commissionsCurrency}${subscriptionPlan.commissions.fallBackCommission}`
  return {overTheLimitCommission,fallBackCommission}
}

export function findCompany(forcedCompany?) {
  const companyKey = forcedCompany ?? config.key
  const whiteLabel = _.filter(whiteLabels, (whitelabel) => whitelabel.value === companyKey)
  if (whiteLabel[0]) {
    whiteLabel[0].company.brandLabel = (whiteLabel[0].internal && whiteLabel[0].label) ? whiteLabel[0].label : whiteLabel[0].company.trademark as any
    whiteLabel[0].company.domain = whiteLabel[0].domain
    whiteLabel[0].company.portalDomain = whiteLabel[0].portalDomain!
    //@ts-ignore todo: we for sure need to fix that
    whiteLabel[0].company.whiteLabelBrand = whiteLabel[0].label
  }
  return whiteLabel[0] ? whiteLabel[0].company : companies[companyKey]
}

export function isWhiteLabel() {
  const {key} = config
  const whiteLabel = _.filter(whiteLabels, (whitelabel) => whitelabel.value === key)
  return whiteLabel.length > 0
}

export function checkPassword(password: string) {
  if (password.length < 6 || password.length > 32)
    return 'isLessThan6CharsMoreThan32'
  else if (!/[a-zA-Z]/.test(password))
    return 'noAnyLetter'
  else if (!/[0-9]/.test(password))
    return 'noDigit'
  else
    return ''
}

export function checkPasswordMauritius(password: string) {
  if (password.length < 8 || password.length > 16)
    return 'isLessThan8CharsMoreThan16'
  else if (!/[a-z]/.test(password))
    return 'noLowercaseLetters'
  else if (!/[A-Z]/.test(password))
    return 'noUppercaseLetters'
  else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password))
    return 'noSpecialCharacters'
  else if (!/[0-9]/.test(password))
    return 'noDigit'
  else
    return ''
}

export function validCountries() {
  return _.filter(countries, country => !country.forbidden && !country.hidden)
}

export function isDarkTheme(theme) {
  return theme === themePreferenceOptions.dark.value
}

export function getCurrentTheme(options = {},pending = false) {
  if (pending) return _.get(options, 'themePreference')
  else return _.get(options, 'themePreference') ||  getItem('themePreference', themePreferenceOptions.light.value)
}

export function getDatesDifference(startDate, endDate) {
  const timeRemaining = moment.duration(endDate.diff(startDate))
  const millisecondsSum = _.get(timeRemaining, '_milliseconds') ?? 0
  const active = millisecondsSum > 0
  //@ts-ignore
  const daysRemaining = active && parseInt(timeRemaining.asDays())
  //@ts-ignore
  const hoursRemaining = active && parseInt(timeRemaining.asHours()) - daysRemaining*24
  //@ts-ignore
  const minutesRemaining = active && parseInt(timeRemaining.asMinutes()) - (daysRemaining*24*60 + hoursRemaining*60)
  //@ts-ignore
  const secondsRemaining = active && parseInt(timeRemaining.asSeconds()) - (daysRemaining*24*60 + hoursRemaining*60 + minutesRemaining*60)
  return {
    active,
    daysRemaining,
    hoursRemaining,
    minutesRemaining,
    secondsRemaining,
    millisecondsSum,
  }
}

export function hasWebTrader() {
  return _.get(config.productConfigs.forex,'webtraderUrl') || false
}

export function missingDocumentsPaymentMethods(paymentMethods) {
  return _.some(paymentMethods, (m)=> _.get(m.pendingUpload,'allowUpload'))
}

export function checkIbanMatchSwiftCountry(iban, swiftCode) {
  const ibanCountry = iban.substring(0, 2).toUpperCase()
  const swiftCodeCountry = swiftCode.substring(4, 6).toUpperCase()
  return ibanCountry !== (commonIbanCountries[swiftCodeCountry] || swiftCodeCountry)
}

export function languagesCountries() {
  return _.map(_.filter(languages, (a) => !!a.client && !a.disabled), (a)=> ({
    ...a,
    localeMatch: a.key,
    key: a.flag,
    value: a.flag,
    label: a.native,
  }))
}

export function getLocaleMoment(locale, exclutions?) {
  const availableLocales = _.remove(moment.locales(), (l) => !_.includes(exclutions, l))
  const momentLocale = _.get(momentLocales, locale) || locale
  const defaultLocale = _.get(momentLocales, 'default')
  const selectedLocale = _.includes(availableLocales, momentLocale) ? momentLocale : defaultLocale
  return selectedLocale
}

export function clientVerificationIsRequiredOrPending(client, accountVerification) {
  const missingVerifications = getMissingVerifications(client,
    _.first(_.get(client, 'appropTests')),_.get(client, 'globalQuestionnaire'),'',accountVerification)
  const missingDocs = _.values(getMissingDocs(client)).some(d => d === true)
  const verificationPending = missingVerifications.length === 1 && _.includes(missingVerifications,'kycStatus')
    && !missingDocs
  const verificationRequired = missingVerifications.length > 0
  return {verificationRequired, verificationPending}
}

export function vpsSpecificMembers(clientId) {
  const {common:{vpsVisibilitySpecificMembers}} = config
  const specificMembers = _.filter(vpsVisibilitySpecificMembers, (member) => !_.isEmpty(member))
  return _.isEmpty(specificMembers) || _.includes(specificMembers, _.toString(clientId))
}

export function nationalitiesListWithFlag() {
  const nationalitiesListFlag = _.map(nationalities, (a)=> ({
    nationality: _.get(a, 'label'),
    key: _.get(_.find(countries, (b)=> b.alpha3Code === a.alpha3Code), 'key'),
    label: _.find(countries, (b)=> b.alpha3Code === a.alpha3Code) && _.get(a, 'label'),
    value: _.get(_.find(countries, (b)=> b.alpha3Code === a.alpha3Code), 'value'),
    keyCountry: _.get(a, 'key'),
    forbidden: _.get(a, 'forbidden'),
    hidden: _.get(a, 'hidden'),
  }))
  return _.reject(nationalitiesListFlag, (o) => _.isNil(o.key))
}

export const currencyPairReplacer = (pair) => {
  const {hasAnotherCurrenciesSymbols} = config
  if (!hasAnotherCurrenciesSymbols) return pair

  return pair.replace(/USD/g, 'GUSD').replace(/EUR/g, 'GEUR')
}

export const specificCurrencyFormatter = (currencyFormatter, currency, value) => {
  const {hasAnotherCurrenciesSymbols} = config
  if (hasAnotherCurrenciesSymbols) {
    if (currency === currencies.USD.value) {
      return currencyFormatter.format(value).replace(/\$/g, 'GUSD')
    }

    if (currency === currencies.EUR.value) {
      return currencyFormatter.format(value).replace(/€/g, 'GEUR')
    }
  }

  return currencyFormatter.format(value)
}
