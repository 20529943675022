import get from 'lodash/get'

import {countries, ICountryEnum} from '@bdswiss/common-enums'

import {
  FORM_FIELD_NAMES,
  CORRESPONDENCE_ADDRESS_FIELDS_NAMES
} from './constants'

function applyFormValues(client) {
  const {houseNumber, streetName} = retrieveHouseNumberAndStreetName({
    client,
    addressPath: 'address.line1',
    houseNumberPath: 'address.houseNumber',
  })

  const {houseNumber: correspondenceHouseNumber, streetName: correspondenceStreetName} = retrieveHouseNumberAndStreetName({
    client,
    addressPath: 'correspondenceAddress.line1',
    houseNumberPath: 'correspondenceAddress.houseNumber',
  })

  return {
    [FORM_FIELD_NAMES.firstName]: get(client, FORM_FIELD_NAMES.firstName, ''),
    [FORM_FIELD_NAMES.lastName]: get(client, FORM_FIELD_NAMES.lastName, ''),
    [FORM_FIELD_NAMES.birthday]: get(client, FORM_FIELD_NAMES.birthday, ''),
    [FORM_FIELD_NAMES.email]: get(client, FORM_FIELD_NAMES.email, ''),
    [FORM_FIELD_NAMES.phone]: get(client, FORM_FIELD_NAMES.phone, ''),
    [FORM_FIELD_NAMES.houseNumber]: houseNumber || '',
    [FORM_FIELD_NAMES.line1]: streetName || '',
    [FORM_FIELD_NAMES.city]: get(client['address'], FORM_FIELD_NAMES.city, ''),
    [FORM_FIELD_NAMES.zip]: get(client['address'], FORM_FIELD_NAMES.zip, ''),
    [FORM_FIELD_NAMES.region]: get(client['address'], FORM_FIELD_NAMES.region, ''),
    [FORM_FIELD_NAMES.country]: get(client['address'], FORM_FIELD_NAMES.country, ''),
    [FORM_FIELD_NAMES.nationality]: get(client, FORM_FIELD_NAMES.nationality, ''),
    [FORM_FIELD_NAMES.previousNames]: get(client, FORM_FIELD_NAMES.previousNames, ''),
    [FORM_FIELD_NAMES.passportNumber]: get(client, FORM_FIELD_NAMES.passportNumber, ''),
    secondaryEmails: {
      [FORM_FIELD_NAMES.secondaryEmail1]: get(client['secondaryEmails'], FORM_FIELD_NAMES.secondaryEmail1, ''),
    },
    secondaryPhones: {
      [FORM_FIELD_NAMES.secondaryPhone1]: get(client['secondaryPhones'], FORM_FIELD_NAMES.secondaryPhone1, ''),
    },
    [CORRESPONDENCE_ADDRESS_FIELDS_NAMES.houseNumber]: correspondenceHouseNumber || '',
    [CORRESPONDENCE_ADDRESS_FIELDS_NAMES.line1]: correspondenceStreetName || '',
    [CORRESPONDENCE_ADDRESS_FIELDS_NAMES.city]: get(client['correspondenceAddress'], FORM_FIELD_NAMES.city) || '',
    [CORRESPONDENCE_ADDRESS_FIELDS_NAMES.zip]: get(client['correspondenceAddress'], FORM_FIELD_NAMES.zip) || '',
    [CORRESPONDENCE_ADDRESS_FIELDS_NAMES.region]: get(client['correspondenceAddress'], FORM_FIELD_NAMES.region) || '',
    [CORRESPONDENCE_ADDRESS_FIELDS_NAMES.country]: get(client['correspondenceAddress'], FORM_FIELD_NAMES.country) || '',
  }
}

function retrieveHouseNumberAndStreetName({client, addressPath, houseNumberPath}) {
  const address = get(client, addressPath, '')
  const houseNumber = get(client, houseNumberPath, '')
  const streetName = houseNumber ? address.replace(houseNumber, '').trim() : address

  return {houseNumber, streetName}
}

function getCountrySelectValue(country: string): ICountryEnum | null {
  return Object.values(countries).find(({key}) => key === country.toLowerCase()) ?? null
}

export {applyFormValues, getCountrySelectValue}
