/*@ts-check*/
import {PropsWithChildren} from 'react'
import {AccountSearchSelectProps} from '..'

type ValueContainerProps = {  selectProps: Pick<AccountSearchSelectProps, 'classes'>
}

export function ValueContainer(props: PropsWithChildren<ValueContainerProps>) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}

