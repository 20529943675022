/*@ts-check*/
import Typography from '@material-ui/core/Typography'
import {Trans} from 'react-i18next'
import messages from '../../../../assets/messages'
import {AccountSearchSelectProps} from '..'

type NoOptionsMessageProps = {
  selectProps: Pick<AccountSearchSelectProps, 'classes'>
  innerProps: Parameters<typeof Typography>
}

export function NoOptionsMessage(props: NoOptionsMessageProps) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      variant={'body2'}
      {...props.innerProps}
    >
      {<Trans {...messages.noOptions}/>}
    </Typography>
  )
}
