import {Component} from 'react'
import {includes, get, find} from 'lodash'
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import {withNamespaces, Trans, WithNamespaces} from 'react-i18next'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'
import {accountTypes, kycStatuses} from '@bdswiss/common-enums'
import Amount from '../../Common/Amount'
import classNames from 'classnames'
import {isMAMScSubAccount, isAffiliatesAccount, isForexAccount, getAccountSubtype, getAccountLabel, isBitnukAccount, isKartwheelAccount, isPammManagerAccount, isApprovedAccount, getAccountProperty} from '../../../common/utils/accounts'
import messages from '../../../assets/messages'
import AppContext from '../../Common/contexts/AppContext'
import {isMobile} from '../../../common/utils/browser'
import {config} from '../../../config'
import {accountCategoriesDetails, serversLabel} from '../../../common/utils/uioptions'
import moment from 'moment'
import {InnerAppContext} from '../../../common/types'
import {styles} from './styles'
import {Account} from '../../../graphql/types'
import {getAccountType, getMetadataFromAccount, isBaseForexAccount, isMt4, isMt5, serverName} from './utils'
import {ServerNameMt5} from './components/ServerNameMt5'
import {ServerNameMt4} from './components/ServerNameMt4'

type AccountCardState = {
  fullDialogOpen: boolean
}

type AccountCardProps = {
  account: Account
  badges?: React.ReactNode
  hideCurrency?: boolean
  kycStatus?: string
  onClick?: (account: Account) => void
}
& WithStyles<typeof styles>
& WithNamespaces

class AccountCard extends Component<AccountCardProps,AccountCardState> {
  static propTypes = {
    account: PropTypes.object as PropTypes.Validator<Account>,
    hideCurrency: PropTypes.bool,
    locale: PropTypes.string,
    onClick: PropTypes.func,
  }
  static contextType = AppContext
  context!: InnerAppContext

  constructor(props: AccountCardProps) {
    super(props)
    this.state = {
      fullDialogOpen: false,
    }
  }
  private shouldDisplayBalanceForAccount(account?: Account): boolean {
    if (account?.__typename == null) return false

    const accountType = accountTypes[account.__typename]

    return !Boolean(accountType.noBalance)
  }
  render() {
    if (this.props.account == null) return null

    const {classes, account, onClick, t, kycStatus} = this.props
    const accountType = getAccountType(account)
    const incomingAccountSubType = getAccountProperty(account, 'accountSubtype') ?? undefined
    const accountCompetitions = getAccountProperty(account, 'competitions')
    const accountCredit = getAccountProperty(account, 'credit')
    const accountBalance = getAccountProperty(account, 'balance') ?? 0
    const accountUnconnected = getAccountProperty(account, 'unconnected')
    const accountIsArchived = getAccountProperty(account, 'isArchived')
    const accountIsViewOnly = getAccountProperty(account, 'isViewOnly')
    const accountIsReadOnly = getAccountProperty(account, 'isReadOnly')
    const accountIsManaged = getAccountProperty(account, 'isManaged')
    const accountSubtype = getAccountSubtype(incomingAccountSubType)
    const accountCurrency = getAccountProperty(account, 'currency') ?? undefined

    const category = get(accountType, 'category')
    const caption = account.isDemo ? `${serversLabel[category]} · ${account.remoteId} · Demo` :
      accountSubtype && !isMAMScSubAccount(account) ? `${serversLabel[category]} · ${account.remoteId} · ${accountSubtype.label}` :
        isBitnukAccount(account) ? account.login : account.remoteId
    const {locale} = this.context
    // SubTypes for Mam account are not translated
    const label = isMAMScSubAccount(account) ? accountSubtype!.label : getAccountLabel(account, locale)
    const verifiedAffiliate = (isAffiliatesAccount(account) && includes(['Approved'],
      get(account, 'serviceFields.affiliateStatus')))
    const missingVerificationsAffiliate = (isAffiliatesAccount(account) && kycStatus !== kycStatuses.approved.value)
    const classesCategory = classes[accountType.subCategory ?? ''] || (!account.isDemo && classes[incomingAccountSubType ?? ''])
      || classes[accountType.category]
    const shortLabel = (accountType.subCategory && accountType.shortLabel) || (incomingAccountSubType
      && get(accountSubtype, 'shortLabel')) || accountType.shortLabel || account.currency
    const copyTradingEnabled = get(account.copyTrading,'isActive')
    const hasActiveCompetition = Boolean(accountCompetitions) && find(accountCompetitions, 'active')
    const provideCopyTrading =  get(account, 'provideCopyTrading')
    const hasActiveBonus =  Boolean(accountCredit)
    const today = moment().format()
    const showBitnukDetails = accountCategoriesDetails['cryptoWallet'].bitnuk
    const bitnukEndDate = moment(showBitnukDetails.startDate).add(showBitnukDetails.activeDays, 'd')
    const isUnapproved = !isApprovedAccount(account) && (isKartwheelAccount(account) || isPammManagerAccount(account))
    const isDepositNeeded = accountUnconnected && accountBalance === 0
    const isDisabled = isUnapproved || accountUnconnected || isDepositNeeded
    const metadata = getMetadataFromAccount(account)
    const isMMFeesAccount = !!metadata.pammFeesAccount
    const isMMTradesAccount =!!metadata.pammTradesAccount
    const moneyManagerText = `${t(messages.moneyManagerID.i18nKey)}: ${metadata.pammFeesAccount ?? metadata.pammTradesAccount} - ${t(messages[isMMFeesAccount ? 'fees' : 'trade'].i18nKey)}`

    return <Card classes={{root: classNames(classes.cardRoot, {[classes.pendingCard]: isDisabled})}} onClick={() => onClick && onClick(account)}>
      <Grid container>
        <Grid item>
          <div className={classNames(classes.typeBadge, classesCategory, {[classes.ibPending]: isDisabled})}>
            {shortLabel}</div>
        </Grid>
        <Grid item className={classes.cardContent}>
          <Typography variant='subtitle1'>{label}</Typography>
          {isMobile() && this.shouldDisplayBalanceForAccount(account) && <div className={classes.amountMobile}><Typography variant='caption'>{caption}</Typography>
            <Typography variant='body2' className={classes.amount}>
              <Amount value={accountBalance} currency={accountCurrency} locale='en-US' classes={{
                currency: classes.amountCurrency,
                value: classes.amountValue,
                fraction: classes.amountFraction
              }} />
            </Typography>
          </div>}
          {!isMobile() && <Typography variant='caption'>{caption}</Typography>}
          {accountIsArchived && <Typography variant='caption'><Trans {...messages.archivedMessage} />
          </Typography>}
          {(isMMFeesAccount || isMMTradesAccount) && <Typography variant='caption'>{moneyManagerText}</Typography>}
          {isBaseForexAccount(account) && isMt5(account) && <ServerNameMt5>
            {serverName(account)}
          </ServerNameMt5>}
          {isBaseForexAccount(account) && isMt4(account) && <ServerNameMt4>
            {serverName(account)}
          </ServerNameMt4>}
        </Grid>
        <Grid item className={classes.badgesBox}>
          {accountCategoriesDetails[accountType.category] && get(accountCategoriesDetails[accountType.category][accountType.subCategory], 'newBadge')
              && moment(today).isSameOrBefore(moment(bitnukEndDate).format(), 'day') && !account.balance
              && !accountIsArchived && !accountIsReadOnly && !accountIsViewOnly && !copyTradingEnabled &&
              <Grid item className={classNames(classes.isDemoArchived, classes.copyingText)}>
                <Typography variant='caption' className={classes.isDemoText}><Trans {...messages.new} /></Typography>
              </Grid>
          }
          {!accountIsArchived && accountIsReadOnly && !accountIsViewOnly &&
            <Grid item className={classNames(classes.isDemoArchived, classes.isDemo)}>
              <Typography variant='caption' className={classes.isDemoText}><Trans {...messages.readOnly} /></Typography>
            </Grid>
          }
          {isUnapproved &&
          <Grid item className={classNames(classes.pending, classes.isDemoArchived)}>
            <Typography variant='caption' className={classes.pendingText}><Trans {...messages.pending} /></Typography>
          </Grid>
          }
          {!accountIsArchived && accountIsViewOnly && <Grid item className={classNames(classes.isDemoArchived, classes.isDemo)}>
            <Typography variant='caption' className={classes.isDemoText}><Trans {...messages.viewOnly} /></Typography>
          </Grid>
          }
          {accountType.isDemo && <Grid item className={classNames(classes.isDemoArchived, classes.isDemo)}>
            <Typography variant='caption' className={classes.isDemoText}><Trans {...messages.demo} /></Typography>
          </Grid>
          }
          {accountIsArchived && <Grid item className={classNames(classes.isDemoArchived, classes.isArchived)}>
            <Tooltip title={t(messages.archivedTooltip.i18nKey, messages.archivedTooltip.defaults)} placement="top">
              <Typography variant='caption' className={classes.isArchivedText}>
                <Trans {...messages.archived} /></Typography>
            </Tooltip>
          </Grid>
          }
          {accountIsManaged && <Grid item className={classNames(classes.isDemoArchived, classes.basic)}>
            <Typography variant='caption' className={classes.providing}>
              <Trans {...messages.managed} />
            </Typography>
          </Grid>
          }
          {isAffiliatesAccount(account) && <Grid item className={classNames(classes.isDemoArchived, classes.isDemo)}>
            <Typography variant='caption' className={classes.isDemoText}>
              {!verifiedAffiliate ? <Trans {...messages.pendingApproval} /> : ''}
              {verifiedAffiliate && missingVerificationsAffiliate ? <Trans {...messages.notVerified} /> : ''}
            </Typography>
          </Grid>
          }
          {hasActiveCompetition && !accountIsArchived && !accountIsReadOnly && !accountIsViewOnly && !copyTradingEnabled &&
            <Grid item className={classNames(classes.isDemoArchived, classes.competitionDiv)}>
              <Typography variant='caption' className={classes.competitionText}><Trans {...messages.competition} /></Typography>
            </Grid>
          }
          {provideCopyTrading &&
            <Grid item className={classNames(classes.isDemoArchived, classes.basic)}>
              <Typography variant='caption' className={classes.providing}><Trans {...messages.providing} /></Typography>
            </Grid>
          }
          {isForexAccount(account) && copyTradingEnabled && <Grid item className={classNames(classes.isDemoArchived, classes.copyingText)}>
            <Typography variant='caption' className={classes.isDemoText}><Trans {...messages.copying} /></Typography>
          </Grid>
          }
          {hasActiveBonus && !accountIsArchived && !accountIsReadOnly && !accountIsViewOnly && !copyTradingEnabled &&
            <Grid item className={classNames(classes.isDemoArchived, classes.bonusDiv)}>
              <Typography variant='caption' className={classNames(classes.isDemoText, classes.bonusText)}><Trans {...messages.bonus} /></Typography>
            </Grid>
          }
          {isMMFeesAccount &&
            <Grid item className={classNames(classes.isDemoArchived, classes.pammStatusDiv)}>
              <Typography variant='caption' className={classNames(classes.isDemoText, classes.bonusText)}><Trans {...messages.pammFeesAccount} /></Typography>
            </Grid>
          }
          {isMMTradesAccount &&
            <Grid item className={classNames(classes.isDemoArchived, classes.pammStatusDiv)}>
              <Typography variant='caption' className={classNames(classes.isDemoText, classes.bonusText)}><Trans {...messages.pammTradesAccount} /></Typography>
            </Grid>
          }
          {this.props.badges}
        </Grid>
        {config.visual?.accounts?.accessNowLabel &&
          <Grid item style={{alignSelf: 'center'}}>
            <Typography variant='inherit'><Trans {...messages.accessNow} /></Typography>
          </Grid>
        }
      </Grid>
      {!isMobile() && this.shouldDisplayBalanceForAccount(account) && <Typography variant='body2' className={classes.amount}>
        <Amount value={accountBalance} currency={this.props.hideCurrency ? undefined : accountCurrency} locale='en-US' classes={{
          currency: classes.amountCurrency,
          value: classes.amountValue,
          fraction: classes.amountFraction
        }} />
      </Typography>
      }
    </Card>
  }
}



export default withStyles(styles, {withTheme: true})(withNamespaces()(AccountCard))
