import {Theme} from '@material-ui/core'
import {CSSProperties} from '@material-ui/core/styles/withStyles'

export const styles = (theme: Theme): Record<string,CSSProperties> => ({
  main: {
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: `${theme.sidebarWidth}`,
  },
  header: {
    marginBottom: 40,
    display: 'inline-block'
  },
  flexGrow:{
    flexGrow:1
  },
  zeroPaddingLeft:{
    paddingLeft:0
  },
  addAccount:{
    display: 'block'
  },
  addAccountMobile:{
    display: 'block',
    fontSize: 25,
    position: 'absolute' as const,
    [theme.direction === 'rtl' ? 'left' : 'right']: 12,
    top: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 23,
    },
  },
  textLink: {
    color: theme.palette.primary.main,
  },
  competitionImg: {
    height: 25,
    position: 'absolute' as const,
    [theme.direction === 'rtl' ? 'right' : 'left']: -35,
    top: -2,
    [theme.breakpoints.down('sm')]: {
      [theme.direction === 'rtl' ? 'right' : 'left']: -25,
      height: 23,
    },
  },
  displayInline: {
    display: 'inline-flex'
  },
  competitionDivider: {
    lineHeight: '15px',
    minHeight: 15,
  },
  competitionsLink: {
    [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']: `1px solid ${theme.palette.primary.main}`,
    [theme.direction === 'rtl' ? 'paddingLeft' : 'paddingRight']: 30,
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 30,
  },
  competitionsLinkMobile: {
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 0,
    paddingRight: 11,
    border: 'none'
  },
  enterCompetition: {
    right: 37,
    top: 20,
  },
  enterCompetitionMobile: {
    fontSize: 14,
    right: 17,
  },
  competitionSpan: {
    position: 'relative' as const
  },
  maxAccountsDiv: {
    textAlign: 'center' as const,
    padding: '15px 0'
  },
  pointer: {
    cursor: 'pointer',
  },
  dot: {
    color: theme.palette.error.main,
    fontSize: 10,
    marginLeft: 5
  },
})
