import {Trans} from 'react-i18next'
import messages from '../../../../assets/messages'
import HeaderDialog from '../../../Common/Dialog/HeaderDialog'
import PageTitle from '../../../Common/PageTitle'
import {Container} from './components/Container'
import Typography from '@material-ui/core/Typography'
import {CodeInput} from '../../Registration/components/MfaSettings/components/CodeInput'
import {useState} from 'react'
import LoadingButton from '../../../Common/LoadingButton'
import {backendRequest} from '../../../../common/utils/requests'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

export const OtpVerification = () => {
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [rememberMe, setRememberMe] = useState(false)

  const handleSubmit = async () => {
    const requestResult = await backendRequest('/api/v2/dashboard/auth/totp', {code, rememberMe})
    const result = await requestResult.json()
    if (requestResult.ok) {
      if (!result.error) {
        window.location.href = '/'
      } else {
        setError(result.actionErrorCode)
      }
    } else {
      setError(result.actionErrorCode)
    }
  }

  const handleRememberMeChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setRememberMe(checked)
  }

  return (
    <>
      <HeaderDialog />
      <Container>
        <PageTitle>
          <Trans {...messages.verifyWithYourAuthenticatorApp} />
        </PageTitle>
        <Typography variant="body1">
          <Trans {...messages.useTheCode} />
        </Typography>
        <CodeInput onChange={setCode} value={code} />
        {error && (<Typography color="error" variant="h5">
          <Trans {...messages[error]} />
        </Typography>)}
        <LoadingButton
          disabled={false}
          onClick={handleSubmit}
        >
          <Trans {...messages.verify} />
        </LoadingButton>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={handleRememberMeChange}
                color="primary"
              />
            }
            label={<Trans {...messages.staySigned} />}
          />
        </FormControl>
      </Container>
    </>)
}
