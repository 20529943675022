import {WelcomeMessagePopup} from './popups/WelcomeMessagePopup'
import {useAcknowledgeSeen} from './useAcknowledge'

export type PendingMessage = {
  id: number;
  type: string;
  metadata: string;
}

type PopupFactoryProps = {
  pendingMessages: PendingMessage[];
}

const Popups = {
  welcome_account_created: WelcomeMessagePopup
}

export const PopupFactory = ({pendingMessages}: PopupFactoryProps) => {
  const acknowledgeSeen = useAcknowledgeSeen()
  return (
    <>
      {pendingMessages.map((notice) => {
        const PopupComponent = Popups[notice.type]
        return (
          <PopupComponent
            acknowledgeSeen={acknowledgeSeen}
            key={notice.id}
            notice={notice}
          />
        )
      })}
    </>
  )
}
