import {useHistory} from 'react-router-dom'
import {ICompanyEnum, companies} from '@bdswiss/common-enums'
import {PopupProps} from '../../../../../../../../types'
import messages from '../../../../../../../../assets/messages'
import {AlertDialog} from '../../../../../../../Common/Dialog'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {Trans, withNamespaces} from 'react-i18next'
import {Account} from '../../../../../../../../graphql/types'
import {TAUROMARKETS_MAURITIUS_SUPPORT_EMAIL, TAUROMARKETS_SUPPORT_EMAIL} from '../../../../constants'

type PammInvestorPendingPopupProps = PopupProps & {
  account: Pick<Account, 'id'>;
  company: ICompanyEnum
}

const agreeI18nKey: string = 'deposit'
const disagreeI18nKey: string = 'cancel'

const titleI18nKey = 'pammInvestorPendingTitle'

const _PammInvestorPendingPopup = ({account, onAgree, onClose, open, t, company}: PammInvestorPendingPopupProps) => {
  const history = useHistory()
  const title = t(messages[titleI18nKey].i18nKey, messages[titleI18nKey].defaults)

  const handleDeposit = () => {
    history.push(`/transactions/${account.id}/deposit`)
  }

  const agreeText: string = t(messages[agreeI18nKey].i18nKey, messages[agreeI18nKey].defaults).toLocaleUpperCase()
  const disagreeText: string = t(messages[disagreeI18nKey].i18nKey, messages[disagreeI18nKey].defaults).toLocaleUpperCase()
  const email = company.value === companies.tauroMarketsMauritius.value
    ? TAUROMARKETS_MAURITIUS_SUPPORT_EMAIL
    : TAUROMARKETS_SUPPORT_EMAIL

  return (
    <AlertDialog
      agreeText={agreeText}
      disagreeText={disagreeText}
      onAgree={handleDeposit}
      onClose={onClose}
      onDisagree={onClose}
      open={open}
      title={title}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" align='center'>
            <Trans
              {...messages.pammInvestorPendingLine1}
            />
          </Typography>
          &nbsp;
          <Typography variant="subtitle2" align="center">
            <Trans
              {...messages.pammInvestorPendingLine2}
              components={[
                <a href={`mailto:${email}`}>{email}</a>,
              ]}
              values={{
                supportEmail: email,
              }}
            />
          </Typography>
        </Grid>
      </Grid>
    </AlertDialog>
  )
}

export const PammInvestorPendingPopup = withNamespaces()<PammInvestorPendingPopupProps>(
  _PammInvestorPendingPopup
)
