import {Container} from './components/Container'
import {DigitInput} from './components/DigitInput'

type CodeInputProps = {
  onChange: (newValue: string) => void;
  value: string;
}

export const CodeInput = ({onChange, value}: CodeInputProps) => {
  const effectiveValue = value.split('')
  const handleDigitInputChange = (digit: string, index: number) => {
    if (digit === 'Backspace') {
      if (index > 0) {
        handleDigitInputChange('', index - 1)
      }
    } else {
      const result = [...effectiveValue]
      result[index] = digit
      onChange(result.join(''))
    }
  }

  return (
    <Container>
      <DigitInput onChange={newValue => handleDigitInputChange(newValue, 0)} value={effectiveValue[0] ?? ''} />
      <DigitInput onChange={newValue => handleDigitInputChange(newValue, 1)} value={effectiveValue[1] ?? ''} />
      <DigitInput onChange={newValue => handleDigitInputChange(newValue, 2)} value={effectiveValue[2] ?? ''} />
      -
      <DigitInput onChange={newValue => handleDigitInputChange(newValue, 3)} value={effectiveValue[3] ?? ''} />
      <DigitInput onChange={newValue => handleDigitInputChange(newValue, 4)} value={effectiveValue[4] ?? ''} />
      <DigitInput onChange={newValue => handleDigitInputChange(newValue, 5)} value={effectiveValue[5] ?? ''} />
    </Container>
  )
}
