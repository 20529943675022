import {Theme} from '@material-ui/core'

export const styles = (theme: Theme) => ({
  cardRoot: {
    cursor: 'pointer',
    position:'relative' as const,
    height: '100%'
  },
  amount: {
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'flex-end',
  },
  amountCurrency: {
    fontSize: 16,
    fontWeight: 200,
    padding: '0 3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  amountValue: {
    fontSize: 26,
    fontWeight: 200,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  amountFraction: {
    fontSize: 16,
    fontWeight: 200,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  amountMobile:{
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  typeBadge: {
    height: 40,
    width: 40,
    fontSize: 15,
    lineHeight: '40px',
    borderRadius: 20,
    textTransform: 'uppercase' as const,
    textAlign: 'center' as const,
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 18,
    backgroundColor:  theme.palette.lightred.color,
    color: theme.palette.red.color,
    [theme.breakpoints.down('xs')]: {
      width: 35,
      height: 35,
      lineHeight: '37px',
      [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 14,
    },
  },
  forexMt4: {
    color: theme.palette.red.color,
    backgroundColor:  theme.palette.lightred.color,
  },
  pammForex: {
    color: theme.palette.green.color,
    backgroundColor: theme.palette.lightgreen.color,
  },
  spotOption: {
    color: theme.palette.green.color,
    backgroundColor: theme.palette.lightgreen.color,
  },
  tradeSmarter: {
    color: theme.palette.green.color,
    backgroundColor: theme.palette.lightgreen.color,
  },
  default: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.lightblue.color,
  },
  affiliate: {
    color: theme.palette.yellow.color,
    backgroundColor: theme.palette.lightyellow.color,
  },
  cardContent:{
    flexGrow:1,
    [theme.breakpoints.up('sm')]: {
      minHeight: 80,
    },
  },
  isDemoArchived:{
    borderRadius: '5px',
    padding: '0px 7px',
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']:4
  },
  badgesBox:{
    position: 'absolute' as const,
    [theme.direction === 'rtl' ? 'left' : 'right']: 8,
    display: 'flex',
    flexDirection: 'row' as const,
    [theme.breakpoints.down('xs')]: {
      top: 5,
    },
    maxHeight: '25px'
  },
  isDemo:{
    backgroundColor: theme.palette.secondary.main,
  },
  isDemoText:{
    color: theme.palette.secondary.light,
    opacity: 1,
  },
  isArchived:{
    backgroundColor:  theme.palette.lightred.color,
  },
  isArchivedText:{
    color: theme.palette.red.color,
  },
  ib: {
    color: theme.palette.green.color,
    backgroundColor:  theme.palette.lightgreen.color,
  },
  ibPending: {
    color: theme.palette.secondary.main,
    backgroundColor:  theme.palette.primary.contrastText,
  },
  basic: {
    color: theme.palette.primary.main,
    backgroundColor:  theme.palette.lightblue.color,
  },
  raw: {
    color: theme.palette.cyan.color,
    backgroundColor:  theme.palette.lightcyan.color,
  },
  black: {
    color: theme.palette.purple.color,
    backgroundColor:  theme.palette.lightpurple.color,
  },
  invest_plus: {
    color: theme.palette.harlequin.color,
    backgroundColor:  theme.palette.lightharlequin.color,
  },
  copyingText:{
    backgroundColor: theme.palette.red.color,
  },
  competitionDiv:{
    backgroundColor:  theme.palette.green.color,
  },
  competitionText:{
    color: theme.palette.green.textColor,
  },
  providing: {
    color: theme.palette.primary.main,
  },
  bonusDiv:{
    backgroundColor: theme.palette.cyan.color,
  },
  pammStatusDiv: {
    backgroundColor: '#3478F6',
  },
  bonusText:{
    fontWeight: 500
  },
  premium: {
    color: theme.palette.premium.color,
    backgroundColor: theme.palette.lightpremium.color,
  },
  pendingText: {
    color: theme.palette.primary.contrastText,
  },
  pending: {
    backgroundColor: theme.palette.red.color,
  },
  pendingCard: {
    backgroundColor: 'transparent',
    border: '1px solid #f1f5f9',
    boxShadow: 'unset',
  }
})
