import {Trans} from 'react-i18next'
import {ManagerOffer} from '../../../../useOffers'
import {OfferString} from '../OfferString'
import messages from '../../../../../../../../../assets/messages'
import {InfoTooltip} from '../InfoTooltip'
import {Container} from './components/Container'

type PerformanceFeeProps = {
  fees: ManagerOffer['settings']['performanceFees']
}

export const PerformanceFee = ({fees}: PerformanceFeeProps) => {
  const {levels} = fees
  const tooltipContent = fees.levels.map(level => (
    <div>{level.level}: {level.value}%</div>
  ))

  if (levels.length === 1) {
    return (
      <OfferString>
        <Trans {...messages.singleLevelPerformanceFee} values={{fee: fees.levels[0].value}} />
      </OfferString>
    )
  } else {
    return (
      <Container>
        <OfferString>
          <Trans {...messages.multipleLevelPerformanceFees} />
        </OfferString>
        <InfoTooltip content={tooltipContent} />
      </Container>
    )
  }
}
