import {Trans} from 'react-i18next'
import NotificationBar from '../../../../../../Common/NotificationBar'
import Images from '../../../../../../Common/Images'
import messages from '../../../../../../../assets/messages'
import {useContext} from 'react'
import AppContext from '../../../../../../Common/contexts/AppContext'
import {ImageContainer} from './components/ImageContainer'

export const NoOffers = () => {
  const {themePreference} = useContext(AppContext)
  return (
    <>
      <NotificationBar status={'info'}>
        <Trans {...messages.noOffers} />
      </NotificationBar>
      <ImageContainer>
        <img
          src={Images[`documents-empty-${themePreference}.png`]}
          alt='noTrades'
        />
      </ImageContainer>
    </>
  )
}
