import Typography from '@material-ui/core/Typography'
import {MfaOption} from '../MfaOption'
import {MfaOptionBody} from '../MfaOptionBody'
import {ReactComponent as QrCodeIcon} from './assets/qrcode.svg'
import {Trans} from 'react-i18next'
import SwitchButton from '../../../../Common/SwitchButton'
import messages from '../../../../../assets/messages'
import {useState} from 'react'
import {MfaSettingsPopup} from '../../../../App/PopupFactory/popups/MfaSettingsPopup'
import {backendRequest} from '../../../../../common/utils/requests'
import {MfaSuccessModal} from '../../../../App/PopupFactory/popups/MfaSuccessModal'
import {CheckCircleOutlineOutlined} from '@material-ui/icons'
import {MfaOptionName} from '../MfaOptionName'
import {MfaDeleteConfirmationModal} from '../../../../App/PopupFactory/popups/MfaDeleteConfirmationModal'
import {TotpRemovalPopup} from '../../../../App/PopupFactory/popups/TotpRemovalModal'

type TotpMfaOptionProps = {
  email: string;
  enabled: boolean;
}

export const TotpMfaOption = ({email, enabled}: TotpMfaOptionProps) => {
  const [totpSetupModalShown, setTotpSetupModalShown] = useState(false)
  const [optionEnabled, setOptionEnabled] = useState(enabled)
  const [error, setError] = useState('')
  const [successModalShown, setSuccessModalShown] = useState(false)
  const [deleteConfirmationModalShown, setDeleteConfirmationModalShown] = useState(false)
  const [totpRemovalModalShown, setTotpRemovalModalShown] = useState(false)
  const [removalSuccessModalShown, setRemovalSuccessModalShown] = useState(false)

  const handleSwitch = (e) => {
    const newValue = e.target.checked
    if (newValue) {
      setTotpSetupModalShown(true)
    } else {
      setDeleteConfirmationModalShown(true)
    }
  }

  const handleOtpSetup = async (otpSecret) => {
    const requestResult = await backendRequest('/api/v2/dashboard/totp_secrets', {secret: otpSecret})
    if (requestResult.ok) {
      const result = await requestResult.json()
      if (!result.actionErrorCode) {
        setOptionEnabled(true)
        setSuccessModalShown(true)
      } else {
        setError(result.actionErrorCode)
      }
    } else {
      const erroneousResult = await requestResult.json()
      setError(erroneousResult.actionErrorCode)
    }
    setTotpSetupModalShown(false)
  }

  const handleDeleteConfirmed = async () => {
    setDeleteConfirmationModalShown(false)
    setTotpRemovalModalShown(true)
  }

  const handleTotpRemoval = async (password: string, code: string) => {
    const requestResult = await backendRequest('/api/v2/dashboard/totp_secrets', {
      code,
      password
    }, 'DELETE')
    if (requestResult.ok) {
      const result = await requestResult.json()
      if (!result.actionErrorCode) {
        setOptionEnabled(false)
        setRemovalSuccessModalShown(true)
        setDeleteConfirmationModalShown(false)
        setTotpRemovalModalShown(false)
      } else {
        setError(result.actionErrorCode)
      }
    } else {
      const erroneousResult = await requestResult.json()
      setError(erroneousResult.actionErrorCode)
    }
  }

  return (
    <>
      <MfaOption>
        <QrCodeIcon />
        <MfaOptionBody>
          <MfaOptionName>
            <h5>
              <Trans {...messages.authenticatorApp} />
            </h5>
            {optionEnabled && (
              <>
                <CheckCircleOutlineOutlined />
                <Typography variant="body1">
                  <Trans {...messages.activated} />
                </Typography>
              </>
            )}
          </MfaOptionName>
          <Typography variant="body1">
            <Trans {...messages.useAnyAuthenticatorApp} />
          </Typography>
        </MfaOptionBody>
        <SwitchButton checked={optionEnabled} onChange={handleSwitch} />
      </MfaOption>
      {totpSetupModalShown && <MfaSettingsPopup
        email={email}
        onAgree={handleOtpSetup}
        onDisagree={() => setTotpSetupModalShown(false)}
      />}
      {error && (<Typography color="error" variant="h5">
        <Trans {...messages[error]} />
      </Typography>)}
      {successModalShown && <MfaSuccessModal
        onClose={() => setSuccessModalShown(false)}
        title={<Trans {...messages.totpSetupSuccessTitle} />}
        content={<Trans {...messages.totpSetupSuccessContent} />}
      />}
      {deleteConfirmationModalShown && <MfaDeleteConfirmationModal
        onSubmit={handleDeleteConfirmed}
        onCancel={() => setDeleteConfirmationModalShown(false)}
      />}
      {totpRemovalModalShown && <TotpRemovalPopup
        error={error}
        onSubmit={handleTotpRemoval}
        onCancel={() => setTotpRemovalModalShown(false)}
      />}
      {removalSuccessModalShown && <MfaSuccessModal
        content={<Trans {...messages.totpRemovalSetupSuccessContent} />}
        onClose={() => setRemovalSuccessModalShown(false)}
        title={<Trans {...messages.totpRemovalSetupSuccessTitle} />}
      />}
    </>
  )}
