import styled from '@emotion/styled'

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  font-size: 14px;

  & > button {
    text-transform: uppercase;
  }

  & > p {
    text-align: center;
  }
`
