import {Typography, FormHelperText, withStyles} from '@material-ui/core'
import {Trans, withNamespaces, WithNamespaces} from 'react-i18next'
import messages from '../../../../assets/messages'
import {isMobile} from '../../../../common/utils'
import LoadingButton from '../../../Common/LoadingButton'
import PageSubTitle from '../../../Common/PageSubTitle'
import {PasswordInput} from './components/PasswordInput'
import {useState} from 'react'
import {ClassNameMap} from '@material-ui/core/styles/withStyles'
import {useHistory, useParams} from 'react-router-dom'
import {backendRequest} from '../../../../common/utils/requests'

const styles = theme => ({
  errorMessage: {
    color: theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
})

type PasswordFormProps = {
  classes?: Partial<ClassNameMap<keyof ReturnType<typeof styles>>>;
} & WithNamespaces

export const _PasswordForm = ({classes, t}: PasswordFormProps) => {
  const [errorPassword, setErrorPassword] = useState('')
  const [errorPasswordConfirmation, setErrorPasswordConfirmation] = useState('')
  const [password, setPassword] = useState('')
  const [confirmationPassword, setConfirmationPassword] = useState('')
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(false)
  const [submitError, setSubmitError] = useState('')
  const params = useParams<{ pammManagerId: string, pammOfferId: string }>()
  const offerId = Number(params.pammOfferId)
  const managerId = Number(params.pammManagerId)
  const history = useHistory()

  const validate = (): boolean => {
    if (password.length === 0) {
      setErrorPassword(t(messages.requiredField.i18nKey, messages.requiredField.defaults))
      return false
    }
    if (confirmationPassword.length === 0) {
      setErrorPasswordConfirmation(t(messages.requiredField.i18nKey, messages.requiredField.defaults))
      return false
    }
    if (password!== confirmationPassword) {
      setErrorPasswordConfirmation(t(messages.passwordsNotSame.i18nKey, messages.passwordsNotSame.defaults))
      return false
    }
    return true
  }

  const handleSubmit = async () => {
    if (!validate()) return
    setLoading(true)

    const result = await backendRequest(`/api/v2/dashboard/pamm/managers/${managerId}/offers/${offerId}/accept`, {password})
    const {error} = await result.json()
    if (result.ok && !error) {
      setStatus('success')
      setSubmitError('')
      history.push('/accounts')
    } else {
      setSubmitError(error)
      setStatus('failure')
    }
    setLoading(false)
  }

  return (
    <>
      <PageSubTitle>
        <Trans {...messages.enterPassword} />
        <Typography variant='caption'> <Trans {...messages.addAccountPassDesc} /></Typography>
      </PageSubTitle>
      <PasswordInput
        onChange={setPassword}
        error={errorPassword}
        value={password}
      />
      <PasswordInput
        onChange={setConfirmationPassword}
        error={errorPasswordConfirmation}
        value={confirmationPassword}
      />
      <LoadingButton
        id='loadingButton'
        onClick={handleSubmit}
        fullWidth={isMobile()}
        disabled={loading}
        status={status}
      >
        <Trans {...messages.invest} />
      </LoadingButton>
      {status==='failure' &&
      <FormHelperText className={classes?.errorMessage}>{submitError}</FormHelperText>}
    </>
  )
}

export const PasswordForm = withStyles(styles)(
  withNamespaces()(_PasswordForm)
)
