import styled from '@emotion/styled'

export const MfaSettingsContainer = styled.div`
  background-color: white;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  left: 25%;
  position: absolute;
  top: 160px;
  max-width: 605px;
  width: 75%;

  &::before {
    background-color: white;
    content: '';
    height: 100vh;
    position: absolute;
    width: 100vw;
  }

  & > * {
    z-index: 1;
  }

  @media(max-width: 600px) {
    left: 0;
  }
`
