import Typography from '@material-ui/core/Typography'
import {Trans} from 'react-i18next'
import messages from '../../../../../assets/messages'
import {useLayoutEffect, useMemo, useState} from 'react'
import {Step} from './components/Step'
import {StepNumber} from './components/StepNumber'
import {StepContent} from './components/StepContent'
import {StepHeader} from './components/StepHeader'
import {StepText} from './components/StepText'
import {CodeInput} from './components/CodeInput'
import LoadingButton from '../../../../Common/LoadingButton'
import {Buttons} from './components/Buttons'
import {CancelLink} from './components/CancelLink'
import QRCodeSVG from 'qrcode.react'

declare global {
  interface Window { otplib: any; }
}

type MfaSettingsProps = {
  email: string;
  onAgree: (otpSecret: string) => void;
  onDisagree: () => void;
}

export const MfaSettings = ({email, onAgree, onDisagree}: MfaSettingsProps) => {
  useLayoutEffect(() => {
    document.getElementsByTagName('html')[0].scrollTo(0,0)
  }, [])
  const [error, setError] = useState('')

  const otpSecret = useMemo(() => window.otplib.authenticator.generateSecret(), [])
  const uri = useMemo(() => window.otplib.totp.keyuri(email, 'TauroMarkets', otpSecret), [email, otpSecret])

  const [code, setCode] = useState('')

  const handleSubmit = () => {
    const codeValid = window.otplib.authenticator.verify({token: code, secret: otpSecret})
    if (codeValid) {
      onAgree(otpSecret)
    } else {
      setError('invalidTotpCode')
    }
  }

  const handleCancel = () => {
    onDisagree()
  }

  return (
    <>
      <Typography variant="h1">
        <Trans {...messages.setupYourAuthenticatorApp} />
      </Typography>
      <Step>
        <StepNumber>1</StepNumber>
        <StepContent>
          <StepHeader>
            <Trans {...messages.downloadAuthenticatorApp} />
          </StepHeader>
          <StepText>
            <Trans {...messages.getStartedMFA}>
              <a href="https://apps.apple.com/ru/app/google-authenticator/id388497605">Google Authenticator</a>
              <a href="https://apps.apple.com/ru/app/microsoft-authenticator/id983156458">Microsoft Authenticator</a>
            </Trans>
          </StepText>
        </StepContent>
      </Step>
      <Step>
        <StepNumber>2</StepNumber>
        <StepContent>
          <StepHeader>
            <Trans {...messages.linkYourAuthenticatorApp} />
          </StepHeader>
          <StepText>
            <Trans
              {...messages.linkYourAuthenticatorAppText}
              components={[
                <b>{otpSecret}</b>
              ]}
              values={{code: otpSecret}}
            />
          </StepText>
          <QRCodeSVG size={166} value={uri} />
        </StepContent>
      </Step>
      <Step>
        <StepNumber>3</StepNumber>
        <StepContent>
          <StepHeader>
            <Trans {...messages.enterConfirmationCode} />
          </StepHeader>
          <StepText>
            <Trans {...messages.useAuthenticatorApp} />
          </StepText>
          <CodeInput value={code} onChange={setCode} />
        </StepContent>
      </Step>
      <Buttons>
        {error && (<Typography color="error" variant="h5">
          <Trans {...messages[error]} />
        </Typography>)}
        <LoadingButton
          disabled={false}
          onClick={handleSubmit}
        >
          <Trans {...messages.verify} />
        </LoadingButton>
        <CancelLink onClick={handleCancel}>
          <Trans {...messages.cancelTotp} />
        </CancelLink>
      </Buttons>
    </>
  )}
