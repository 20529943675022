import styled from '@emotion/styled'

export const BlueZone = styled('div')`
  background-color: rgba(240, 248, 255, 1);
  border-color: rgba(128, 193, 255, 1);
  border-radius: 8px;
  border-style: dashed;
  border-width: 2px;
  margin: 1rem 0;
  padding: 1rem;
  padding-left: 6rem;

  & > b {
    font-weight: 700;
  }

  & > p {
    margin: 0.125rem;
  }
`
