import styled from '@emotion/styled'

export const StepNumber = styled.div`
  align-items: center;
  background-color: #E5E5E5;
  border-radius: 50%;
  color: black;
  display: flex;
  height: 40px;
  justify-content: center;
  min-width: 40px;
  width: 40px;
`
