export const styles = theme => ({
  secondary: {
    margin: theme.spacing(1),
    marginLeft: 0,
    '&:hover': {
      'cursor': 'pointer',
    }
  },
  errorMessage:{
    color:  theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  linkText:{
    color:  theme.palette.primary.main,
  },
  helperTextError:{
    color:  theme.palette.error.main,
  },
  button:{
    marginTop: 0,
    marginRight: 0,
  },
  disabledField:{
    color: theme.palette.secondary.dark,
    cursor: 'pointer'
  },
  orangeButton: {
    backgroundColor: '#ef9756',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#ef7e2b',
    }
  },
  pageDescription:{
    fontSize: 11,
    fontStyle: 'italic',
    marginBottom: 20,
  },
  pageSubtitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '15px 30px 15px 0',
    gap: 20,
    [theme.breakpoints.down('sm')]: {
      margin: '15px 0 15px 0',
      padding: '0 30px 0 30px',
    },
  },
  fieldsContainer: {
    display: 'flex',
    gap: 25,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  fieldsColumn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },
  fieldsRow: {
    display: 'flex',
    gap: 10,
    minHeight: 60,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  buttonsContainer: {
    width: '100%',
    maxWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    marginLeft: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'initial',
    },
  },
  field: {
    width: '100%',
  },
  dateInput:{
    width: '100%'
  },
  helperTextContainer: {
    position: 'relative'
  },
  helperButton: {
    position: 'absolute',
    top: '2px',
    width: 'max-content',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    padding: 0,
  },
}) as const
