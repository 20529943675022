import {Trans} from 'react-i18next'
import {ManagerOffer} from '../../../Accounts/PammManagerSettingsView/components/PammManagerOffers/useOffers'
import {Column} from '../Column'
import {Label} from '../Label'
import {Row} from '../Row'
import messages from '../../../../assets/messages'
import {LargeText} from '../../../Accounts/PammManagerSettingsView/components/PammManagerOffers/components/OfferTile/components/LargeText'
import {VerticalSpace} from '../VerticalSpace'
import {mapIntervalNameToI18nKeyPlural, mapIntervalNameToI18nKeySingular} from '../../../Accounts/PammManagerSettingsView/components/PammManagerOffers/components/OfferTile'
import {getFormattedAmount} from '../../../../common/utils'
import {Amount, GreenAmount} from '../../../Accounts/PammManagerSettingsView/components/PammManagerOffers/components/OfferTile/components/GreenAmount'

type ConditionsProps = {
  offer: ManagerOffer
}

export const Conditions = ( {offer}: ConditionsProps ) => (
  <Row>
    <Column>
      <Label>
        <Trans {...messages.tradingInterval} />
      </Label>
      <LargeText>
        {offer.settings.tradingInterval.count}
        {' '}
        <Trans {...(offer.settings.tradingInterval.count === 1 ? mapIntervalNameToI18nKeySingular : mapIntervalNameToI18nKeyPlural)[offer.settings.tradingInterval.type]} />
      </LargeText>
      <VerticalSpace />
      <Label>
        <Trans {...messages.minWithdrawal} />
      </Label>
      <Amount>
        {getFormattedAmount({amount: offer.settings.minWithdrawal, currency: offer.currency})}
      </Amount>
    </Column>
    <Column>
      <Label>
        <Trans {...messages.minDeposit} />
      </Label>
      <GreenAmount>
        {getFormattedAmount({amount: offer.settings.minDeposit, currency: offer.currency})}
      </GreenAmount>
      <VerticalSpace />
      <Label>
        <Trans {...messages.minInitialInvestment} />
      </Label>
      <GreenAmount>
        {getFormattedAmount({amount: offer.settings.minInitialInvestment, currency: offer.currency})}
      </GreenAmount>
    </Column>
  </Row>
)
