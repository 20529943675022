/*@ts-check*/
import Paper, {PaperProps} from '@material-ui/core/Paper'
import {PropsWithChildren} from 'react'
import {AccountSearchSelectProps} from '..'

type MenuProps = {
  selectProps:   Pick<AccountSearchSelectProps, 'classes'>
  innerProps: PaperProps;
}

export function Menu(props: PropsWithChildren<MenuProps>) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  )
}
