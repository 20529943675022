import {ReactElement, memo} from 'react'
import compose from 'lodash/flowRight'
import noop from 'lodash/noop'

import {withStyles, WithStyles} from '@material-ui/core/styles'
import {withNamespaces, Trans, WithNamespaces} from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import {CORRESPONDENCE_ADDRESS_FIELDS_NAMES} from '../constants'
import CountriesSelect from '../../../../../Common/CountriesSelect'
import messages from '../../../../../../assets/messages'
import AddressAutocomplete from '../../../../../Common/AddressAutocomplete'
import {styles} from '../styles'
import {AddressFields} from '../AddressFields'

type CorrespondenceAddressFieldsProps = {
  values: Record<string, string>,
  errors: Record<string, boolean>,
  errorsKeys: Record<string, string>,
  countryList: {key: string, value: string, label: string, callingCode: string}[],
  geocoderService: any,
  isUseResidentialAddressAsCorrespondenceAddress: boolean,
  onChange: (fieldName: string, value: string) => void,
  onSwitchUseResidentialAddressAsCorrespondenceAddress: () => void,
} & WithNamespaces & WithStyles

function CorrespondenceAddressFieldsBase(props: CorrespondenceAddressFieldsProps): ReactElement {
  const {
    countryList,
    errors,
    errorsKeys,
    classes,
    values,
    geocoderService,
    t,
    isUseResidentialAddressAsCorrespondenceAddress,
    onChange,
    onSwitchUseResidentialAddressAsCorrespondenceAddress
  } = props
  const {
    [CORRESPONDENCE_ADDRESS_FIELDS_NAMES.line1]: line1,
    [CORRESPONDENCE_ADDRESS_FIELDS_NAMES.country]: country,
    [CORRESPONDENCE_ADDRESS_FIELDS_NAMES.houseNumber]: houseNumber,
    [CORRESPONDENCE_ADDRESS_FIELDS_NAMES.city]: city,
    [CORRESPONDENCE_ADDRESS_FIELDS_NAMES.zip]: zip,
    [CORRESPONDENCE_ADDRESS_FIELDS_NAMES.region]: region
  } = values

  return (
    <AddressFields
      title={<Trans {...messages.correspondenceAddress} />}
      country={country}
      geocoderService={geocoderService}
      onChange={onChange}
      renderAddressFields={({
        countrySelectValue,
        onAddressAutocompleteChange,
        onAddressAutoCompleteSelect,
        onChangeCountry,
        onTextFieldChange,
      }) => (
        <div className={classes.fieldsContainer}>
          <div className={classes.fieldsColumn}>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={isUseResidentialAddressAsCorrespondenceAddress}
                    onChange={onSwitchUseResidentialAddressAsCorrespondenceAddress}
                    value={isUseResidentialAddressAsCorrespondenceAddress}
                  />
                }
                label={t(messages.useMyResidentialAddressAsTheCorrespondenceAddress.i18nKey, messages.useMyResidentialAddressAsTheCorrespondenceAddress.defaults)}
              />
            </div>
            <div className={classes.fieldsRow}>
              <div className={classes.field}>
                {geocoderService ? (
                  <AddressAutocomplete
                    disabled={isUseResidentialAddressAsCorrespondenceAddress}
                    address={line1}
                    onChange={onAddressAutocompleteChange(CORRESPONDENCE_ADDRESS_FIELDS_NAMES.line1)}
                    onSelect={onAddressAutoCompleteSelect}
                    error={errors[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.line1]}
                    country={country}
                  />
                ) : (
                  <TextField
                    required
                    disabled={isUseResidentialAddressAsCorrespondenceAddress}
                    id="line1"
                    label={t(messages.streetLabel.i18nKey, messages.streetLabel.defaults)}
                    fullWidth
                    value={line1}
                    name={CORRESPONDENCE_ADDRESS_FIELDS_NAMES.line1}
                    error={errors[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.line1]}
                    onChange={onTextFieldChange(CORRESPONDENCE_ADDRESS_FIELDS_NAMES.line1)}
                  />
                )}
                {!line1 ? (
                  <FormHelperText className={classes.errorMessage}>
                    <Trans {...messages.streetNameRequired} />
                  </FormHelperText>
                ) : null}
                {line1 && errors.line1 && messages[errorsKeys[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.line1]] ? (
                  <FormHelperText className={classes.errorMessage}>
                    <Trans {...messages[errorsKeys[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.line1]]} />
                  </FormHelperText>
                ) : null}
              </div>
              <div className={classes.field}>
                <TextField
                  fullWidth
                  required
                  disabled={isUseResidentialAddressAsCorrespondenceAddress}
                  id={CORRESPONDENCE_ADDRESS_FIELDS_NAMES.houseNumber}
                  name={CORRESPONDENCE_ADDRESS_FIELDS_NAMES.houseNumber}
                  label={t(messages.houseNumberLabel.i18nKey, messages.houseNumberLabel.defaults)}
                  autoComplete={CORRESPONDENCE_ADDRESS_FIELDS_NAMES.houseNumber}
                  error={errors[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.houseNumber]}
                  value={houseNumber}
                  onChange={onTextFieldChange(CORRESPONDENCE_ADDRESS_FIELDS_NAMES.houseNumber)}
                />
                {!houseNumber ? (
                  <FormHelperText className={classes.errorMessage}>
                    <Trans {...messages.houseNumberRequired} />
                  </FormHelperText>
                ) : null}
                {houseNumber && errors[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.houseNumber] && messages[errorsKeys[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.houseNumber]] ? (
                  <FormHelperText className={classes.errorMessage}>
                    <Trans {...messages[errorsKeys[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.houseNumber]]} />
                  </FormHelperText>
                ) : null}
              </div>
              <div className={classes.field}>
                <TextField
                  required
                  disabled={isUseResidentialAddressAsCorrespondenceAddress}
                  id={CORRESPONDENCE_ADDRESS_FIELDS_NAMES.city}
                  name={CORRESPONDENCE_ADDRESS_FIELDS_NAMES.city}
                  label={t(messages.cityLabel.i18nKey, messages.cityLabel.defaults)}
                  fullWidth
                  autoComplete={CORRESPONDENCE_ADDRESS_FIELDS_NAMES.city}
                  value={city}
                  error={errors[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.city]}
                  onChange={onTextFieldChange(CORRESPONDENCE_ADDRESS_FIELDS_NAMES.city)}
                />
                {city && errors[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.city] && messages[errorsKeys[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.city]] ? (
                  <FormHelperText className={classes.errorMessage}>
                    <Trans {...messages[errorsKeys[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.city]]} />
                  </FormHelperText>
                ) : null}
              </div>
            </div>
            <div className={classes.fieldsRow}>
              <div className={classes.field}>
                <TextField
                  required
                  disabled={isUseResidentialAddressAsCorrespondenceAddress}
                  id={CORRESPONDENCE_ADDRESS_FIELDS_NAMES.zip}
                  name={CORRESPONDENCE_ADDRESS_FIELDS_NAMES.zip}
                  label={t(messages.zipLabel.i18nKey, messages.zipLabel.defaults)}
                  fullWidth
                  autoComplete={CORRESPONDENCE_ADDRESS_FIELDS_NAMES.zip}
                  value={zip}
                  error={errors[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.zip]}
                  onChange={onTextFieldChange(CORRESPONDENCE_ADDRESS_FIELDS_NAMES.zip)}
                />
                {zip && errors[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.zip] && messages[errorsKeys[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.zip]] ? (
                  <FormHelperText className={classes.errorMessage}>
                    <Trans {...messages[errorsKeys[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.zip]]} />
                  </FormHelperText>
                ) : null}
              </div>
              <div className={classes.field}>
                <TextField
                  required
                  disabled={isUseResidentialAddressAsCorrespondenceAddress}
                  id={CORRESPONDENCE_ADDRESS_FIELDS_NAMES.region}
                  name={CORRESPONDENCE_ADDRESS_FIELDS_NAMES.region}
                  label={t(messages.regionLabel.i18nKey, messages.regionLabel.defaults)}
                  fullWidth
                  value={region}
                  error={errors[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.region]}
                  onChange={onTextFieldChange(CORRESPONDENCE_ADDRESS_FIELDS_NAMES.region)}
                />
                {region && errors[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.region] && messages[errorsKeys[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.region]] ? (
                  <FormHelperText className={classes.errorMessage}>
                    <Trans {...messages[errorsKeys[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.region]]} />
                  </FormHelperText>
                ) : null}
              </div>
              <div className={classes.field}>
                <CountriesSelect
                  returnKey
                  showRequired
                  disabled={isUseResidentialAddressAsCorrespondenceAddress}
                  countryList={countryList}
                  handleChangeField={onChangeCountry}
                  handleChange={onChange}
                  setStateOuter={noop}
                  errors={errors}
                  value={countrySelectValue?.label}
                  name={CORRESPONDENCE_ADDRESS_FIELDS_NAMES.country}
                  label={t(messages.countryLabel.i18nKey, messages.countryLabel.defaults)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    />
  )
}

const CorrespondenceAddressFields = memo(compose(withStyles(styles), withNamespaces())(CorrespondenceAddressFieldsBase))
export {CorrespondenceAddressFields}
