import {Theme} from '@material-ui/core'

export const styles = (theme: Theme )=> ({
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap' as const,
    flex: 1,
    overflow: 'hidden',
    cursor: 'pointer',
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
    marginTop:-3,
  },
  placeholder: {
    position: 'absolute'as const,
    [theme.direction === 'rtl' ? 'right' : 'left']: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute'as const,
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  grey: {
    color: theme.palette.secondary.main
  },
  spanBalance:{
    position: 'absolute'as const,
    [theme.direction === 'rtl' ? 'left' : 'right']: 50
  },
  spanBalanceRight:{
    position: 'absolute'as const,
    [theme.direction === 'rtl' ? 'left' : 'right']: 16
  },
  fontLight:{
    fontWeight: 300
  },
  menuItemRoot: {
    paddingTop: 10,
    paddingBottom: 10
  },
})
