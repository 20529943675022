import React, {Component} from 'react'
import {get, find, map, reject, values, isEqual, some, isEmpty, filter, keys, flowRight as compose, trim, omitBy, endsWith} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import Hidden from '@material-ui/core/Hidden'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import withStyles from '@material-ui/core/styles/withStyles'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import withWidth, {isWidthDown} from '@material-ui/core/withWidth'
import {withdrawalPaymentVendors, withdrawalPaymentFields, currencies} from '@bdswiss/common-enums'
import ConfirmWithdrawalModal from './ConfirmWithdrawalModal'
import WithdrawalFields from './WithdrawalFields'
import PageSubTitle from '../../Common/PageSubTitle'
import {config} from '../../../config'
import messages from '../../../assets/messages'
import {getPlatform, isMobile} from '../../../common/utils/browser'
import {getAccountLabel, isCentAccount, isTradesmarterAccount, isWalletAccount} from '../../../common/utils/accounts'
import {getFormattedAmount, checkIbanMatchSwiftCountry} from '../../../common/utils/general'
import AppContext from '../../Common/contexts/AppContext'
import {isValidIBAN, isValidBIC, electronicFormatIBAN} from 'ibantools'
import {withdrawalWarnings} from '../../../common/utils/uioptions'
import CloseIcon from '@material-ui/icons/Close'
import {InnerAppContext} from '../../../common/types'

const styles = theme => ({
  error: {
    color: theme.palette.red.color
  },
  messageGrid: {
    marginTop: 30,
  },
  highlight: {
    cursor: 'pointer',
    color: theme.palette.primary.main
  },
  highlightRed: {color: theme.palette.red.color, fontWeight: 500},
  listItem: {
    marginTop: 10
  },
  paddingLeft:{
    paddingLeft:40
  },
  bolder:{
    fontWeight:400
  },
  noticeDiv: {
    backgroundColor: theme.palette.lightyellow.color,
    padding: 12,
    marginLeft: 20,
    position: 'relative' as const
  },
  displayInline: {
    display: 'inline'
  },
  close: {
    position: 'absolute' as const,
    fontSize: 18,
    right: 5,
    top: 5,
    cursor: 'pointer',
    color: theme.palette.grey[500],
  },
  hidden: {
    display: 'none'
  }
})

const getMethodFields = (method, country, paymentOption?) => (
  values(withdrawalPaymentFields).filter((field) =>
    ((field.visible && field.visible({})) ||
      field.commonField ||
      (field.paymentVendors && field.paymentVendors.includes(method) && !field.visible)) &&
    field.key !== 'amount' &&
    ((field.hidden && !field.hidden({country, paymentOption})) || !field.hidden)
  )
)

const skipGenericMessageGroups = ['creditCard', 'bankTransfer', 'cryptocurrency']

class ChooseMethod extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  constructor(props) {
    super(props)
    this.state = {
      method: '',
      methodFields: [],
      form: {},
      errors: {},
      confirmWithdrawalOpen: false,
      buttonLoading: false,
      showWarning: true,
    }
    if (!isEmpty(get(props, 'availableMethod'))) {
      const method = get(props, 'availableMethod.vendor')
      const methodFields = getMethodFields(method, get(props, 'viewer.country'), get(props.availableMethod, 'paymentOption'))
      //@ts-ignore todo: this can be fixed
      this.state.method = method
      //@ts-ignore
      this.state.methodFields = methodFields
      for (const field of methodFields) {
        this.state.form[field.key] = get(props, `availableMethod[${field.key}]`) || ''
      }
    }
  }

  onSubmit() {
    const {onSubmit, amount, account} = this.props
    const {method, methodFields, form} = this.state

    this.setState({buttonLoading: true})

    const errors = {}
    for (const field of methodFields) {
      errors[field.key] = !form[field.key]
    }

    if (some(errors)) {
      this.setState({errors, buttonLoading: false})
      return
    }

    const variables = {
      amount,
      paymentVendor: method,
      paymentFields: omitBy(form, (_, k) => endsWith(k, '_label')),
      accountId: account.id,
      platform: JSON.stringify(getPlatform())
    }
    onSubmit(variables)
  }

  clickWithdrawal() {
    const {methodFields, form} = this.state
    const errors : any = {}
    form.iban = form.iban && electronicFormatIBAN(form.iban)!.toUpperCase()
    form.swiftCode = form.swiftCode && trim(form.swiftCode).toUpperCase()
    const isIBANValid = form.iban && isValidIBAN(form.iban)
    const isBICValid = form.swiftCode && isValidBIC(form.swiftCode)

    for (const field of methodFields) {
      errors[field.key] = !form[field.key]
      // Generic logic to validate equality of confirmation fields, like password_confirmation
      // or wallet_address_confirmation
      if (field.key.endsWith('Confirmation')) {
        const [masterFieldKey] = field.key.split('Confirmation')
        const masterFieldValue = form[masterFieldKey]
        const confirmationFieldValue = form[field.key]

        if (masterFieldValue === undefined || !confirmationFieldValue) continue
        if (masterFieldValue !== confirmationFieldValue) errors[field.key] = 'validation'
      }

      if (field.key === 'iban' && !isEmpty(form[field.key])) {
        errors[field.key] = !isIBANValid && 'validation'
        if (errors[field.key] && (!isNaN(form[field.key]) && form[field.key].length >= 6 && form[field.key].length <= 20)) {
          errors[field.key] = false
        }
      }

      if (field.key === 'swiftCode' && isIBANValid && !isEmpty(form[field.key])) {
        errors[field.key] = !isBICValid  && 'validation'
      }
    }

    errors.ibanSwiftNotCoherent = form.iban && form.swiftCode && !errors.iban && !errors.swiftCode &&
      isIBANValid && checkIbanMatchSwiftCountry(form.iban, form.swiftCode)

    if (some(errors)) {
      this.setState({errors})
      return
    }
    this.setState({confirmWithdrawalOpen: true})
  }

  onMethodChange(method) {
    const {method: currentMethod} = this.state
    if (!isEqual(method, currentMethod)) {
      this.setState({
        method,
        methodFields: getMethodFields(method, get(this.props, 'viewer.country')),
        form: {},
        errors: {}
      })
    }
  }

  handleChange(prop, value, label?) {
    const {form, errors, method, methodFields: currentMethodFields, form: {paymentOption: currentPaymentOption}} = this.state

    const update = {
      form: {
        ...form,
        [prop]: value,
      },
      errors: {
        ...errors,
        [prop]: !value,
        ibanSwiftNotCoherent: false
      },
      methodFields: prop === 'paymentOption' && !isEqual(value, currentPaymentOption)
        ? getMethodFields(method, get(this.props, 'viewer.country'), value)
        : currentMethodFields,
    }

    if (label) {
      update.form[`${prop}_label`] = label
    }

    this.setState(update)
  }

  renderTnCandSubmit(termsAndConditionsField) {
    const {classes, account, width} = this.props
    const {method, form, errors} = this.state
    const {locale} = this.context
    const termsLink = isTradesmarterAccount(account) ? config.weblinks.binaryTerms :config.weblinks.termsAndConditions.replace('{lang}', locale)

    return <>
      {termsAndConditionsField &&
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!get(form, termsAndConditionsField.key, '')}
                onChange={(e) => this.handleChange(termsAndConditionsField.key, e.target.checked ? 'accept' : '')}
                value={get(form, termsAndConditionsField.key, '')}
                className={get(errors, termsAndConditionsField.key, false) ? classes.error : ''}
              />
            }
            label={
              <Trans
                {...messages.withdrawalTermsAndConditions}
                components={[
                  <a target="_blank" rel="noopener noreferrer" href={termsLink} className={classes.highlight}>termsLink</a>
                ]}
              />
            }
          />
        </Grid>}
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => this.clickWithdrawal()}
          disabled={isEmpty(method)}
          fullWidth={isWidthDown('sm', width)}
        >
          <Trans {...messages.withdraw} />
        </Button>
      </Grid>
    </>
  }

  render() {
    const {classes, account, amount, viewer: {locale, country}, goBack,submitLoading, availableMethod} = this.props
    const {method, methodFields, form, errors, confirmWithdrawalOpen, buttonLoading, showWarning} = this.state
    const {companyObject} = this.context
    const {featuresConfig: {showWithdrawalWarning}, transactionsConfig} = config
    const {availableWithdrawalOptions} = account
    const accountLabel = getAccountLabel(account, locale)
    const formattedAmount = isCentAccount(account)
      ? getFormattedAmount({amount: amount / (currencies.CUD.baseCurrencyRate ?? 1), currency: currencies.CUD.baseCurrency, locale})
      : getFormattedAmount({amount, currency: account.currency, locale})
    const availableMethods = reject(withdrawalPaymentVendors,
      (v) => v.disabled || v.key === withdrawalPaymentVendors.goldDelivery.key ||
      !availableWithdrawalOptions.includes(v.key)
    )

    const termsAndConditionsField = find(withdrawalPaymentFields, {value: 'terms_and_conditions'})

    const groupName = get(withdrawalPaymentVendors[method], 'groupName', 'other')
    let footnote
    if (get(withdrawalPaymentVendors[method], 'hasFootnote', false)) {
      footnote = messages[`${method}WithdrawalFootnote`]
    }
    const methodMessages : any = groupName ? filter(keys(messages), (m) => m.indexOf(`${groupName}WithdrawalDescription`) === 0).reduce((m, i) => {
      m[i] = messages[i]
      return m
    }, {}) : {}
    const hasWarning = showWithdrawalWarning && showWarning && get(withdrawalWarnings, method)

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageSubTitle>
            <Trans
              {...messages.withdrawalMessage}
              values={{amount: formattedAmount, accountLabel}}
              components={[
                <span onClick={() => goBack()} className={classes.highlight}>amount</span>,
                <span onClick={() => goBack()} className={classes.highlight}>account</span>
              ]}
            />
          </PageSubTitle>
        </Grid>
        <Grid item  xs={12} sm={8} lg={6}>
          <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="select-method"><Trans {...messages.withdrawalMethod} /></InputLabel>
                <Select
                  value={method}
                  onChange={(e) => this.onMethodChange(e.target.value)}
                  error={get(errors, 'method', false)}
                  inputProps={{
                    name: 'method',
                    id: 'select-method',
                  }}
                  disabled={!isEmpty(availableMethod)}
                >
                  {map(availableMethods, (m) => (
                    <MenuItem
                      value={m.key}
                      key={m.key}
                    >
                      {m.localization && m.localization.t(locale)}
                    </MenuItem>
                  ))}
                </Select>
                {get(errors, 'method', false) &&
                  <FormHelperText className={classes.error}><Trans {...messages.requiredField} /></FormHelperText>
                }
                {footnote && <FormHelperText><Trans {...footnote} /></FormHelperText>}
              </FormControl>
            </Grid>
            <WithdrawalFields
              country={country}
              errors={errors}
              form={form}
              availableMethod={availableMethod}
              methodFields={methodFields}
              method={method}
              handleChange={this.handleChange.bind(this)} />
            {!isMobile() && this.renderTnCandSubmit(termsAndConditionsField)}
          </Grid>
        </Grid>
        <Grid item sm={6} xs={12}>
          {hasWarning && showWarning && <Grid container spacing={3} className={classes.noticeDiv}>
            <CloseIcon className={classes.close} onClick={()=> this.setState({showWarning: false})}/>
            <Grid item xs={12}>
              <Typography variant='h4' className={classes.displayInline}>
                <Trans {...messages[get(hasWarning, 'title')]} />
              </Typography>
            </Grid>
            {map(get(hasWarning, 'content'), (content, index) => <Grid item xs={12} key={index}>
              <Typography variant='body1'>
                <Trans {...messages[content]} />
              </Typography>
            </Grid>)}
          </Grid>}
          <Hidden smDown>
            <Grid item sm={12} className={classes.paddingLeft}>
              <PageSubTitle>
                <Trans {...messages.attention} />
              </PageSubTitle>
            </Grid>
          </Hidden>
          <Grid container direction="column" spacing={1}>
            <Grid item xs={12} sm={11}>
              <ul key="information">
                {isWalletAccount(account.__typename) ?
                  <li className={classes.listItem}>
                    <Typography variant='body1'>
                      <Trans {...messages.generalWithdrawalDescriptionPartner} />
                    </Typography>
                  </li>
                  : <React.Fragment>
                    {map((methodMessages), (m) =>
                      <li key={m.i18nKey} className={classes.listItem}>
                        <Typography variant='body1'>
                          <Trans
                            {...m}
                            values={{
                              company: companyObject.brandLabel,
                              hours: get(transactionsConfig, `withdrawal.${method}.waitingHours`) || '48',
                            }}
                            components={[
                              <span className={classes.bolder}>highlight</span>,
                              <span className={get(transactionsConfig, `withdrawal.${method}.notShowSepaMsg`) ? classes.hidden : ''}>message</span>,
                              <span className={`${classes.highlightRed}`}>highlight red</span>
                            ]}
                          />
                        </Typography>
                      </li>
                    )}
                    {skipGenericMessageGroups.includes(groupName) === false && <li className={classes.listItem}>
                      <Typography variant='body1'>
                        <Trans {...messages.generalWithdrawalDescription} values={{company:companyObject.brandLabel}}/>
                      </Typography>
                    </li>}
                  </React.Fragment>}
              </ul>
            </Grid>
          </Grid>
        </Grid>
        {isMobile() && this.renderTnCandSubmit(termsAndConditionsField)}
        <ConfirmWithdrawalModal
          open={confirmWithdrawalOpen}
          locale={locale}
          methodFields={methodFields}
          form={form}
          onSubmit={() => this.onSubmit()}
          onCancel={() => this.setState({confirmWithdrawalOpen: false})}
          method={method}
          amount={formattedAmount}
          accountLabel={accountLabel}
          buttonLoading={submitLoading && buttonLoading}
          country={country}
          availableMethod={availableMethod}
        />
      </Grid>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withWidth(),
  withNamespaces(),
)(ChooseMethod)
