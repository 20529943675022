import {Overlay} from '../components/shared/Overlay'
import {TotpRemovalForm} from './components/TotpRemovalForm'
import {TotpRemovalModal} from './components/TotpRemovalModal'

type TotpRemovalPopupProps = {
  error: string;
  onSubmit: (password: string, code: string) => void;
  onCancel: () => void;
}

export const TotpRemovalPopup = ({
  error,
  onSubmit,
  onCancel,
}: TotpRemovalPopupProps) => (
  <>
    <Overlay onClick={onCancel} />
    <TotpRemovalModal>
      <TotpRemovalForm
        error={error}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </TotpRemovalModal>
  </>
)
