import {ReactElement, ReactNode} from 'react'

import {Tooltip, Typography} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import {withNamespaces, WithNamespaces} from 'react-i18next'

import messages from '../../../../../../assets/messages'

import {useStyles} from './styles'

type AccountTypeTooltipProps = {
  accountSubtype: ReactNode;
} & WithNamespaces

function AccountTypeTooltipBase(props: AccountTypeTooltipProps): ReactElement | null {
  const {accountSubtype, t} = props
  const {containerClassName, infoIconClassName} = useStyles()

  if (!accountSubtype) return null

  return (
    <Tooltip arrow placement='top' title={t(messages.copyTradingAccountSubtypeRequirement.i18nKey, {accountSubtype})}>
      <Typography className={containerClassName} variant='body2'>
        {accountSubtype}
        <InfoIcon className={infoIconClassName} />
      </Typography>
    </Tooltip>
  )
}

const AccountTypeTooltip = withNamespaces()(AccountTypeTooltipBase)
export {AccountTypeTooltip}
