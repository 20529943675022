import {MfaSettings} from '../../../../Auth/Registration/components/MfaSettings'
import {Modal} from '../components/shared/Modal'
import {Overlay} from '../components/shared/Overlay'
import {MfaModalBody} from './components/MfaModalBody'

type MfaSettingsPopupProps = {
  onAgree: (secret: string) => void;
  onDisagree: () => void;
  email: string;
}

export const MfaSettingsPopup = ({
  onAgree,
  onDisagree,
  email,
}: MfaSettingsPopupProps) => (
  <>
    <Overlay onClick={onDisagree} />
    <Modal>
      <MfaModalBody>
        <MfaSettings
          email={email}
          onAgree={onAgree}
          onDisagree={onDisagree}
        />
      </MfaModalBody>
    </Modal>
  </>
)
