import {useReducer} from 'react'
import {AccountCardProps} from '../types'
import {reducer} from './reducer'
import {initialState} from './initialState'
import {ActionType} from './actions'
import {toKartwheelIBPortal} from './toKartwheelIbPortal'
import AccountCard from '../../../AccountCard'
import {IbAccountPendingPopup} from './components/IbAccountPendingPopup'
import {isApprovedAccount} from '../../../../../common/utils/accounts'

export const IbAccountCard: React.FC<AccountCardProps> = ({account}) => {
  const [
    {
      showIbAccountPendingPopup,
    },
    dispatch
  ] = useReducer(reducer, {...initialState, account})

  const closeModals = () => {
    dispatch({type: ActionType.CloseModal})
  }

  const handleClick = () => {
    if (account != null && isApprovedAccount(account)) {
      toKartwheelIBPortal(account)
    } else {
      dispatch({type: ActionType.Click})
    }
  }

  return (
    <div data-testid='ib-account-card' style={{height: '100%'}}>
      <AccountCard account={account} onClick={handleClick} />
      <IbAccountPendingPopup
        onAgree={closeModals}
        onClose={closeModals}
        open={showIbAccountPendingPopup}
      />
    </div>
  )
}
