import React from 'react'
import PropTypes from 'prop-types'
import {map, get} from 'lodash'
import {currencies} from '@bdswiss/common-enums'
import MenuItem from '@material-ui/core/MenuItem'
import TextField, {TextFieldProps} from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles'
import {getAccountLabel, isCentAccount} from '../../common/utils/accounts'
import {isMobile, getFormattedAmount} from '../../common/utils'
import classNames from 'classnames'
import {Grid} from '@material-ui/core'
import {Account, BaseAccount, Currency} from '../../graphql/types'
import {OmitMaybe} from '../../shared/typescript'

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10,
  },
  subnote: {
    fontSize: 14,
    textAlign: 'right' as const,
  },
  textRight: {
    textAlign: 'right' as const,
  },
  greyText: {
    color: theme.palette.grey[500],
  },
})

type AccountDataType = Pick<BaseAccount, 'id' | 'currency' | 'remoteId'>;
type WithoutMaybe = OmitMaybe<AccountDataType>;
type AccountType = WithoutMaybe & {
  balance?: BaseAccount['balance'];
  pendingAmount: number;
  __typename: string;
};

type AccountSelectProps = {
  accounts: AccountType[];
  locale: string;
} & WithStyles<typeof styles> &
  TextFieldProps;

const AccountSelect = withStyles(styles, {withTheme: true})(
  (props: AccountSelectProps) => {
    const {accounts, classes, locale, ...otherProps} = props
    return (
      <FormControl fullWidth={true}>
        <TextField
          inputProps={{className: classNames(!isMobile() && classes.root)}}
          select
          {...otherProps}
        >
          {map(accounts, (account) => {
            let formattedPendingAmount, usdForCentAccount
            if (get(account, 'pendingAmount', 0) > 0) {
              formattedPendingAmount = getFormattedAmount({
                locale,
                amount: account.pendingAmount,
                currency: account.currency,
              })
            }
            if (isCentAccount({__typename: account.__typename})) {
              usdForCentAccount = getFormattedAmount({
                locale,
                amount: account.balance ?? 0 / (currencies.CUD.baseCurrencyRate ?? 1),
                currency: currencies.CUD.baseCurrency,
              })
            }
            const formattedBalance = getFormattedAmount({
              locale,
              amount: account.balance,
              currency: account.currency,
            })
            const hasBalance = account.balance != null && account.balance >= 0
            const accountLabel = getAccountLabel(account as unknown as Account, locale)
            return (
              <MenuItem
                value={account.id}
                key={account.id}
                className={classNames(!isMobile() && classes.root)}
              >
                {!isMobile() ? (
                  <React.Fragment>
                    <div>
                      {accountLabel}{' '}
                      <span className={classes.greyText}>- {account.remoteId}</span>
                    </div>
                    {hasBalance && (
                      <div className={classes.greyText}>
                        {formattedBalance}
                        {usdForCentAccount && !formattedPendingAmount && (
                          <div className={classes.subnote}>
                            {usdForCentAccount}
                          </div>
                        )}
                        {formattedPendingAmount && (
                          <div className={classes.subnote}>
                            (-{formattedPendingAmount})
                          </div>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <Grid container>
                    <Grid item xs={8}>
                      <div>{accountLabel}</div>
                      <div>
                        <span className={classes.greyText}>{account.remoteId}</span>
                      </div>
                    </Grid>
                    <Grid item xs={4} className={classes.textRight}>
                      {hasBalance && (
                        <div className={classes.greyText}>
                          {formattedBalance}
                          {usdForCentAccount && !formattedPendingAmount && (
                            <div className={classes.subnote}>
                              {usdForCentAccount}
                            </div>
                          )}
                          {formattedPendingAmount && (
                            <div className={classes.subnote}>
                              (-{formattedPendingAmount})
                            </div>
                          )}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                )}
              </MenuItem>
            )
          })}
        </TextField>
      </FormControl>
    )
  }
)

AccountSelect.propTypes = {
  locale: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      remoteId: PropTypes.string.isRequired,
      balance: PropTypes.number,
      currency: PropTypes.oneOf(Object.values(Currency)).isRequired,
      __typename: PropTypes.string.isRequired,
      pendingAmount: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
}

export default AccountSelect
