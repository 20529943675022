import React from 'react'
import _ from 'lodash'
import Intl from 'intl' // TODO: remove and replace with builtin
import {currencies} from '@bdswiss/common-enums'
import {config} from '../../config'

interface AmountProps {
  locale: string
  currency?: string
  value?: number
  classes?: Record<string, string> // TODO: add types after Intl fixing
}

const Amount = (props: AmountProps) => {
  const {locale, currency, value} = props
  const {hasAnotherCurrenciesSymbols} = config
  const classes = _.defaults(props.classes || {}, {
    root: 'amount'
  })
  const isCrypto = currency != null && _.get(currencies[currency], 'isCrypto', false)
  const noCents = currency != null &&  _.get(currencies[currency], 'noCents', false)
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency ?? 'USD',
    minimumFractionDigits: noCents ? 0 : isCrypto ? 8 : 2,
  })
  const hasBalance = value != null && value >= 0
  if (!hasBalance) {return <span className={classes.root}>-</span>}
  let parts = formatter.formatToParts(value).map(part => {
    const type =
      ['integer', 'group'].includes(part.type) ? 'value' : (part.type === 'decimal' ? 'fraction' : part.type)
    return {...part, type}
  }).reduce((parts, next) => {
    if (parts.length > 0 && parts[parts.length - 1].type === next.type) {

      const copy = [...parts]
      const lastPart = copy[copy.length - 1]
      copy[copy.length - 1] = {...lastPart, value: lastPart.value + next.value}
      return copy
    } else {
      return [...parts, next]
    }
  }, [])

  if (hasAnotherCurrenciesSymbols) {
    if (currency === currencies.USD.value || currency === currencies.EUR.value) {
      const currencySignPart = parts.find(part => part.type === 'currency')
      const currencyWithoutSign = parts.filter((part) => part.type !== 'currency')
      parts = [{...currencySignPart, value: `G${currency} (${currencySignPart.value})`}, ...currencyWithoutSign]
    }
  }
  if (!currency) {
    parts.shift() // remove currency part if no currency provided
  }

  return <span className={classes.root}>
    {
      parts.map((part, index) =>
        <span key={index} className={classes[part.type] || part.type}>{part.value}</span>
      )
    }
  </span>
}

export default Amount
