import {Trans, withNamespaces} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import messages from '../../../../../../../assets/messages'
import {AlertDialog} from '../../../../../../Common/Dialog'
import {PopupProps} from '../../../../../../../types'

const email = 'support@tauromarkets.com'
const titleI18nKey = 'ibAccountPendingTitle'

const _IbAccountPendingPopup = ({onClose, open, t}: PopupProps) => {
  const title = t(messages[titleI18nKey].i18nKey, messages[titleI18nKey].defaults)

  return (
    <AlertDialog
      open={open}
      onClose={onClose}
      title={title}
      agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1"><Trans {...messages.ibAccountPendingLine1} /></Typography>
          <Typography variant="body1">
            <Trans
              {...messages.ibAccountPendingLine2}
              components={[
                <a href={`mailto:${email}`}>{email}</a>,
              ]}
              values={{
                supportEmail: email,
              }}
            />
          </Typography>
        </Grid>
      </Grid>
    </AlertDialog>
  )
}

export const IbAccountPendingPopup = withNamespaces()<PopupProps>(
  _IbAccountPendingPopup
)
