import {Trans, withNamespaces, WithNamespaces} from 'react-i18next'
import messages from '../../../../../assets/messages'
import {Overlay} from '../components/shared/Overlay'
import {Modal} from '../components/shared/Modal'
import {ModalHeader} from '../components/shared/ModalHeader'
import {ModalBody} from '../components/shared/ModalBody'
import {BlueZone} from '../components/shared/BlueZone'
import {PendingMessage} from '../..'
import {Checkbox} from '../components/shared/Checkbox'
import {Button} from '@material-ui/core'
import {ButtonArea} from '../components/shared/ButtonArea'

type WelcomeMessagePopupProps = {
  acknowledgeSeen: (noticeId: number) => void;
  notice: PendingMessage;
} & WithNamespaces

export const _WelcomeMessagePopup = ({acknowledgeSeen, notice}: WelcomeMessagePopupProps) => {
  const metadata = JSON.parse(notice.metadata)
  const handleDismiss = () => {
    acknowledgeSeen(notice.id)
  }

  return (
    <>
      <Overlay onClick={handleDismiss} />
      <Modal>
        <ModalHeader>
          <Trans {...messages.welcomeMessagePopupTitle} />
        </ModalHeader>
        <ModalBody>
          <Trans {...messages.welcomeMessagePopupLine1} />
          <BlueZone>
            <p>
              <Trans {...(metadata.isMt5 ? messages.mt5Login : messages.mt4Login)} />:&nbsp;
              <b>{metadata.login}</b>
            </p>
            <p>
              <Trans {...(metadata.isMt5 ? messages.mt5Password : messages.mt4Password)} />:&nbsp;
              <b><Trans {...messages.passwordPlaceholderTauro} /></b>
            </p>
            <p>
              <Trans {...(metadata.isMt5 ? messages.mt5Server : messages.mt4Server)} />:&nbsp;
              <b>{metadata.mtServer}</b>
            </p>
          </BlueZone>
          <Trans {...messages.welcomeMessagePopupLine2} />
          <Checkbox>
            <Trans {...messages.iHaveNoted} />
          </Checkbox>
          <ButtonArea>
            <Button variant="contained" color="primary" onClick={handleDismiss}>
              <Trans {...messages.takeMeToMyTradingAccount} />
            </Button>
          </ButtonArea>
        </ModalBody>

      </Modal>
    </>
  )}

export const WelcomeMessagePopup = withNamespaces()<WelcomeMessagePopupProps>(
  _WelcomeMessagePopup
)
