import {useEffect, useState} from 'react'
import {backendGetRequest} from '../../../../../common/utils/requests'

export type ManagerOffer = {
  activeInvestmentCount: number;
  currency: string;
  id: number;
  managerId: number;
  name: string;
  settings: {
    minDeposit: number;
    minInitialInvestment: number;
    minWithdrawal: number;
    performanceFees: {
      levels: {
        level: number;
        value: number;
      }[];
      mode: 'Equity' | 'Return'
    }
    tradingInterval: {
      type: 'Days' | 'Weeks' | 'Months' | 'CalendarMonths';
      count: number;
    };
  },
  skygroundClientId: number;
}

const fetchOffers = async (managerAccountId: number) => {
  const response = await backendGetRequest(`/api/v2/dashboard/pamm/managers/${managerAccountId}/offers`, true)
  if (response.ok) {
    return response.json()
  } else {
    throw new Error(`Failed to fetch offers ${response.status} ${response.statusText}`)
  }
}

export const useOffers = (managerAccountId: number) => {
  const [error, setError] = useState<Error | null>(null)
  const [loading, setLoading] = useState(true)
  const [offers, setOffers] = useState<ManagerOffer[]>([])

  useEffect(() => {
    fetchOffers(managerAccountId).then((data) => {
      setLoading(false)
      setOffers(data)
    }, (error) => {
      // eslint-disable-next-line no-console
      console.error('Error fetching offers:', error)
      setLoading(false)
      setError(error)
    })
  }, [managerAccountId])

  return {error, loading, offers}
}
