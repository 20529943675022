import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {setCookie} from '../../../../common/utils'

export const PammCookieSetter = () => {
  const location = useLocation()

  useEffect(() => {
    const setPammCookie = async () => {
      const params = new URLSearchParams(location.search)
      const pammManagerId = params.get('pammManager')
      const pammOfferId = params.get('pammOffer')
      if (pammManagerId && pammOfferId) {
        setCookie('pammOffer', encodeURIComponent(JSON.stringify({pammManagerId, pammOfferId})), 1)
      }
      params.delete('pammManager')
      params.delete('pammOffer')
      location.search = params.toString()
    }
    setPammCookie()
  }, [location])

  return null
}
