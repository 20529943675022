import styled from '@emotion/styled'

export const MfaOptionName = styled.div`
  color: black;
  display: flex;
  font-weight: 400;
  gap: 0.5rem;

  & > svg {
    color: #4CAF50;
  }

  & > h5 {
    color: #000;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    margin: 0;
  }
`
