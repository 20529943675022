import AccountCard from '../../AccountCard'
import Grid from '@material-ui/core/Grid'
import {AccountCardProps} from '../Cards/types'
import {AccountTypeEnumIndices, ICompanyEnum} from '@bdswiss/common-enums'
import {PammTauroMarketsAccountCard} from '../Cards/PammTauroMarketsAccountCard'
import {PammTauroMarketsManagerAccountCard} from '../Cards/PammTauroMarketsManagerAccountCard'
import {IbAccountCard} from '../Cards/IbAccountCard'
import {Account} from '../../../../graphql/types'
import React from 'react'

const specificComponents: Partial<Record<AccountTypeEnumIndices, React.FC<AccountCardProps>>> = {
  [AccountTypeEnumIndices.PAMMTauroMarketsAccount]: PammTauroMarketsAccountCard as React.FC<AccountCardProps>,
  [AccountTypeEnumIndices.PAMMTauroMarketsMauritiusAccount]: PammTauroMarketsAccountCard as React.FC<AccountCardProps>,
  [AccountTypeEnumIndices.PAMMTauroMarketsManagerAccount]: PammTauroMarketsManagerAccountCard,
  [AccountTypeEnumIndices.PAMMTauroMarketsMauritiusManagerAccount]: PammTauroMarketsManagerAccountCard,
  [AccountTypeEnumIndices.IntroducingBrokerTauroMarketsAccount]: IbAccountCard,
}

interface AccountCardFactoryProps {
  account: Partial<Account>
  kycStatus: boolean;
  company: ICompanyEnum;
  onClick: (account: Account) => void;
}

export const AccountCardFactory = ({account, kycStatus, company, onClick}: AccountCardFactoryProps) => {
  const {__typename} = account

  const CardComponent = __typename != null && Boolean(specificComponents[__typename])
    ? specificComponents[__typename]
    : AccountCard

  return (
    <Grid item xs={12} sm={6} key={account.id}>
      <CardComponent
        account={account}
        company={company}
        kycStatus={kycStatus}
        onClick={onClick}
      />
    </Grid>
  )
}
