import {Theme} from '@material-ui/core'

export const styles = (theme: Theme) => ({
  isDemoArchived:{
    borderRadius: '5px',
    padding: '0px 7px',
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']:4
  },
  pending: {
    backgroundColor: '#F1F5F9',
  },
  pendingText: {
    color: '#73738C'
  },
})
