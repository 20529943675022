import {Trans, withNamespaces} from 'react-i18next'
import compose from 'lodash/flowRight'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {withStyles, WithStyles} from '@material-ui/core'

import messages from '../../../../../../../../assets/messages'
import {AlertDialog} from '../../../../../../../Common/Dialog'
import {PopupProps} from '../../../../../../../../types'
import {styles} from './styles'

const email = 'support@tauromarkets.com'
const titleI18nKey = 'pammManagerNotApprovedTitle'

type PammManagerUnapprovedPopupProps = PopupProps & WithStyles

const _PammManagerUnapprovedPopup = ({onClose, open, t, classes}: PammManagerUnapprovedPopupProps) => {
  const title = t(messages[titleI18nKey].i18nKey, messages[titleI18nKey].defaults)

  return (
    <AlertDialog
      open={open}
      onClose={onClose}
      title={title}
      agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.line1} variant="body1"><Trans {...messages.pammManagerNotApprovedLine1} /></Typography>
          <Typography className={classes.line2} variant="body1">
            <Trans
              {...messages.pammManagerNotApprovedLine2}
              components={[
                <a href={`mailto:${email}`}>{email}</a>,
              ]}
              values={{
                email,
              }}
            />
          </Typography>
        </Grid>
      </Grid>
    </AlertDialog>
  )
}

export const PammManagerUnapprovedPopup = compose(withStyles(styles), withNamespaces()<PammManagerUnapprovedPopupProps>)(
  _PammManagerUnapprovedPopup
)
