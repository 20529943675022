import {useParams} from 'react-router-dom'
import {useOffers} from './useOffers'
import Loading from '../../../../Common/Loading'
import {OfferTile} from './components/OfferTile'
import {Container} from './components/Container'
import {NoOffers} from './components/NoOffers'

export const PammManagerOffers = () => {
  const {accountId} = useParams<{ accountId: string }>()
  const {error, loading, offers} = useOffers(Number(accountId))

  if (error) return null
  if (loading) return <Loading />

  if (offers.length < 2) // Because we don't want to display the default offer
    return <NoOffers />
  return (
    <Container>
      {offers
        .filter((offer) => offer.name !== 'Default')
        .map((offer) => (
          <OfferTile key={offer.id} offer={offer} />
        ))}
    </Container>
  )
}
