import {useState} from 'react'
import {CloseButton} from '../../../components/shared/CloseButton'
import {SuccessModalTitle} from '../../../components/shared/SuccessModalTitle'
import messages from '../../../../../../../assets/messages'
import {Trans} from 'react-i18next'
import {FieldLabel} from './components/FieldLabel'
import {PasswordInput} from '../../../../../../PammOffer/components/PasswordForm/components/PasswordInput'
import {Field} from './components/Field'
import {Legend} from './components/Legend'
import {CodeInput} from '../../../../../../Auth/Registration/components/MfaSettings/components/CodeInput'
import Button from '@material-ui/core/Button'
import {CancelLink} from '../../../../../../Auth/Registration/components/MfaSettings/components/CancelLink'
import {Buttons} from './components/Buttons'
import Typography from '@material-ui/core/Typography'

type TotpRemovalFormProps = {
  error: string;
  onCancel: () => void;
  onSubmit: (password: string, code: string) => void
}

export const TotpRemovalForm = ({error, onCancel, onSubmit}: TotpRemovalFormProps) => {
  const [password, setPassword] = useState('')
  const [code, setCode] = useState('')

  const formValid = password.length > 0 && code.length === 6

  const handleSubmit = () => {
    onSubmit(password, code)
  }

  return (
    <>
      <CloseButton onClick={onCancel} />
      <SuccessModalTitle>
        <Trans {...messages.disableAuthenticatorApp}></Trans>
      </SuccessModalTitle>
      <Field>
        <FieldLabel>
          <Trans {...messages.enterYourAccountPassword}></Trans>
        </FieldLabel>
        <PasswordInput error='' value={password} onChange={setPassword} />
      </Field>
      <Field>
        <FieldLabel>
          <Trans {...messages.enterTheCode}></Trans>
        </FieldLabel>
        <Legend>
          <Trans {...messages.forYourSecurity}></Trans>
        </Legend>
        <CodeInput onChange={setCode} value={code} />
      </Field>
      <Buttons>
        {error && (<Typography color="error" variant="h5">
          <Trans {...messages[error]} />
        </Typography>)}
        <Button
          disabled={!formValid}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          size="large"
        >
          <Trans {...messages.removeAuthenticator} />
        </Button>
        <CancelLink onClick={onCancel}>
          <Trans {...messages.nevermindKeepIt} />
        </CancelLink>
      </Buttons>
    </>
  )
}

