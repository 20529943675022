import styled from '@emotion/styled'

export const ModalHeader = styled('h6')`
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  margin: 2rem 0 1rem 0;
  text-align: center;
`
