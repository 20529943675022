import {ReactElement, useCallback, useState, memo, ReactNode, useEffect} from 'react'
import get from 'lodash/get'
import compose from 'lodash/flowRight'
import isEmpty from 'lodash/isEmpty'
import forEach from 'lodash/forEach'
import isArray from 'lodash/isArray'
import uniq from 'lodash/uniq'

import {ICountryEnum} from '@bdswiss/common-enums'
import {withStyles, WithStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import {formatGooglePlacesAddress} from '../../../../../../common/utils'
import {FORM_FIELD_NAMES} from '../constants'
import {styles} from '../styles'
import {getCountrySelectValue} from '../utils'

type FieldName = typeof FORM_FIELD_NAMES[keyof typeof FORM_FIELD_NAMES]

type AddressFieldsProps = {
  title: ReactNode,
  country: string,
  geocoderService: any,
  onChange: (fieldName: string, value: string) => void,
  renderAddressFields
} & WithStyles

function AddressFieldsBase(props: AddressFieldsProps): ReactElement {
  const {
    title,
    classes,
    country,
    geocoderService,
    onChange,
    renderAddressFields
  } = props
  const [countrySelectValue, setCountrySelectValue] = useState<ICountryEnum | null>(getCountrySelectValue(country))

  useEffect(() => {
    setCountrySelectValue(getCountrySelectValue(country))
  }, [country])

  const handleChangeCountry = useCallback(value => {
    const res = getCountrySelectValue(value)
    if (!res) return

    setCountrySelectValue(res)
  }, [])


  const handleTextFieldChange = useCallback(
    (fieldName: FieldName) => (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(fieldName, event.target.value)
    },
    [onChange]
  )

  const handleAddressAutocompleteChange = useCallback(
    (fieldName: FieldName) => (value: string) => onChange(fieldName, value),
    [onChange]
  )

  const handleAddressAutoCompleteSelect = useCallback((address) => {
    if (!address) return

    geocoderService.geocode({address}, result => {
      const addressComponents = get(result[0], 'address_components', {})
      const addressForm: any = !isEmpty(addressComponents) && formatGooglePlacesAddress(addressComponents)

      forEach(addressForm, (field, key) => {
        const fieldValue = isArray(field) ? uniq(field).join(', ') : field
        onChange(key, fieldValue)
      })
    })
  }, [geocoderService, onChange])

  return (
    <div className={classes.container}>
      <Typography className={classes.pageSubtitle}>
        {title}
      </Typography>
      {renderAddressFields({
        countrySelectValue,
        onChangeCountry: handleChangeCountry,
        onTextFieldChange: handleTextFieldChange,
        onAddressAutocompleteChange: handleAddressAutocompleteChange,
        onAddressAutoCompleteSelect: handleAddressAutoCompleteSelect,
      })}
    </div>
  )
}

const AddressFields = memo(compose(withStyles(styles))(AddressFieldsBase))
export {AddressFields}
