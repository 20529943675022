import styled from '@emotion/styled'

export const FeeStructureTitle = styled.div`
  align-items: center;
  color: #818080;
  display: flex;
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: 700;
  gap: 0.5rem;
  margin: 1rem 0;
`
