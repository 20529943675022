import {backendGetRequest} from '../../../../../common/utils/requests'
import {Account} from '../../../../../graphql/types'

export const toKartwheelIBPortal = async (account: Account) => {
  const newTab = window.open('about:blank', '_blank')
  if (!newTab) {
    throw new Error('Can\'t open popup')
  }
  newTab.document.write('<h4>Please wait</h4>')
  try {
    const response = await backendGetRequest(`api/v1/accounts/${account.id}/getKartwheelAutologinUrl`,true,{})
    const responseData = await response.json()
    newTab.location = responseData.data.url
  }
  catch (e) {
    newTab.close()
  }
}
