import React from 'react'
import bugsnag, {BrowserConfig} from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import {config} from './config'

const enabledReleaseStages = ['staging', 'production']

const bugsnagConfig: BrowserConfig = {
  apiKey: config.common.bugsnagApiKey,
  autoDetectErrors: true,
  enabledErrorTypes: {
    unhandledExceptions: true,
    unhandledRejections: true,
  },
  enabledReleaseStages,
  releaseStage: config.common.environment,
}

bugsnag.start({
  ...bugsnagConfig,
  plugins: [new BugsnagPluginReact()],
})

export const BugsnagErrorBoundary = bugsnag.getPlugin('react')?.createErrorBoundary(React)
