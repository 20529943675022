import {ReactElement, memo} from 'react'
import compose from 'lodash/flowRight'
import noop from 'lodash/noop'

import {withStyles, WithStyles} from '@material-ui/core/styles'
import {withNamespaces, Trans, WithNamespaces} from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'

import {FORM_FIELD_NAMES} from '../constants'
import CountriesSelect from '../../../../../Common/CountriesSelect'
import messages from '../../../../../../assets/messages'
import AddressAutocomplete from '../../../../../Common/AddressAutocomplete'
import {styles} from '../styles'
import {AddressFields} from '../AddressFields'

type ResidentialAddressFieldsProps = {
  values: Record<string, string>,
  errors: Record<string, boolean>,
  errorsKeys: Record<string, string>,
  countryList: {key: string, value: string, label: string, callingCode: string}[],
  geocoderService: any,
  onChange: (fieldName: string, value: string) => void,
} & WithNamespaces & WithStyles

function ResidentialAddressFieldsBase(props: ResidentialAddressFieldsProps): ReactElement {
  const {
    countryList,
    errors,
    errorsKeys,
    classes,
    values,
    geocoderService,
    t,
    onChange,
  } = props
  const {line1, country, houseNumber, city, zip, region} = values

  return (
    <AddressFields
      title={<Trans {...messages.residentialAddress} />}
      country={country}
      geocoderService={geocoderService}
      onChange={onChange}
      renderAddressFields={({
        countrySelectValue,
        onAddressAutocompleteChange,
        onAddressAutoCompleteSelect,
        onChangeCountry,
        onTextFieldChange,
      }) => (
        <div className={classes.fieldsContainer}>
          <div className={classes.fieldsColumn}>
            <div className={classes.fieldsRow}>
              <div className={classes.field}>
                {geocoderService ? (
                  <AddressAutocomplete
                    address={line1}
                    onChange={onAddressAutocompleteChange(FORM_FIELD_NAMES.line1)}
                    onSelect={onAddressAutoCompleteSelect}
                    error={errors.line1}
                    country={country}
                  />
                ) : (
                  <TextField
                    required
                    id={FORM_FIELD_NAMES.line1}
                    label={t(messages.streetLabel.i18nKey, messages.streetLabel.defaults)}
                    fullWidth
                    value={line1}
                    onChange={onTextFieldChange(FORM_FIELD_NAMES.line1)}
                  />
                )}
                {!line1 ? (
                  <FormHelperText className={classes.errorMessage}>
                    <Trans {...messages.streetNameRequired} />
                  </FormHelperText>
                ) : null}
                {line1 && errors.line1 && messages[errorsKeys.line1] ? (
                  <FormHelperText className={classes.errorMessage}>
                    <Trans {...messages[errorsKeys.line1]} />
                  </FormHelperText>
                ) : null}
              </div>
              <div className={classes.field}>
                <TextField
                  fullWidth
                  required
                  id={FORM_FIELD_NAMES.houseNumber}
                  name={FORM_FIELD_NAMES.houseNumber}
                  label={t(messages.houseNumberLabel.i18nKey, messages.houseNumberLabel.defaults)}
                  autoComplete={FORM_FIELD_NAMES.houseNumber}
                  error={errors.houseNumber}
                  value={houseNumber}
                  onChange={onTextFieldChange(FORM_FIELD_NAMES.houseNumber)}
                />
                {!houseNumber ? (
                  <FormHelperText className={classes.errorMessage}>
                    <Trans {...messages.houseNumberRequired} />
                  </FormHelperText>
                ) : null}
                {houseNumber && errors.houseNumber && messages[errorsKeys.houseNumber] ? (
                  <FormHelperText className={classes.errorMessage}>
                    <Trans {...messages[errorsKeys.houseNumber]} />
                  </FormHelperText>
                ) : null}
              </div>
              <div className={classes.field}>
                <TextField
                  required
                  id={FORM_FIELD_NAMES.city}
                  name={FORM_FIELD_NAMES.city}
                  label={t(messages.cityLabel.i18nKey, messages.cityLabel.defaults)}
                  fullWidth
                  autoComplete={FORM_FIELD_NAMES.city}
                  value={city}
                  error={errors.city}
                  onChange={onTextFieldChange(FORM_FIELD_NAMES.city)}
                />
                {city && errors.city && messages[errorsKeys.city] ? (
                  <FormHelperText className={classes.errorMessage}>
                    <Trans {...messages[errorsKeys.city]} />
                  </FormHelperText>
                ) : null}
              </div>
            </div>
            <div className={classes.fieldsRow}>
              <div className={classes.field}>
                <TextField
                  required
                  id={FORM_FIELD_NAMES.zip}
                  name={FORM_FIELD_NAMES.zip}
                  label={t(messages.zipLabel.i18nKey, messages.zipLabel.defaults)}
                  fullWidth
                  autoComplete={FORM_FIELD_NAMES.zip}
                  value={zip}
                  error={errors.zip}
                  onChange={onTextFieldChange(FORM_FIELD_NAMES.zip)}
                />
                {zip && errors.zip && messages[errorsKeys.zip] ? (
                  <FormHelperText className={classes.errorMessage}>
                    <Trans {...messages[errorsKeys.zip]} />
                  </FormHelperText>
                ) : null}
              </div>
              <div className={classes.field}>
                <TextField
                  required
                  id={FORM_FIELD_NAMES.region}
                  name={FORM_FIELD_NAMES.region}
                  label={t(messages.regionLabel.i18nKey, messages.regionLabel.defaults)}
                  fullWidth
                  value={region}
                  error={errors.region}
                  onChange={onTextFieldChange(FORM_FIELD_NAMES.region)}
                />
                {region && errors.region && messages[errorsKeys.region] ? (
                  <FormHelperText className={classes.errorMessage}>
                    <Trans {...messages[errorsKeys.region]} />
                  </FormHelperText>
                ) : null}
              </div>
              <div className={classes.field}>
                <CountriesSelect
                  countryList={countryList}
                  handleChangeField={onChangeCountry}
                  handleChange={onChange}
                  setStateOuter={noop}
                  errors={errors}
                  value={countrySelectValue?.label}
                  name={FORM_FIELD_NAMES.country}
                  label={t(messages.countryLabel.i18nKey, messages.countryLabel.defaults)}
                  returnKey
                  showRequired
                />
              </div>
            </div>
          </div>
        </div>
      )}
    />
  )
}

const ResidentialAddressFields = memo(compose(withStyles(styles), withNamespaces())(ResidentialAddressFieldsBase))
export {ResidentialAddressFields}
