import {withNamespaces, WithNamespaces} from 'react-i18next'
import messages from '../../../../assets/messages'
import PageTitle from '../../../Common/PageTitle'

const _PammOfferTitle = ({children, t}: React.PropsWithChildren<WithNamespaces>) => (
  <PageTitle
    title={t(messages.offerDetails.i18nKey, messages.offerDetails.default)}
  >
    {children}
  </PageTitle>
)

export const PammOfferTitle = withNamespaces()(_PammOfferTitle)
