import styled from '@emotion/styled'

export const Presentation = styled('div')`
  background-color: white;
  border-color: rgba(244, 67, 54, 1);
  border-radius: 2px;
  border-style: solid;
  border-width: 2px;
  display: flex;
  height: 18px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 18px;

  input:checked + &:before {
    background-color: rgba(244, 67, 54, 1);
    color: white;
    content: '✓';
    line-height: 18px;
    text-align: center;
    width: 18px;
  }
`
