import {useState} from 'react'
import Password from '../../../../../Common/Password'
import {withStyles} from '@material-ui/core'
import {ClassNameMap} from '@material-ui/core/styles/withStyles'

type PasswordInputProps = {
  classes?: Partial<ClassNameMap<keyof ReturnType<typeof styles>>>;
  error: string;
  onChange: (newValue: string) => void;
  value: string;
}

const styles = theme => ({
  formControl:{
    display: 'block',
    margin: '20px 0px'
  },
  error:{
    color: theme.palette.error.main,
  },
  errorMessage:{
    color: theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
})

export const _PasswordInput = ({classes, error, onChange, value}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <Password
      classes={classes ?? {}}
      showPassword={showPassword}
      onChange={e => onChange(e.target.value)}
      error={error.length > 0}
      onClickShow={() => setShowPassword(!showPassword)}
      fullWidth
      errorText={error}
      value={value}
    />
  )
}

export const PasswordInput = withStyles(styles)(_PasswordInput)
