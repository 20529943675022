import styled from '@emotion/styled'
import {ReactComponent as XIcon} from './assets/close.svg'

type CloseButtonProps = {
  onClick: () => void
}

const Container = styled.div`
  cursor: pointer;
  height: 2rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 2rem;
`
export const CloseButton = ({onClick}: CloseButtonProps) => (
  <Container onClick={onClick}>
    <XIcon />
  </Container>
)
