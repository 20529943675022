import styled from '@emotion/styled'

export const MfaDeleteConfirmationModalContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 35px;
  left: 50%;
  padding: 30px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 575px;
  z-index: 2;
`
