import {getCookie} from '../../../../common/utils'

export const PammRedirect = () => {
  const pammCookie = getCookie('pammOffer')

  if (pammCookie && !window.location.pathname.startsWith('/pamm-offer')) {
    const pammOffer = JSON.parse(decodeURIComponent(pammCookie))
    window.location.href = `/pamm-offer/${pammOffer.pammManagerId}/${pammOffer.pammOfferId}`
  }
  return null
}
