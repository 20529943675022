import styled from '@emotion/styled'

export const Amount = styled.div`
  display: inline-block;
  font-family: Roboto;
  font-size: 1.5rem;
  &::first-letter {
    font-size: 1rem;
    padding-right: 0.25rem;
  };
`

export const GreenAmount = styled(Amount)`
  color: #1DCD9A;
`
