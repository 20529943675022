import {Theme} from '@material-ui/core'

export const styles = (theme: Theme) => ({
  selectClass:  {
    [theme.breakpoints.down('xs')]: {
      paddingTop: 17,
    },
  },
  overflowVisible: {
    overflow: 'visible'
  }
})
