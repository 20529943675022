import {isApprovedAccount} from '../../../../../common/utils/accounts'
import {Account} from '../../../../../graphql/types'
import {Action, ActionType} from './actions'

export type State = {
  account: Account;
  showPammManagerUnapprovedPopup: boolean;
}

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.Click: {
      if (!isApprovedAccount(state.account)) {
        return {...state, showPammManagerUnapprovedPopup: true}
      } else return state
    }
    case ActionType.CloseModal: {
      return {...state, showPammManagerUnapprovedPopup: false}
    }
    default: {
      return state
    }
  }
}
