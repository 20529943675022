import styled from '@emotion/styled'

export const Container = styled.div`
  color: #D0D5DD;
  display: flex;
  gap: 6px;
  justify-content: center;
  font-size: 60px;
  line-height: 72px;
`
