import {Trans, withNamespaces, WithNamespaces} from 'react-i18next'
import messages from '../../../../../assets/messages'
import PageTitle from '../../../../Common/PageTitle'
import {useParams} from 'react-router-dom'
import {toPammPortal} from './toPammPortal'
import Button from '@material-ui/core/Button'

const _PammManagerSettingsTitle = ({children, t}: React.PropsWithChildren<WithNamespaces>) => {
  const params = useParams<{accountId?: string}>()
  const handleDashboardClick = () => {
    if (params.accountId) toPammPortal(params.accountId)
  }

  return (
    <PageTitle
      title={t(messages.availableOffers.i18nKey, messages.availableOffers.default)}
      rightAction={
        <Button
          color="primary"
          onClick={handleDashboardClick}
          size="large"
          variant={'contained'}
        >
          <Trans {...messages.pammInvestorDashboard} />
        </Button>
      }
    >
      {children}
    </PageTitle>
  )
}

export const PammManagerSettingsTitle = withNamespaces()(_PammManagerSettingsTitle)
