const CORRESPONDENCE_ADDRESS_FIELD_PREFIX = 'correspondenceAddress'

const ADDRESS_FIELDS = Object.freeze({
  line1: 'line1',
  houseNumber: 'houseNumber',
  city: 'city',
  region: 'region',
  country: 'country',
  zip: 'zip',
})

const CORRESPONDENCE_ADDRESS_FIELDS_NAMES = Object.freeze({
  line1: `${CORRESPONDENCE_ADDRESS_FIELD_PREFIX}Line1`,
  houseNumber: `${CORRESPONDENCE_ADDRESS_FIELD_PREFIX}HouseNumber`,
  city: `${CORRESPONDENCE_ADDRESS_FIELD_PREFIX}City`,
  region: `${CORRESPONDENCE_ADDRESS_FIELD_PREFIX}Region`,
  country: `${CORRESPONDENCE_ADDRESS_FIELD_PREFIX}Country`,
  zip: `${CORRESPONDENCE_ADDRESS_FIELD_PREFIX}Zip`,
})

const FORM_FIELD_NAMES = Object.freeze({
  firstName: 'firstName',
  lastName: 'lastName',
  birthday: 'birthday',
  email: 'email',
  phone: 'phone',
  passportNumber: 'passportNumber',
  nationality: 'nationality',
  previousNames: 'previousNames',
  secondaryEmail1: 'secondaryEmail1',
  secondaryPhone1: 'secondaryPhone1',
  ...ADDRESS_FIELDS
})


export {
  ADDRESS_FIELDS,
  FORM_FIELD_NAMES,
  CORRESPONDENCE_ADDRESS_FIELD_PREFIX,
  CORRESPONDENCE_ADDRESS_FIELDS_NAMES
}
