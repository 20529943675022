import {getAccountLabel} from '../../../common/utils/accounts'
import {Account, Client} from '../../../graphql/types'

export const isAccount = (account: Account | Client): account is Account => 'remoteId' in account && 'balance' in account

export const isClient = (account: Account | Client): account is Client => 'firstName' in account

export const getLabel = (suggestion: Account | Client, locale: string): string => {
  if (isAccount(suggestion)) return `${getAccountLabel(suggestion, locale)} ${suggestion.remoteId} ${suggestion.balance}`
  if (isClient(suggestion)) return `${suggestion.firstName} ${suggestion.lastName} ${suggestion.id}`
  return 'Unknown'
}
