import {useMutation} from '@apollo/react-hooks'
import gql from 'graphql-tag'

const ACKNOWLEDGE = gql`
  mutation ($id: Int!) {
    acknowledgeClientMessage(id: $id)
  }
`

export const useAcknowledgeSeen = () => {
  const [mutation] = useMutation(ACKNOWLEDGE, {
    refetchQueries: ['Viewer'],
  })
  return (id: number) => mutation({
    variables: {
      id
    }
  })
}
