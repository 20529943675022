import styled from '@emotion/styled'

export const Modal = styled('div')`
  background-color: white;
  border-radius: 12px;
  min-height: 412px;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  z-index: 2;
`
