import styled from '@emotion/styled'

export const SuccessModalContent = styled.div`
  color: rgba(77, 73, 73, 0.87);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
`
