import {useLocation} from 'react-router-dom'
import {DifferentPartnerWarningPopup} from '../../../App/PopupFactory/popups/ShowDifferentPartnerWarningPopup'

export const ShowDifferentPartnerWarningModal = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const showDifferentPartnerWarning = params.get('showDifferentPartnerWarning')
  if (!showDifferentPartnerWarning) {
    return null
  }

  return (
    <DifferentPartnerWarningPopup />
  )
}
