import {ReactElement} from 'react'
import {withNamespaces, WithNamespaces, Trans} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import {AlertDialog} from '../../../../../Common/Dialog'
import messages from '../../../../../../assets/messages'
import {useStyles} from './styles'

type UnableCopyTradeAlertDialogProps = {
  strategyAccountSubtype?: string;
  isOpen: string;
  onClose: () => void;
} & WithNamespaces

function UnableCopyTradeAlertDialogBase(props: UnableCopyTradeAlertDialogProps): ReactElement {
  const {strategyAccountSubtype, isOpen, onClose} = props
  const {infoRowClassName, okButtonClassName} = useStyles()

  return (
    <AlertDialog
      open={isOpen}
      onClose={onClose}
      title={<Trans {...messages.unableToCopyTrade} />}
    >
      <Grid container direction="column" justifyContent='space-between'>
        <Grid item className={infoRowClassName}>
          <Trans {...messages.unableCopyTradeMessage} values={{accountType: strategyAccountSubtype}} />
        </Grid>
        <Grid item className={infoRowClassName}>
          <Trans {...messages.unableCopyTradeAdvice} values={{accountType: strategyAccountSubtype}} />
        </Grid>
        <Grid item className={infoRowClassName}>
          <Button
            className={okButtonClassName}
            variant='contained'
            color="primary"
            onClick={onClose}
          >
            <Trans {...messages.okUnderstoodLabel}/>
          </Button>
        </Grid>
      </Grid>
    </AlertDialog>
  )
}

const UnableCopyTradeAlertDialog = withNamespaces()(UnableCopyTradeAlertDialogBase)
export {UnableCopyTradeAlertDialog}
