import styled from '@emotion/styled'

export const Legend = styled.div`
  color: #908F8F;
  font-family: Roboto;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 153%;
  margin-bottom: 1.5rem;
`
