import Typography from '@material-ui/core/Typography'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {withNamespaces, WithNamespaces} from 'react-i18next'
import {WithStyles, withStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import Button from '@material-ui/core/Button'
import {flowRight as compose} from 'lodash'
import CustomTooltip from './CustomTooltip'
import messages from '../../assets/messages'

type WithCopyToClipboardProps = {
  text: string;
} & WithNamespaces & WithStyles

const styles = (theme) => ({
  copyButton: {
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  loginDetails: {
    fontWeight: 300,
    lineHeight: '2.5rem',
  },
  tooltipText: {
    color: '#ffffff',
  },
})

export const _WithCopyToClipboard = ({classes, t, text, children}: React.PropsWithChildren<WithCopyToClipboardProps>) => (
  <CustomTooltip
    title={
      <Typography
        variant="body1"
        className={classes.tooltipText}
      >
        {t(
          messages.clickToCopy.i18nKey,
          messages.clickToCopy.defaults
        )}
      </Typography>
    }
    placement={'right'}
  >
    <CopyToClipboard text={text}>
      <Button
        className={classNames(
          classes.copyButton,
          classes.loginDetails
        )}
      >
        {children}
      </Button>
    </CopyToClipboard>
  </CustomTooltip>

)

export const WithCopyToClipboard = compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
)(_WithCopyToClipboard)
