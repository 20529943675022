import React from 'react'
import {get, isEmpty, isFunction} from 'lodash'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import HeaderDialog from './HeaderDialog'
import SidebarDialog from './SidebarDialog'
import {withStyles} from '@material-ui/core/styles'
import {isMobile} from '../../../common/utils/browser'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import LoadingButton from '../LoadingButton'
import {FormHelperText} from '@material-ui/core'
import classNames from 'classnames'

const styles = (theme) => ({
  mainBody:{
    maxWidth: theme.mainBodyWidth,
    margin:'2rem auto 4rem'
  },
  sidebar: {
    float: theme.direction === 'rtl' ? 'right' as const : 'left' as const,
    maxWidth: `${theme.sidebarWidth}`,
    height: '100%',
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: 0,
  },
  main: {
    marginTop: 30
  },
  closeButton: {
    position: 'absolute' as const,
    [theme.direction === 'rtl' ? 'left' : 'right']: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
  },
  dialogTitle: {
    '&&:first-child': {
      marginRight: '35px',
    }
  },
  alertBody: {
    minWidth: 270,
  },
  errorMessage:{
    color:  theme.palette.error.main,
    textAlign: theme.direction === 'rtl' ? 'left'as const : 'right'as const
  },
  successMessage:{
    color:  theme.palette.green.color,
    textAlign: 'right' as const
  },
})

const AlertDialogComponent = (props: any) =>  (
  <div>
    <Dialog
      open={props.open}
      onClose={props.onClose}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={props.maxWidth}
    >
      {props.title && <DialogTitle id="alert-dialog-title" className={props.classes.dialogTitle} disableTypography={props.disabledTypography}>
        {props.title}
        <IconButton aria-label="Close" className={props.classes.closeButton} onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>}
      <DialogContent classes={{root: classNames(props.classes.alertBody, props.alertBodyClass)}}>
        {props.children}
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent={props.justify ? props.justify : 'flex-end' }
          alignItems={props.alignItems ? props.alignItems : 'flex-end' }
          spacing={1}
        >
          {props.disagreeText && <Grid item>
            <Button fullWidth={isMobile() ? true: false} color="secondary" size="medium" variant="contained"
              onClick={props.onDisagree ? props.onDisagree : props.onClose}>
              {props.disagreeText}
            </Button></Grid>}
          {props.agreeText && <Grid item>
            <LoadingButton
              id='loadingButton'
              onClick={props.onAgree ? props.onAgree : props.onClose}
              autoFocus
              fullWidth={isMobile()}
              color="primary"
              variant="contained"
              size="medium"
              disabled={props.buttonLoading || !isEmpty(props.errorText) || !isEmpty(props.successText) || props.buttonDisabled || false}
              status={props.buttonStatus || ''}
              noMargin={true}
              hideProgressBar={(!props.buttonLoading && !isEmpty(props.buttonStatus)) || props.hideProgressBar}
            >
              {props.agreeText}
            </LoadingButton> </Grid>}
          {props.errorText && <Grid item xs={12}>
            <FormHelperText className={props.classes.errorMessage}>{props.errorText}</FormHelperText></Grid>}
          {props.successText && <Grid item xs={12}>
            <FormHelperText className={props.classes.successMessage}>{props.successText}</FormHelperText></Grid>}
        </Grid>

      </DialogActions>
    </Dialog>
  </div>
)
export const AlertDialog = withStyles(styles)(AlertDialogComponent)

export const CustomDialog =  withStyles(styles)((props: any) => (
  <div>
    <Dialog
      open={props.open}
      onClose={props.onClose}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={get(props, 'customClasses.dialog', '')}
      maxWidth={props.maxWidth}
      fullScreen={props.fullScreen}
      disableEscapeKeyDown={props.disableEscapeKeyDown}
      classes={get(props, 'customClasses.classes', {})}
    >
      {isFunction(props.onCloseIcon) && <CloseIcon className={get(props, 'customClasses.closeIcon')} onClick={() => props.onCloseIcon()}/>}
      {props.title && <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>}
      <DialogContent className={get(props, 'customClasses.dialogContent',props.classes.dialogContent)}>
        {props.children}
      </DialogContent>
    </Dialog>
  </div>
))

const FullScreenDialogComponent = (props: any) =>  {
  const {classes} = props
  if (isMobile() && props.desktopOnly) {
    return props.component || props.children
  }

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.onClose}
    >
      <Grid container className={classes.mainBody}>
        <HeaderDialog linkTo={'/accounts'}/>
        <Grid item className={classes.sidebar} xs={3}>
          <SidebarDialog  onClick={props.onClose} />
        </Grid>
        <Grid item xs={9}>
          <main className={classes.main}>{props.component || props.children}</main>
        </Grid>
      </Grid>
    </Dialog>)
}

export const FullScreenDialog = withStyles(styles)(FullScreenDialogComponent)
