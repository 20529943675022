import React from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import Notifications from '../Notifications'
import Settings from '../Settings'
import Payments from '../Payments'
import LanguageSelection from '../LanguageSelection'
import Support from '../Support'
import LydianSupport from '../RedesignedWLComponents/Lydian/Support'
import LydianSidebar from '../RedesignedWLComponents/Lydian/Sidebar'
import AccountRoutes from '../Accounts/routes'
import WebtraderRoutes from '../Webtraders/routes'
import EmailVerification from '../EmailVerification'
import ForexTrader from '../ForexTrader'
import Sidebar from './sidebar/Sidebar'
import BlackAccount from '../ContentPages/LP_BlackAccount'
import CallBackForm from '../ContentPages/CallbackForm'
import SMSVerification from '../SMSVerification'
import TermsDialog from '../Common/TermsDialog'
import Vps from '../Vps'
import EmailVerificationNeeded from '../EmailVerification/EmailVerificationNeeded'
import CompareAccountTypes from '../ContentPages/CompareAccountTypes'
import TradingCompetitions from '../Accounts/TradingCompetitions'
import ReferAndEarn from '../ReferAndEarn'
import StatusParamRoute from '../../components/Payments/Deposits/StatusParamRoute'
import OpenUpParamRoute from '../Payments/Deposits/OpenUpParamRoute'
import LydianNotifications from '../RedesignedWLComponents/Lydian/LydianNotifications'
import PWClickParamRoute from '../Payments/Deposits/PWClickParamRoute'
import JMFinancialKWRoute from '../Payments/Deposits/JMFinancialKWParamRoute'
import {config} from '../../config'
// import eBookDialog from '../Common/eBookDialog'
// import Tools from '../Tools'
// import Autochartist from '../Tools/Autochartist'
// import TradeCompanion from '../Tools/TradeCompanion'
// import TradingCentral from '../Tools/TradingCentral'
import {SolicitationAcceptance} from '../SolicitationAcceptance'
import {PammOffer} from '../PammOffer'

const {key, showTab} = config
let NotificationsWL
let SupportWL
let SidebarWL
let showTransactions = true
let showSettings = true
let showSolicitationAcceptance = false

switch (key) {
  case 'lydian':
    NotificationsWL = LydianNotifications
    SupportWL = LydianSupport
    SidebarWL = LydianSidebar
    showTransactions = showTab?.transactions ?? false
    showSettings = false
    break
  case 'equityflow':
    showSolicitationAcceptance = true
    break
  default:
    NotificationsWL =  Notifications
    SupportWL = Support
    SidebarWL = Sidebar
}

const AppRoutes = (props) => (
  <Switch>
    <Route path={'/pamm-offer/:pammManagerId/:pammOfferId'} component={PammOffer}/>
    {showSolicitationAcceptance && <Route path={'/solicitationAcceptance/:lang'} component={SolicitationAcceptance}/>}
    <Route path={'/clientNotice'} component={TermsDialog} />
    {showTransactions && <Route path={'/transactions'} component={Payments}/>}
    <Route path={'/notifications'} component={NotificationsWL} />
    {showSettings && <Redirect exact from={'/settings'} to={'/settings/profile'}/>}
    {showSettings && <Route path={'/settings'} component={Settings}/>}
    <Route path={'/support'} component={SupportWL} />
    <Route path={'/change-language'} component={LanguageSelection} />
    <Route path={'/trade'} component={WebtraderRoutes} />
    <Route path={'/accounts'} component={AccountRoutes} />
    <Route path={'/forexTrader'}  component={ForexTrader}/>
    <Route path={'/forextrader'}  component={ForexTrader}/>
    <Route path={'/confirm-email/:confirmEmailToken'} component={EmailVerification} />
    <Route path={'/more'} component={SidebarWL} />
    <Route path={'/vip-account'} component={BlackAccount} />
    <Route path={'/callback'} component={CallBackForm} />
    <Route path={'/email-verification-needed'} component={EmailVerificationNeeded} />
    <Route path={'/smsverification'} component={SMSVerification} />
    <Route path={'/vps'} component={Vps} />
    <Route path={'/compare-accounts'} component={CompareAccountTypes} />
    <Route path={'/trading-competitions'} component={TradingCompetitions} />
    <Route path={'/referAndEarn'} component={ReferAndEarn} />
    <Route path={'/payments/:accountId/deposit/result/pending/azulpay'} component={StatusParamRoute} />
    <Route path={'/payments/:accountId/deposit/result/pending/openup'} component={OpenUpParamRoute} />
    <Route path={'/payments/:accountId/deposit/result/pending/pwclick'} component={PWClickParamRoute} />
    <Route path={'/payments/:accountId/deposit/result/pending/jmfinancialkw'} component={JMFinancialKWRoute} />
    {/* <Route path={'/tools/autochartist'} component={Autochartist} />
    <Route path={'/tools/tradeCompanion'} component={TradeCompanion} />
    <Route path={'/tools/ebook'} component={eBookDialog} to={{pathname: '/ebook'} }/>
    <Route path={'/tools/tradingcentral'} component={TradingCentral} />
    <Route path={'/tools'} component={Tools} /> */}
    {showSettings && <Redirect exact from={'/profile'} to={'/settings/profile/personal-profile/details'}/>}
    <Redirect exact from={'/documents'} to={'settings/profile/documents'} />
    <Redirect exact from={'/appropriateness-test'} to={'settings/profile/appropriateness-test'} />
    {showTransactions && <Redirect exact from={'/withdraw'} to={'/transactions/withdraw'} />}
    {showTransactions && <Redirect exact from={'/deposit'} to={'/transactions/deposit'} />}
    {showSettings && <Redirect exact from={'/change-password'} to={'/settings/change-password'}/>}
    <Redirect exact from={'/contact'} to={'/support'} />
    {showSettings && <Redirect exact from={'/gdpr'} to={'/settings/privacy'}/>}
    <Redirect exact from={'/forex/:id/new'} to={'/trade/forex/:id'} />
    <Redirect exact from={'/dashboard'} to={'/accounts'} />
    <Redirect exact from={'/dashboard/:id'} to={'/accounts/:id'} />
    {showTransactions && <Redirect exact from={'/dashboard/:id/deposit/'} to={'/transactions/:id/deposit'}/>}
    {showTransactions && <Redirect exact from={'/dashboard/:id/withdrawal/'} to={'/transactions/:id/withdraw'}/>}
    {showTransactions && <Redirect exact from={'/dashboard/:id/transferfunds/'} to={'/transactions/transfer'}/>}
    {showSettings && <Redirect exact from={'/spoa'} to={'/settings/profile/spoa-authorization'}/>}
    {showTransactions && <Redirect exact from={'/payments/:accountId/deposit/result/:resultstatus'}
      to={'/transactions/:accountId/deposit/result/:resultstatus'}/>}
    {showTransactions && <Redirect exact from={'/payments/:accountId/:action/deposit/:depositId'}
      to={'/transactions/:accountId/:action/deposit/:depositId'}/>}
    {showTransactions && <Redirect exact from={'/payments/:accountId/deposit'} to={'/transactions/:accountId/deposit'}/>}
    {showTransactions && <Redirect exact from={'/payments/:accountId/transfer/transferFunds'}
      to={'/transactions/:accountId/transfer/transferFunds'}/>}
    {showTransactions && <Redirect exact from={'/payments/transfer/transferFunds'} to={'/transactions/transfer/transferFunds'}/>}
    {showTransactions && <Redirect exact from={'/payments/transfer/transferSend'} to={'/transactions/transfer/transferSend'}/>}
    {showTransactions && <Redirect exact from={'/payments/transfer/transferSpoa'} to={'/transactions/transfer/transferSpoa'}/>}
    {showTransactions && <Redirect exact from={'/payments/transfer/transferIb'} to={'/transactions/transfer/transferIb'}/>}
    {showTransactions && <Redirect exact from={'/payments/:accountId/transfer/transferSend'}
      to={'/transactions/:accountId/transfer/transferSend'}/>}
    {showTransactions && <Redirect exact from={'/payments/:accountId/transfer/transferSpoa'}
      to={'/transactions/:accountId/transfer/transferSpoa'}/>}
    {showTransactions && <Redirect exact from={'/payments/:accountId/transfer/transferIb'}
      to={'/transactions/:accountId/transfer/transferIb'}/>}
    {showTransactions && <Redirect exact from={'/payments/:accountId/transfer'} to={'/transactions/transfer'}/>}
    {showTransactions && <Redirect exact from={'/payments/transfer'} to={'/transactions/transfer'}/>}
    {showTransactions && <Redirect exact from={'/payments/withdraw'} to={'/transactions/withdraw'}/>}
    {showTransactions && <Redirect exact from={'/payments/history'} to={'/transactions/history'}/>}
    {showTransactions && <Redirect exact from={'/payments'} to={'/transactions'}/>}
    <Redirect exact from={'/forex/:id/custom'} to={'/'} />
    <Redirect exact from={'/black-account'} to={'/vip-account'} />
    <Route exact path={'/'} component={AccountRoutes} />
  </Switch>
)

export default AppRoutes
