import React, {Component} from 'react'
import {map, get, isEmpty, some, omitBy, findKey, size,reject, filter, includes, debounce, flowRight as compose} from 'lodash'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import {AlertDialog} from '../../../Common/Dialog/index'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import {PROFILE_SETTINGS_QUERY, CLIENT_DATA_QUERY} from '../../../../graphql/queries'
import {UPDATE_ECONOMIC_PROFILE_MUTATION} from '../../../../graphql/mutations'
import {appropTestIsUsReportable, appropTestPoliticallyExposed, economicProfileQuestions, appropTestPoliticallyExposedReason,
  appropTestTinReason, countries} from '@bdswiss/common-enums'
import withWidth from '@material-ui/core/withWidth'
import messages from '../../../../assets/messages'
import NotificationBar from '../../../Common/NotificationBar'
import AppContext from '../../../Common/contexts/AppContext'
import {Link} from 'react-router-dom'
import {scrollElementIntoView} from '../../../../common/utils'
import TextField from '@material-ui/core/TextField'
import LoadingButton from '../../../Common/LoadingButton'
import FormHelperText from '@material-ui/core/FormHelperText'
import {isMobile} from '../../../../common/utils/browser'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {validateLength} from '../../../../common/utils/validations'
import {config} from '../../../../config'
import {InnerAppContext} from '../../../../common/types'

const gridScroll = 'scroll-grid'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    fontSize: '0.9rem'
  },
  errorMessage: {
    color: theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  textLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  firstItemHide: {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    formControl: {
      fontSize: '0.8rem'
    }
  },
  button:{
    marginTop: 0
  },
  usButton: {
    padding: '8px 0',
    '&:hover':{
      backgroundColor: 'transparent',
    },
    '&:active':{
      backgroundColor: 'transparent',
    }
  },
  subtitle: {
    paddingBottom: 20
  },
  error: {
    color: theme.palette.error.main,
  },
  textRight: {
    textAlign: 'right' as const
  },
})

export class EconomicProfile extends Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext

  static propTypes = {
    classes: PropTypes.object.isRequired,
  }
  constructor(props) {
    super(props)

    this.state = {
      form: props.client, errors: {}, politicallyExposed: false, usCitizen: false, taxJurisdictionCountry: false, loading: false,
      status: '', submitMessageError: '', countryReadOnly: false, formChanged: {}, usCitizenNotication: '', checked: [],
      showMessage: false
    }
  }

  componentDidMount() {
    const {clientData} = this.props
    const {euRegulation: {showEpTestMessage}} = config
    this.setState({showMessage: !!showEpTestMessage && get(clientData, 'fromCompany') && get(clientData, 'verificationActions.forcedVerification') === 'economicProfile'})
  }

  componentDidUpdate(prevProps) {
    if (this.props.client !== prevProps.client) {
      this.setState({form: this.props.client})
    }
  }

  handleChange(name, value, alert?) {
    const {form} = this.state
    const tinDisabledCitizen = (name === economicProfileQuestions.usCitizen.value) && (value === appropTestIsUsReportable.yes.value)
    const tinDisabledCountry = (name === economicProfileQuestions.taxJurisdictionCountry.value) && (value === countries.us.key)
    const resetUsCitizen = (get(form, 'usCitizen') === appropTestIsUsReportable.yes.value) && (name === economicProfileQuestions.taxJurisdictionCountry.value) && (value !== countries.us.key)
    const resetCountry = (name === economicProfileQuestions.usCitizen.value) && (value === appropTestIsUsReportable.no.value)
    const pepRemoveReason = get(form, 'politicallyExposedReason') && (name === economicProfileQuestions.politicallyExposed.value) && value === appropTestIsUsReportable.no.value

    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value,
        tinReason: tinDisabledCitizen ||  tinDisabledCountry ? appropTestTinReason.iHaveTin.key : (name === economicProfileQuestions.tinReason.value) ? value : form.tinReason,
        usCitizen: tinDisabledCountry ? appropTestIsUsReportable.yes.value : (name === economicProfileQuestions.usCitizen.value) ? value : (resetUsCitizen) ? '' : form.usCitizen,
        taxJurisdictionCountry: tinDisabledCitizen ? countries.us.key : (name === economicProfileQuestions.taxJurisdictionCountry.value) ? value : (resetCountry) ? '' : form.taxJurisdictionCountry,
        politicallyExposedReason: (pepRemoveReason) ?  '' : (name === 'politicallyExposedReason') ? value : form.politicallyExposedReason
      },
      errors: {
        ...state.errors,
        [name]: !value,
      },
      formChanged:{
        ...state.formChanged,
        [name]: (value !== this.props.client[name]) ? true : false,
      },
      status: '',
      pepCheckboxError: false
    }))
    if (alert && alert(value))
      this.setState({[name]: true})
  }

  onContinue() {
    const {form: {politicallyExposedReason}} = this.state
    if (!politicallyExposedReason)
      this.setState({pepCheckboxError: true})
    else
      this.setState(state => ({
        form: {
          ...state.form,
          politicallyExposed: (politicallyExposedReason === appropTestPoliticallyExposedReason.none.value) ? appropTestPoliticallyExposed.no.value : appropTestPoliticallyExposed.yes.value,
        },
        politicallyExposed: false,
        pepCheckboxError: false
      }))
  }


  scrollUp(errors?: any ) {
    this.setState(() => scrollElementIntoView(isEmpty(errors) ? gridScroll : `${findKey(errors)}-simple`, 250))
  }

  handleSubmit() {
    const {form} = this.state
    const {client, t} = this.props
    const errors : any = {}
    const {companyObject} = this.context

    map(economicProfileQuestions, (q) => {
      const checkCompanies = filter(q.company, (o) => o.value === companyObject.value)
      const usCountry = (q.value === economicProfileQuestions.tin.value && (get(form, 'taxJurisdictionCountry') === countries.us.key))
      const usCitizen = (q.value === economicProfileQuestions.usCitizen.value && (get(form, 'usCitizen') === appropTestIsUsReportable.yes.value))
      const usRelevant = [economicProfileQuestions.taxJurisdictionCountry.value, economicProfileQuestions.tinReason.value]
      const tinCheck = (usCountry || usCitizen) && q.value === economicProfileQuestions.tin.key
      if (q.relevant({answers: form, company: companyObject}) && !q.disable && !isEmpty(checkCompanies)) {
        if (!tinCheck) {
          errors[q.value] = isEmpty(form[q.value])
        } else {
          const validation = !(/^9/i.test(form[q.value])) || !validateLength(form[q.value], null, null, 9)
          errors[q.value] = validation
          errors.tinUsError =  validation
        }
      } else {
        errors[q.value] = false
        if (!(get(form, 'taxJurisdictionCountry') === countries.us.key && includes(usRelevant, q.value)))
          form[q.value] = ''
      }
    })

    if (some(errors)) {
      this.setState({errors}, () => {this.scrollUp(errors)})
      return
    }

    const variables = omitBy(form, (value, key) => ((isEmpty(value) && isEmpty( client[key])) || client[key] === value))

    this.setState({status: '', loading: true})
    this.props.updateDetails({variables}).then((res) => {
      const usCitizen = get(res, 'data.updateOwnDetails.answers.globalQuestionnaire.usCitizen') === appropTestIsUsReportable.yes.value
      this.props.removeForced()
      if (usCitizen) {
        this.props.profileSettings.refetch()
          .then((res) => this.setState({loading: false, formChanged: false, submitMessageError: '', usCitizenNotication: 'hasOpenPositions'}))
          .catch((err) => this.setState({loading: false, formChanged: false, submitMessageError: '', usCitizenNotication: 'noOpenPositions'},
            //@ts-ignore
            debounce(() => {
              this.context.logout()}, 20000)()))
      } else {
        this.setState({loading: false, formChanged: false, status: 'success', submitMessageError: '', usCitizenNotication: ''}, ()=>{this.scrollUp()})
      }
    })
      .catch((err) => {
        if (err.networkError) {
          this.setState({
            loading: false, status: 'failure', usCitizenNotication: '',
            submitMessageError: t(messages.networkError.i18nKey, messages.networkError.defaults),
          })
        } else {
          this.setState({
            loading: false, status: 'failure', usCitizenNotication: '',
            submitMessageError: get(err, 'graphQLErrors[0].message') || err.message,
          })
        }
      })
  }

  renderQuestion(q, answerKey, locale) {
    const {classes, client} = this.props
    const {form, countryReadOnly} = this.state
    const {companyObject} = this.context
    const checkCompanies = filter(q.company, (o) => o.value === companyObject.value)

    return <React.Fragment key={q.value}>
      {!q.disable && q.dropdown && q.relevant({answers: form, company: companyObject}) && !isEmpty(checkCompanies) && <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel htmlFor={`${q.value}-simple`} className={classes.formControl}>
            {q.localization.t(locale)}
          </InputLabel>
          <Select
            native
            error={this.state.errors[q.value]}
            id={`${q.value}-simple`}
            name={q.value}
            value={answerKey}
            onChange={(e) => this.handleChange(q.value, e.target.value, q.alert)}
            disabled={(q.value === economicProfileQuestions.taxJurisdictionCountry.value && countryReadOnly ? true : false)}
          >
            <option value='' key='' className={classes.firstItemHide}></option>
            {map(q.getOptions({company: companyObject}), (option) =>
              !option.disable && !option.hidden ? (
                <option key={option.key} value={option.key}>
                  {option.localization.t(locale)}
                </option>
              ) : null
            )}
          </Select>
          {q.value === economicProfileQuestions.politicallyExposed.value &&
          (client.politicallyExposedReason || form.politicallyExposedReason) &&
          <Typography variant="caption" className={classNames(classes.textLink, classes.textRight)} onClick={() => this.setState({politicallyExposed: true})}>
            <Trans {...messages.viewDetails} /></Typography>}
        </FormControl></Grid>}
      {!q.disable && q.textfield && q.relevant({answers: form, company: companyObject}) && !isEmpty(checkCompanies) && <Grid item xs={12} key={q.value}>
        <TextField
          required
          placeholder={q.placeholderTranslation ? q.placeholderTranslation(locale) : null}
          id={q.value}
          name={q.value}
          label={q.localization.t(locale)}
          fullWidth
          value={answerKey}
          error={this.state.errors[q.value]}
          onChange={(e) => this.handleChange(q.value, e.target.value, q.alert)}
        />
        {q.value === economicProfileQuestions.tin.value && this.state.errors.tinUsError &&
          <FormHelperText className={classes.errorMessage}><Trans {...messages.tinUsError} /></FormHelperText>}
      </Grid>}
    </React.Fragment>
  }

  render() {
    const {classes, viewer, t} = this.props
    const {locale, companyObject} = this.context
    const {politicallyExposed, status, form, loading, submitMessageError, formChanged, usCitizenNotication, pepCheckboxError, showMessage} = this.state
    const lengthEconomic = Math.ceil(size(economicProfileQuestions) / 2)
    const firstColumn = reject(economicProfileQuestions,(q) => q.order > lengthEconomic)
    const secondColumn = reject(economicProfileQuestions,(q) => q.order <= lengthEconomic)
    const isFormChanged = includes(formChanged, true)

    return (
      <React.Fragment>
        <AlertDialog
          open={politicallyExposed}
          onAgree={() => this.onContinue()}
          title={t(messages.pepTitle.i18nKey, messages.pepTitle.defaults)}
          agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
        >
          <Grid container direction="row" alignContent="center" justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.subtitle}>
                <Trans {...messages.pepSubtitle} />
              </Typography>
            </Grid>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="Gender"
                name="gender1"
                value={get(form, 'politicallyExposedReason')}
                onChange={(event) => this.handleChange('politicallyExposedReason', event.target.value)}
              >
                {map(appropTestPoliticallyExposedReason, (option) =>
                  <FormControlLabel key={option.key} value={option.value} control={<Radio color="primary" className={pepCheckboxError ? classes.error: ''}/>}
                    label={<Typography variant="subtitle2" className={pepCheckboxError ? classes.error: ''}>
                      {/*@ts-ignore*/}
                      {option.localization.t(locale)}
                    </Typography>} />
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
        </AlertDialog>
        <Grid item id={gridScroll}></Grid>
        {(status === 'success'  || usCitizenNotication) &&
          <Grid item xs={12} sm={12}>
            <NotificationBar status={(usCitizenNotication) ? (usCitizenNotication === 'hasOpenPositions') ? 'warning': 'error' : 'success'}>
              {(!usCitizenNotication) ? <Trans {...messages.economicProfileChanged}
                components={[
                  <Link to={'/'} className={classes.textLink}>Dashboard</Link>,
                ]} />
                : <React.Fragment>
                  <Trans {...messages.openPositionsUsCitizen} values={{company: companyObject['brandLabel']}}
                    components={[(usCitizenNotication === 'hasOpenPositions') && <Link to={'/support'} className={classes.textLink}>contanctSupport</Link>]} />
                  {(usCitizenNotication !== 'hasOpenPositions') && <Trans {...messages.registrationRejectedUsCitizen}
                    components={[
                      <Button onClick={() => this.context.logout()} className={classNames(classes.usButton, classes.textLink)}>
                        <Trans {...messages.logout} />
                      </Button>,
                    ]} />}
                </React.Fragment>
              }
            </NotificationBar>
          </Grid>
        }
        <form className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={3}>
                {viewer && map(firstColumn, (q) => {
                  const answerKey = get(form, q.value, '')
                  return this.renderQuestion(q, answerKey, locale)
                })
                }
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={3}>
                {viewer && map(secondColumn, (q) => {
                  const answerKey = get(form, q.value, '')
                  return this.renderQuestion(q, answerKey, locale)
                })
                }
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <LoadingButton
                id='loadingButton'
                onClick={(e) => this.handleSubmit()}
                disabled={!isFormChanged || loading}
                hideProgressBar={!isFormChanged}
                status={status}
                className={isMobile() ? classes.button : ''}
              ><Trans {...messages.saveButtonSettings} />
              </LoadingButton>
              {status==='failure' &&
                <FormHelperText className={classes.errorMessage}>{submitMessageError}</FormHelperText>}
            </Grid>
          </Grid>
        </form>
        <AlertDialog
          open={showMessage}
          onClose={() => this.setState({showMessage: false})}
          agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
        >
          <Typography variant="subtitle1">
            {/*@ts-ignore*/}
            <Trans {...messages.migrateEpTestMessage} components={{company: get(companyObject, 'trademark')}}/>
          </Typography>
        </AlertDialog>
      </React.Fragment>
    )
  }
}


function applyPendingChanges(client, changes) {
  return {
    transactionPurpose: get(changes, 'globalQuestionnaire.transactionPurpose') ||
      get(client, 'globalQuestionnaire.transactionPurpose') || '',
    jobTitle: get(changes, 'globalQuestionnaire.jobTitle') ||
      get(client, 'globalQuestionnaire.jobTitle') || '',
    approxYearlyIncome: get(changes, 'globalQuestionnaire.approxYearlyIncome') ||
      get(client, 'globalQuestionnaire.approxYearlyIncome') || '',
    approxNetWorth: get(changes, 'globalQuestionnaire.approxNetWorth') ||
      get(client, 'globalQuestionnaire.approxNetWorth') || '',
    approxExpectedDeposit: get(changes, 'globalQuestionnaire.approxExpectedDeposit') ||
      get(client, 'globalQuestionnaire.approxExpectedDeposit') || '',
    taxJurisdictionCountry: get(changes, 'globalQuestionnaire.taxJurisdictionCountry') ||
      get(client, 'globalQuestionnaire.taxJurisdictionCountry') || '',
    sourceOfFunds: get(changes, 'globalQuestionnaire.sourceOfFunds') ||
      get(client, 'globalQuestionnaire.sourceOfFunds') || '',
    natureOfTransactions: get(changes, 'globalQuestionnaire.natureOfTransactions') ||
      get(client, 'globalQuestionnaire.natureOfTransactions') || '',
    originOfFunds: get(changes, 'globalQuestionnaire.originOfFunds') ||
      get(client, 'globalQuestionnaire.originOfFunds') || '',
    politicallyExposed: get(changes, 'globalQuestionnaire.politicallyExposed') ||
      get(client, 'globalQuestionnaire.politicallyExposed') || '',
    usCitizen: get(changes, 'globalQuestionnaire.usCitizen') ||
      get(client, 'globalQuestionnaire.usCitizen') || '',
    tinReason: get(changes, 'globalQuestionnaire.tinReason') ||
      get(client, 'globalQuestionnaire.tinReason') || '',
    tin: get(changes, 'globalQuestionnaire.tin') ||
    get(client, 'globalQuestionnaire.tin') || '',
    tinClarify: get(changes, 'globalQuestionnaire.tinClarify') ||
    get(client, 'globalQuestionnaire.tinClarify') || '',
    transactionPurposeClarify: get(changes, 'globalQuestionnaire.transactionPurposeClarify') ||
    get(client, 'globalQuestionnaire.transactionPurposeClarify') || '',
    natureOfTransactionsClarify: get(changes, 'globalQuestionnaire.natureOfTransactionsClarify') ||
    get(client, 'globalQuestionnaire.natureOfTransactionsClarify') || '',
    sourceOfFundsClarify: get(changes, 'globalQuestionnaire.sourceOfFundsClarify') ||
    get(client, 'globalQuestionnaire.sourceOfFundsClarify') || '',
    politicallyExposedReason: get(changes, 'globalQuestionnaire.politicallyExposedReason') ||
      get(client, 'globalQuestionnaire.politicallyExposedReason') || '',
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withWidth(),
  withNamespaces(),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: (props) => {
      const viewer = get(props.data, 'viewer')
      const profileChanges = get(viewer, 'profileChanges[0].answers', {})
      return {
        error: props.data?.error,
        loading:props.data?.loading,
        profileChanges,
        viewer,
        client: applyPendingChanges(viewer, profileChanges)
      }
    }
  }),
  graphql(UPDATE_ECONOMIC_PROFILE_MUTATION, {
    name: 'updateDetails',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}, {query: PROFILE_SETTINGS_QUERY}],
    }, //@ts-ignore
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    name: 'profileSettings',
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: (props) => ({
      clientData: get(props.data, 'viewer'),
    })
  }),
)(EconomicProfile)
