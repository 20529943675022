import {Trans} from 'react-i18next'
import messages from '../../../../assets/messages'
import {Container} from './components/Container'
import {Header} from './components/Header'
import {Content} from './components/Content'
import LoadingButton from '../../../Common/LoadingButton'
import {isMobile} from '../../../../common/utils'
import {toPammPortal} from '../../../Accounts/components/Cards/PammTauroMarketsAccountCard/toPammPortal'

type AlreadyAcceptedParams = {
  pammId: number,
}

export const AlreadyAccepted = ({pammId}: AlreadyAcceptedParams) => {
  const handleCTA = () => {
    toPammPortal(String(pammId))
  }

  return (
    <Container>
      <Header>
        <Trans {...messages.alreadyAcceptedHeader} />
      </Header>
      <Content>
        <Trans {...messages.alreadyAcceptedContent} />
      </Content>
      {pammId !== 0 && <LoadingButton
        disabled={false}
        id='loadingButton'
        onClick={handleCTA}
        fullWidth={isMobile()}
      >
        <Trans {...messages.toPammPortal} />
      </LoadingButton>}
    </Container>
  )
}
