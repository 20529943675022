import React from 'react'
import {get, find, map, isEmpty, sortBy, isFunction, includes, flowRight as compose, omitBy} from 'lodash'
import {branch} from 'recompose'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import withStyles from '@material-ui/core/styles/withStyles'
import FormHelperText from '@material-ui/core/FormHelperText'
import {withdrawalPaymentFields, countries, withdrawalPaymentVendors, directa24TaxNumberLabels, FieldType} from '@bdswiss/common-enums'
import messages from '../../../assets/messages'
import {safeParseJSON} from '../../../common/utils/general'
import AppContext from '../../Common/contexts/AppContext'
import {DYNAMIC_WITHDRAWAL_FIELDS_QUERY} from '../../../graphql/queries'
import Loading from '../../Common/Loading'
import {InnerAppContext} from '../../../common/types'

const styles = theme => ({
  error: {
    color: theme.palette.red.color
  },
})

class WithdrawalFieldsView extends React.Component<any,any> {
  static contextType = AppContext
  context!: InnerAppContext
  render() {
    const {method, country, errors, form, availableMethod, methodFields, t, classes, dynamicFields, handleChange} = this.props
    const termsAndConditionsField = find(withdrawalPaymentFields, {value: 'terms_and_conditions'})
    const {locale} = this.context
    const localeParams : any = {}
    if (method === withdrawalPaymentVendors.directa24.value) {
      localeParams.id_type = get(directa24TaxNumberLabels, country.toLowerCase()) ? directa24TaxNumberLabels[country.toLowerCase()].label : 'ID'
    } else if (method === withdrawalPaymentVendors.payRetailers.value) {
      localeParams.id_type = '#'
    }
    return map(omitBy(methodFields, (f) => f.key === withdrawalPaymentFields.termsAndConditions.key), (field, i) => {
      const label = field.localization.t(locale, localeParams)
      const options = isFunction(field.options)
        ? field.options({
          withdrawalPaymentVendor: method,
          dynamicFields,
          country: get(countries[country], 'value'),
          paymentOption: get(availableMethod, 'paymentOption') || get(form, 'paymentOption'),
          bankCode: get(form, 'provider'),
        }) : field.options
      const hidden = isFunction(field.hidden)
        ? field.hidden({
          country: get(countries[country], 'key'),
          paymentOption: get(availableMethod, 'paymentOption') || get(form, 'paymentOption'),
        }) : false
      const additionalProps = field.key === withdrawalPaymentFields.cardNumber.key
        ? {inputProps: {pattern: '[0-9]*', maxLength: 4, inputMode:'numeric'}}
        : {}
      const specificErrors = ['iban', 'swiftCode', 'walletAddressConfirmation']

      if (field.fieldType === FieldType.checkbox) {
        const translateContext = getPaymentFieldDescriptionTranslateContext(field.key)
        return (
          <Grid item xs={12} key={i}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!get(form, field.key, '')}
                  onChange={(e) =>
                    handleChange(field.key, e.target.checked ? 'accept' : '')
                  }
                  value={get(form, field.key, '')}
                  className={get(errors, field.key, false) ? classes.error : ''}
                />
              }
              label={translateContext ? <Trans {...translateContext} values={{company: this.context.companyObject.brandLabel,}}/> : label}
            />
          </Grid>
        )
      }

      return (<Grid item xs={12} key={i}>
        {!options && !hidden && field.key !== termsAndConditionsField?.key &&
          <TextField
            label={label}
            value={get(form, field.key, '')}
            onChange={(e) => handleChange(field.key, e.target.value)}
            id={field.key}
            fullWidth
            error={!!get(errors, field.key, false)}
            helperText={(includes(specificErrors, field.key) && get(errors, field.key) === 'validation')
              ? t(messages[`${field.key}Validation`].i18nKey)
              : (get(errors, field.key, false) && t(messages.requiredField.i18nKey, messages.requiredField.defaults))}
            {...additionalProps}
            disabled={!isEmpty(get(availableMethod, field.key))}
            inputProps={field.key === 'iban' ? {style: {textTransform: 'uppercase'}}: {}}
          />
        }
        {field.key === 'swiftCode' && get(errors, 'ibanSwiftNotCoherent') && <FormHelperText className={classes.error}>
          <Trans {...messages.ibanSwiftNotCoherent} /></FormHelperText>}
        {options && !hidden && field.key !== termsAndConditionsField?.key &&
          <FormControl fullWidth>
            <InputLabel htmlFor={field.key}>{field.localization.t(locale)}</InputLabel>
            <Select
              value={get(form, field.key, '')}
              onChange={(e) => {
                handleChange(field.key, e.target.value, get(find(options, {key: e.target.value}), 'label'))
                if (field.key === withdrawalPaymentFields.provider.key) {
                  const methodFieldKeys = map(methodFields, 'key')
                  const branchCodeKey = withdrawalPaymentFields.branchCode.key
                  if (includes(methodFieldKeys, branchCodeKey) && get(form, branchCodeKey)) {
                    handleChange(branchCodeKey, '')
                  }
                }
              }}
              error={get(errors, field.key, false)}
              inputProps={{
                name: field.key,
                id: field.key,
              }}
              disabled={!isEmpty(get(availableMethod, field.key))}
            >
              {map(sortBy(options, 'label'), (o) => (
                <MenuItem
                  value={o.key}
                  // label={o.label}
                  key={o.key}
                >
                  {o.label}
                </MenuItem>
              ))}
            </Select>
            {get(errors, field.key, false) &&
              <FormHelperText className={classes.error}><Trans {...messages.requiredField} /></FormHelperText>
            }
          </FormControl>
        }
      </Grid>
      )
    })
  }
}

function getPaymentFieldDescriptionTranslateContext(paymentFieldKey: string) {
  switch (paymentFieldKey) {
    case withdrawalPaymentFields.walletResponsibilityConfirmation.key:
      return messages.walletResponsibilityConfirmation
    default:
      return null
  }
}

const dynamicFieldsQuery = graphql(DYNAMIC_WITHDRAWAL_FIELDS_QUERY, {
  options: (options: any) => ({
    fetchPolicy: 'no-cache',
    variables: {providerName: options.method},
  }),
  name: 'dynamicFields'}
)

const dynamicWithdrawalFields = (Component) => dynamicFieldsQuery(({dynamicFields, ...other}) => {
  if (dynamicFields.loading) {
    return <Loading />
  }
  const fields = get(dynamicFields, 'dynamicWithdrawalFields.fields', '{}')
  const withdrawalFields = safeParseJSON(fields)
  return <Component dynamicFields={withdrawalFields} {...other} />
})

export default compose(
  branch(
    ({method}) => get(find(withdrawalPaymentVendors, {key: method}), 'hasDynamicWithdrawalFields', false),
    dynamicWithdrawalFields
  ),
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(WithdrawalFieldsView)
