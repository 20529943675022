import {nationalitiesListWithFlag} from '../../../common/utils'

export const getInitialMifirPopupState = (nationality?: string) => ({
  form: {
    nationality: nationality ?? '',
    mifirType: '',
    mifirId: '',
  },
  nationalityChanged: false,
  nationalityFlag:'',
  formErrors: {
    nationality: false,
    mifirType: false,
    mifirId: false,
    onSubmit: false,
  },
  formStatus: '',
  loading: false,
  nationalitiesFlags: nationalitiesListWithFlag(),
})
