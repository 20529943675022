import React from 'react'
import {flowRight as compose} from 'lodash'
import PropTypes from 'prop-types'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import withWidth from '@material-ui/core/withWidth'
import {AlertDialog} from '../../Common/Dialog'
import messages from '../../../assets/messages'
import {config} from '../../../config'

const {weblinks} = config

const styles = {
  subtitleGrid: {
    padding: 8,
  },
  labelField: {
    paddingRight: 4,
  },
  valueField: {
    paddingLeft: 4,
    paddingTop:6
  },
  styledText: {
    whiteSpace: 'pre-line' as const,
    fontSize: 16,
    lineHeight: 1.5,
  }
}

const MauMT4TermsAndConditionsModal = (props) => {
  const {open, classes, t, onSubmit, onCancel} = props
  return (
    <AlertDialog
      open={open}
      title={t(messages.mauMT4TermsTitle.i18nKey, messages.mauMT4TermsTitle.defaults)}
      agreeText={t(messages.mauMT4TermsAccept.i18nKey, messages.mauMT4TermsAccept.defaults)}
      onAgree={() => onSubmit()}
      disagreeText={t(messages.mauMT4TermsCancel.i18nKey, messages.mauMT4TermsCancel.defaults)}
      onDisagree={() => onCancel()}
    >
      <Grid container direction="row" alignContent="center" justifyContent="center">
        <Grid item xs={12} className={classes.subtitleGrid}>
          <p className={classes.styledText}>
            <Trans
              i18nKey={messages.mauMT4TermsBody1.i18nKey}
              defaults="By clicking the button below, you confirm that you have read, understood, and agreed to the following: <0>General Terms & Conditions</0>, <1>Legal Documents</1>, <2>Personal Data Protection</2>."
              components={[
                <a
                  href={weblinks.termsAndConditions}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  General Terms & Conditions
                </a>,
                <a
                  href={weblinks.legalDocuments}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  Legal Documents
                </a>,
                <a
                  href={weblinks.privacyPolicy}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  Personal Data Protection
                </a>,
              ]}
            />
          </p>
          <p className={classes.styledText}>
            <Trans
              i18nKey={messages.mauMT4TermsBody2.i18nKey}
              defaults="We are committed to protecting your personal data and ensuring a secure, seamless experience on our platform. For more details on how we manage your information, please visit our <0>Privacy Policy</0>."
              components={[
                <a
                  href={weblinks.privacyPolicy}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  Privacy Policy
                </a>,
              ]}
            />
          </p>
        </Grid>
      </Grid>
    </AlertDialog>
  )
}

MauMT4TermsAndConditionsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withWidth(),
  withNamespaces(),
)(MauMT4TermsAndConditionsModal)
