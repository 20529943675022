import React, {Component, type ReactNode} from 'react'
import {BugsnagErrorBoundary} from '../../bugsnag'
import {OnErrorCallback} from '@bugsnag/js'

type ErrorBoundaryWrapperProps = {
  children: ReactNode;
  onError?: OnErrorCallback;
  FallbackComponent?: React.ComponentType<{
    error: Error;
    info: React.ErrorInfo;
    clearError: () => void;
  }>;
};

export class ErrorBoundaryWrapper extends Component<
  ErrorBoundaryWrapperProps,
unknown> {
  render() {
    const {children, ...rest} = this.props

    if (BugsnagErrorBoundary == null)
      throw new Error('BugsnagErrorBoundary is not defined')

    return <BugsnagErrorBoundary {...rest}>{children}</BugsnagErrorBoundary>
  }
}
