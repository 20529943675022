import styled from '@emotion/styled'

export const SuccessModalContainer = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 348px;
  left: 50%;
  padding: 30px;
  padding-top: 54px;
  position: fixed;
  top: 50%;
  width: 575px;
  transform: translate(-50%, -50%);
  z-index: 2;
`
