import {Grid, WithStyles, withStyles} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import {Trans} from 'react-i18next'
import messages from '../../../../../../../../assets/messages'
import {styles} from '../styles'

const _DepositNeededBadge = ({classes}: WithStyles<typeof styles>) => (
  <Grid item className={classNames(classes.pending, classes.isDemoArchived)}>
    <Typography variant='caption' className={classes.pendingText}><Trans {...messages.depositNeeded} /></Typography>
  </Grid>

)

export const DepositNeededBadge = withStyles(styles, {withTheme: true})(_DepositNeededBadge)
