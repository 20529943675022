import styled from '@emotion/styled'

export const MT5Badge = styled.div`
  align-items: center;
  background: #EBF8E4;
  border-radius: 4px;
  color: #707070;
  display: flex;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  gap: 10px
  justify-content: center;
  letter-spacing: 0.4px;
  line-height: 24px; /* 171.429% */
  padding: 2px 10px;
`
