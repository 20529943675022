import {Trans} from 'react-i18next'
import {CloseButton} from '../components/shared/CloseButton'
import {Overlay} from '../components/shared/Overlay'
import {SuccessModalTitle} from '../components/shared/SuccessModalTitle'
import {MfaDeleteConfirmationModalContainer} from './components/MfaDeleteConfirmationModalContainer'
import messages from '../../../../../assets/messages'
import {SuccessModalContent} from '../components/shared/SuccessModalContent'
import Button from '@material-ui/core/Button'
import {Buttons} from './components/Buttons'

type MfaDeleteConfirmationModalProps = {
  onCancel: () => void;
  onSubmit: () => void;
}

export const MfaDeleteConfirmationModal = ({onCancel, onSubmit}: MfaDeleteConfirmationModalProps) => (
  <>
    <Overlay onClick={onCancel} />
    <MfaDeleteConfirmationModalContainer>
      <CloseButton onClick={onCancel} />
      <SuccessModalTitle>
        <Trans {...messages.disableAuthenticatorApp}></Trans>
      </SuccessModalTitle>
      <SuccessModalContent>
        <Trans {...messages.disableAuthenticatorAppWarning}></Trans>
      </SuccessModalContent>
      <Buttons>
        <Button onClick={onCancel} variant="outlined" color="primary">
          <Trans {...messages.cancel}></Trans>
        </Button>
        <Button onClick={onSubmit} variant="contained" color="primary">
          <Trans {...messages.disable}></Trans>
        </Button>
      </Buttons>
    </MfaDeleteConfirmationModalContainer>
  </>

)
