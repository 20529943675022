import React, {Component} from 'react'
import {get, isEmpty, some, omit, omitBy, mapValues, findKey, filter, find, includes,
  flowRight as compose, trim, toString, lowerCase, pick} from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import {accountTypes, countries, whiteLabels} from '@bdswiss/common-enums'
import {withNamespaces, Trans} from 'react-i18next'
import FormHelperText from '@material-ui/core/FormHelperText'
import {graphql} from 'react-apollo'
import {CLIENT_PROFILE_QUERY, PROFILE_SETTINGS_QUERY} from '../../../../../graphql/queries'
import {UPDATE_OWN_DETAILS_MUTATION, UPDATE_OWN_EMAIL_MUTATION} from '../../../../../graphql/mutations'
import messages from '../../../../../assets/messages'
import {Loading} from '../../../../Common/Loading'
import LoadingButton from '../../../../Common/LoadingButton'
import NotificationBar from '../../../../Common/NotificationBar'
import {withRouter, Link} from 'react-router-dom'
import AppContext from '../../../../Common/contexts/AppContext'
import {scrollElementIntoView} from '../../../../../common/utils'
import {
  validateEmail,
  validatePhone,
  validateDate,
  validateDOB,
  validateCharacters,
  validateCountryCodePrefix,
  validateAddress,
  validateLength
} from '../../../../../common/utils/validations'
import CountriesSelect from '../../../../Common/CountriesSelect'
import {isMobile} from '../../../../../common/utils/browser'
import Button from '@material-ui/core/Button'
import {getCountries} from '../../../../../common/utils/requests'
import {hasOnlyWalletProductAccount} from '../../../../../common/utils/accounts'
import {getLocaleMoment, nationalitiesListWithFlag} from '../../../../../common/utils/general'
import {momentLocales, fnsLocales} from '../../../../../common/utils/uioptions'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import * as dateFnsLocales from 'date-fns/locale'
import {InnerAppContext} from '../../../../../common/types'
import {styles} from './styles'
import {
  ADDRESS_FIELDS,
  FORM_FIELD_NAMES,
  CORRESPONDENCE_ADDRESS_FIELDS_NAMES,
} from './constants'
import {applyFormValues} from './utils'
import {CorrespondenceAddressFields} from './CorrespondenceAddressFields'
import {ResidentialAddressFields} from './ResidentialAddressFields'

const gridScroll = 'scroll-grid'
const REGION_LENGTH_MAX = 32
const CITY_LENGTH_MAX = 40
const FIELD_NAMES_TO_REMOVE_SPECIAL_CHARS = [
  FORM_FIELD_NAMES.line1,
  FORM_FIELD_NAMES.houseNumber,
  FORM_FIELD_NAMES.city,
  FORM_FIELD_NAMES.region,
  FORM_FIELD_NAMES.zip
]
class PersonalDetails extends Component<any,any> {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  }
  static contextType = AppContext
  context!: InnerAppContext
  geocoderService: any
  constructor(props) {
    super(props)
    const {viewer} = props
    const form = {
      ...mapValues(omit(viewer, ['address','secondaryEmails','secondaryPhones']), (v) => v || ''),
      ...mapValues(viewer.address, (v) => v || ''),
      ...mapValues(viewer.secondaryEmails, (v) => v || ''),
      ...mapValues(viewer.secondaryPhones, (v) => v || ''),
    }

    if (window.google && !isEmpty(window.google))
      this.geocoderService = new window.google.maps.Geocoder()

    this.state = {
      form,
      errors: {}, showSecondaryEmail: false, showSecondaryPhone: false, loading: false, status: '',
      submitMessageError:'',
      nationalityFlag:'',
      nationalityChanged: false,
      formChanged: {},
      errorsKeys: {},
      allowedCountries: [],
      nationalitiesFlags: nationalitiesListWithFlag(),
      isUseResidentialAddressAsCorrespondenceAddress: false,
    }
  }

  componentDidMount() {
    const {locale, companyObject, accounts} = this.context
    moment.locale(getLocaleMoment(locale, momentLocales.excluded))
    let walletAccount = find(accounts, (a) => get(accountTypes[a.__typename], 'walletProduct'))
    if (walletAccount) walletAccount = find(accountTypes, (accountType) => accountType.key === get(walletAccount, '__typename'))
    this.getCountries(walletAccount && walletAccount.value, companyObject.key)
  }

  getSecondaryEmail() {
    this.setState({showSecondaryEmail: true})
  }

  getSecondaryPhone() {
    this.setState({showSecondaryPhone: true})
  }

  handleSwitchUseResidentialAddressAsCorrespondenceAddress = () => {
    this.setState((prevState) => {
      const {isUseResidentialAddressAsCorrespondenceAddress, form, errors, formChanged, errorsKeys} = prevState

      const {valuesToUpdate, errorsToUpdate, errorsKeysToUpdate, formChangedToUpdate} = !isUseResidentialAddressAsCorrespondenceAddress
        ? this.correspondenceAddressStateToSync
        : {valuesToUpdate: {}, errorsToUpdate: {}, errorsKeysToUpdate: {}, formChangedToUpdate: {}}

      return {
        isUseResidentialAddressAsCorrespondenceAddress: !isUseResidentialAddressAsCorrespondenceAddress,
        form: {...form, ...valuesToUpdate},
        errors: {...errors, ...errorsToUpdate},
        errorsKeys: {...errorsKeys, ...errorsKeysToUpdate},
        formChanged:{...formChanged, ...formChangedToUpdate},
        status: ''
      }
    })
  }

  handleChangeValue = ({fieldValue, fieldName}) => {
    const {viewer} = this.props
    this.setState(state => {
      const {isUseResidentialAddressAsCorrespondenceAddress} = state
      const isAddressFieldUpdates = Object.keys(ADDRESS_FIELDS).includes(fieldName)
      const shouldCorrespondenceBeUpdatedToo = isUseResidentialAddressAsCorrespondenceAddress && isAddressFieldUpdates


      const formFieldToUpdate = shouldCorrespondenceBeUpdatedToo
        ? {[fieldName]: fieldValue, [CORRESPONDENCE_ADDRESS_FIELDS_NAMES[fieldName]]: fieldValue}
        : {[fieldName]: fieldValue}

      const errorsToUpdate = shouldCorrespondenceBeUpdatedToo
        ? {[fieldName]: !fieldValue, [CORRESPONDENCE_ADDRESS_FIELDS_NAMES[fieldName]]: !fieldValue}
        : {[fieldName]: !fieldValue}

      const formChangedToUpdate = shouldCorrespondenceBeUpdatedToo
        ? {
          [fieldName]: (fieldValue !== viewer[fieldName]),
          [CORRESPONDENCE_ADDRESS_FIELDS_NAMES[fieldName]]: (fieldValue !== viewer[CORRESPONDENCE_ADDRESS_FIELDS_NAMES[fieldName]])
        } : {
          [fieldName]: (fieldValue !== this.props.viewer[fieldName])
        }

      return {
        form: {...state.form, ...formFieldToUpdate},
        errors: {...state.errors, ...errorsToUpdate},
        formChanged:{...state.formChanged, ...formChangedToUpdate},
        status: ''
      }
    })
  }

  handleChangeRegion = ({fieldValue, fieldName}) => {
    if (validateLength(fieldValue, REGION_LENGTH_MAX)) {
      return this.handleChangeValue({fieldValue, fieldName})
    }

    return this.setState((state) => ({
      ...state,
      form: {...state.form, [fieldName]: fieldValue},
      errors: {...state.errors, [fieldName]: true},
      errorsKeys: {...state.errorsKeys, [fieldName]: 'maxRegionLengthError'},
      formChanged:{...state.formChanged, [fieldName]: false},
    }))
  }

  handleChangeCity = ({fieldValue, fieldName}) => {
    if (validateLength(fieldValue, CITY_LENGTH_MAX)) {
      return this.handleChangeValue({fieldValue, fieldName})
    }

    return this.setState((state) => ({
      ...state,
      form: {...state.form, [fieldName]: fieldValue},
      errors: {...state.errors, [fieldName]: true},
      errorsKeys: {...state.errorsKeys, [fieldName]: 'maxStateLengthError'},
      formChanged:{...state.formChanged, [fieldName]: false},
    }))
  }

  handleChangePreviousName = value => {
    this.setState((state) => ({
      ...state,
      form: {...state.form, [FORM_FIELD_NAMES.previousNames]: value},
      formChanged:{...state.formChanged, [FORM_FIELD_NAMES.previousNames]: (value !== this.props.viewer.previousNames)},
    }))
  }

  handleChange = (fieldName, fieldValue) => {
    if (includes(FIELD_NAMES_TO_REMOVE_SPECIAL_CHARS, fieldName)) {
      fieldValue = toString(fieldValue)
        .normalize('NFD')
        .replace(/[']/g, ' ')
        // Allows for arabic characters as well as per task SKYG-793
        .replace(/[^a-zA-Z0-9 \u0600-\u06FF]/g, '')
    }

    switch (fieldName) {
      case FORM_FIELD_NAMES.region:
      case CORRESPONDENCE_ADDRESS_FIELDS_NAMES.region:
        this.handleChangeRegion({fieldValue, fieldName})
        break
      case FORM_FIELD_NAMES.city:
      case CORRESPONDENCE_ADDRESS_FIELDS_NAMES.city:
        this.handleChangeCity({fieldValue, fieldName})
        break
      case FORM_FIELD_NAMES.previousNames:
        this.handleChangePreviousName(fieldValue)
        break
      default:
        this.handleChangeValue({fieldValue, fieldName})
    }
  }

  scrollUp(errors : any = null) {
    this.setState(() => scrollElementIntoView(isEmpty(errors)?gridScroll:findKey(errors), 250))
  }

  handleSubmit = countryList => {
    const {form, form: {email}, nationalitiesFlags} = this.state
    const {viewer, t, handleChangeParent} = this.props

    const validateField = (fieldName, fieldValue, countryList, viewer) => {
      switch (fieldName) {
        case FORM_FIELD_NAMES.phone:
        case FORM_FIELD_NAMES.secondaryPhone1:
          return validatePhoneField(fieldName, fieldValue, countryList)
        case FORM_FIELD_NAMES.email:
          return validateEmailField(fieldValue)
        case FORM_FIELD_NAMES.secondaryEmail1:
          return validateSecondaryEmailField(fieldValue)
        case FORM_FIELD_NAMES.firstName:
        case FORM_FIELD_NAMES.lastName:
          return validateNameField(fieldName, fieldValue, viewer)
        case FORM_FIELD_NAMES.birthday:
          return validateDOBField(fieldValue)
        case FORM_FIELD_NAMES.line1:
        case FORM_FIELD_NAMES.houseNumber:
        case FORM_FIELD_NAMES.city:
        case FORM_FIELD_NAMES.region:
          return validateAddressField(fieldName, fieldValue, viewer)
        case CORRESPONDENCE_ADDRESS_FIELDS_NAMES.line1:
        case CORRESPONDENCE_ADDRESS_FIELDS_NAMES.houseNumber:
        case CORRESPONDENCE_ADDRESS_FIELDS_NAMES.city:
        case CORRESPONDENCE_ADDRESS_FIELDS_NAMES.region:
          return this.isTauromarketsMauritiusCompany
            ? validateAddressField(fieldName, fieldValue, viewer)
            : false
        case FORM_FIELD_NAMES.zip:
          return validateZipField(fieldName, fieldValue)
        case CORRESPONDENCE_ADDRESS_FIELDS_NAMES.zip:
          return this.isTauromarketsMauritiusCompany
            ? validateZipField(fieldName, fieldValue)
            : false
        case FORM_FIELD_NAMES.previousNames:
          return false
        case CORRESPONDENCE_ADDRESS_FIELDS_NAMES.country:
        case FORM_FIELD_NAMES.passportNumber:
          return this.isTauromarketsMauritiusCompany
            ? isEmpty(fieldValue)
            : false
        default:
          return isEmpty(fieldValue)
      }
    }

    const validatePhoneField = (field, value, countryList) => {
      const prefixError = !validateCountryCodePrefix(value, countryList)
      setErrorState(field, prefixError)
      return isEmpty(value) || !validatePhone(value, true) || prefixError
    }

    const validateEmailField = (value) => isEmpty(value) || !validateEmail(value)

    const validateSecondaryEmailField = (value) => !validateEmail(value)

    const validateNameField = (field, value, viewer) => {
      const isChanged = viewer[field] !== value
      if (isChanged) {
        const nameError = validateCharacters(trim(value))
        setErrorState(field, nameError)
        return !!nameError
      }
      return false
    }

    const validateDOBField = (value) => !validateDOB(value)

    const validateAddressField = (fieldName, fieldValue, viewer) => {
      if (isEmpty(fieldValue)) {
        return true
      }

      const isChanged = viewer[fieldName] !== fieldValue
      if (isChanged) {
        if (
          [FORM_FIELD_NAMES.region, CORRESPONDENCE_ADDRESS_FIELDS_NAMES.region].includes(fieldName)
          && !validateLength(fieldValue, 32)
        ) {
          return true
        }
        const addressError = validateAddress(fieldValue)
        setErrorState(fieldName, addressError)
        return !!addressError
      }
      return false
    }

    const validateZipField = (fieldName, fieldValue) => {
      if (isEmpty(fieldValue)) {
        return true
      }

      if (!validateLength(fieldValue, 10,2)) {
        setErrorState(fieldName, 'maxZipCodeLengthError')
        return true
      }
      return false
    }

    const setErrorState = (field, error) => {
      this.setState(state => ({errorsKeys: {...state.errorsKeys, [field]: error}}))
    }

    const errors = {}

    for (const field of Object.keys(form)) {
      errors[field] = validateField(field, form[field], countryList, viewer)
    }

    if (some(omitBy(errors, (e, key) => includes(['secondaryEmail1', 'secondaryPhone1', 'houseNumber'], key) && !form[key]))) {
      this.setState({errors},() => {this.scrollUp(errors)})
      return
    }

    const variables = omitBy(form, (value, key) => {
      if ((!['secondaryEmail1', 'secondaryPhone1', 'previousNames'].includes(key) && isEmpty(value)) || viewer[key] === value ||
          (key === 'secondaryEmail1' && get(viewer['secondaryEmails'], 'secondaryEmail1') === value ) ||
          (key === 'secondaryPhone1' && get(viewer['secondaryPhones'], 'secondaryPhone1') === value)
      ) {
        return true
      }
    })

    variables['line1'] = (variables['houseNumber'] || variables['line1']) && (form.houseNumber ? `${form.line1} {HouseNumber:[${form.houseNumber}]}` : form.line1)
    variables['country'] = variables['country'] && variables['country'].toLowerCase()
    variables['nationality'] = variables['nationality'] && get(find(nationalitiesFlags,(a)=> variables['nationality'] && a.key === variables['nationality']), 'keyCountry', '')
    //@ts-ignore
    variables['birthday'] = variables['birthday'] && moment(variables['birthday'], validateDate(variables['birthday'], true, true)).format('YYYY-MM-DD') /* convert to DB format */
    variables['firstName'] = variables['firstName'] && trim(variables['firstName'])
    variables['lastName'] = variables['lastName'] && trim(variables['lastName'])

    variables[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.country] = variables[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.country]
      ? variables[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.country].toLowerCase()
      : null
    variables[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.line1] =
      (variables[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.houseNumber] || variables[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.line1]) &&
      (form[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.houseNumber]
        ? `${form[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.line1]} {HouseNumber:[${form[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.houseNumber]}]}`
        : form[CORRESPONDENCE_ADDRESS_FIELDS_NAMES.line1]
      )

    this.setState({status: '', loading: true})
    this.props.updateDetails({variables}).then((succ) => {
      if (variables.email) {
        this.props.updateOwnEmail({variables: {email}}).then((updateEmailResult) => {
          this.scrollUp()
          this.setState({loading: false,status: 'success',submitMessageError: '',formChanged: false})
          handleChangeParent && handleChangeParent('profileCompleted', true)
        }).catch((err) => {
          if (err.networkError) {
            this.setState({loading: false,status: 'failure',
              submitMessageError: t(messages.networkError.i18nKey, messages.networkError.defaults),
            })
          } else {
            this.setState({loading: false,status: 'failure',
              submitMessageError: get( err, 'graphQLErrors[0].message') || err.message,
            })
          }
        })
      } else {
        this.scrollUp()
        this.setState({loading: false,status: 'success',submitMessageError: '',formChanged: false})
        handleChangeParent && handleChangeParent('profileCompleted', true)
      }
    }).catch((err) => {
      if (err.networkError) {
        this.setState({loading: false,status: 'failure',
          submitMessageError: t(messages.networkError.i18nKey, messages.networkError.defaults),
        })
      } else {
        this.setState({loading: false,status: 'failure',
          submitMessageError: get( err, 'graphQLErrors[0].message') || err.message,
        })
      }
    })
  }

  handleChangeNationality = value => {
    const {nationalitiesFlags} = this.state
    this.setState({nationalityFlag: get(find(nationalitiesFlags,(a)=>
      value && (a.key === lowerCase(value) || lowerCase(a.value) === lowerCase(value))), 'label', ''), status: ''})
  }

  setStateOuter = (prop, value) => {
    this.setState({[prop]: value})
  }

  async getCountries(product, company) {
    const {viewer: {country}} = this.props
    await getCountries(product, company)
      .then((res) => {
        if (!find(res.countries, {key: country})) {
          res.countries.push(countries[country])
        }
        this.setState({allowedCountries: res.countries})
      })
      .catch((err) => err)
  }

  get isTauromarketsMauritiusCompany() {
    const {companyObject} = this.context
    return companyObject.key === whiteLabels.tauroMarketsMauritius.key
  }

  get correspondenceAddressStateToSync() {
    const {form, errors, errorsKeys} = this.state

    function getSynchedState(stateObj) {
      return Object.keys(ADDRESS_FIELDS).reduce((obj, key) => {
        obj[CORRESPONDENCE_ADDRESS_FIELDS_NAMES[key]] = stateObj[key]
        return obj
      }, {})
    }

    const formChangedToUpdate = Object.keys(ADDRESS_FIELDS).reduce((obj, key) => {
      obj[CORRESPONDENCE_ADDRESS_FIELDS_NAMES[key]] = true
      return obj
    }, {})

    const valuesToUpdate = getSynchedState(form)
    const errorsToUpdate = getSynchedState(errors)
    const errorsKeysToUpdate = getSynchedState(errorsKeys)

    return {valuesToUpdate, errorsToUpdate, errorsKeysToUpdate, formChangedToUpdate}
  }

  render() {
    const {classes, t, economicProfile, location, emailPendingChange, viewer, viewerLoading, profileLoading, register} = this.props
    const {
      form, showSecondaryEmail, showSecondaryPhone, loading, submitMessageError, status, nationalityChanged, nationalityFlag,
      formChanged, errors, errorsKeys, allowedCountries, nationalitiesFlags, isUseResidentialAddressAsCorrespondenceAddress
    } = this.state
    const {
      firstName,
      lastName,
      previousNames,
      birthday,
      email,
      secondaryEmail1,
      phone,
      secondaryPhone1,
      nationality,
      passportNumber
    } = form
    const {accounts, locale} = this.context

    if (!viewer || viewerLoading || profileLoading) return <Loading />
    const {blockedDeposit} = this.context
    const maxDate = moment().subtract(18,'year').format('YYYY-MM-DD')
    const minDate = moment().subtract(120,'year').format('YYYY-MM-DD')
    const showPendingConfirmation = emailPendingChange !== null
    const countryList = allowedCountries || []
    const birthdayFormat =  birthday && validateDate(birthday, true, true)
    const birthdayValidFormat = birthdayFormat && moment(birthday,birthdayFormat).format('YYYY-MM-DD')
    const isFormChanged = includes(formChanged, true)
    const nationalitiesList = filter(nationalitiesFlags, nationality => !hasOnlyWalletProductAccount(accounts) ?
      (!nationality.forbidden && !nationality.hidden) : !nationality.hidden)

    return (
      <React.Fragment>
        <Grid container spacing={0} id={gridScroll}>
          {status==='success' && !register &&
          <Grid item xs={12} sm={12}>
            <NotificationBar status='success'>
              <Trans {...messages.personalDetailsChanged} />
              {isEmpty(economicProfile)  && !blockedDeposit && <span>&nbsp;
                <Trans {...messages.updateEconomicProfile}
                  components={[
                    <Link to={`${location.pathname.replace(/[^/]+$/,'')}economic`} className={classes.linkText}>Economic Profile</Link>,
                  ]} /></span>
              }
            </NotificationBar>
          </Grid>
          }
          <Grid item xs={12} sm={12}>
            <Typography variant="caption" color="secondary" className={classes.pageDescription}>
              *<Trans {...messages.confirmValidPersonalDetails} />
            </Typography>
          </Grid>
          <div className={classes.container}>
            <Typography className={classes.pageSubtitle}>
              <Trans {...messages.personalInformation} />
            </Typography>
            <div className={classes.fieldsContainer}>
              <div className={classes.fieldsColumn}>
                <div className={classes.fieldsRow}>
                  <div className={classes.field}>
                    <TextField
                      required
                      id="firstName"
                      name="firstName"
                      label={t(messages.firstName.i18nKey, messages.firstName.defaults)}
                      fullWidth
                      autoComplete="firstName"
                      error={errors.firstName}
                      value={firstName}
                      onChange={(e) => this.handleChange('firstName', e.target.value)}
                    />
                    {firstName && errors.firstName && messages[errorsKeys.firstName] ? (
                      <FormHelperText className={classes.errorMessage}>
                        <Trans {...messages[errorsKeys.firstName]} />
                      </FormHelperText>
                    ) : null}
                  </div>
                  <div className={classes.field}>
                    <TextField
                      required
                      id="lastName"
                      name="lastName"
                      label={t(messages.lastName.i18nKey, messages.lastName.defaults)}
                      fullWidth
                      autoComplete="lastname"
                      value={lastName}
                      error={errors.lastName}
                      onChange={(e) => this.handleChange('lastName', e.target.value)}
                    />
                    {lastName && errors.lastName && messages[errorsKeys.lastName] ? (
                      <FormHelperText className={classes.errorMessage}>
                        <Trans {...messages[errorsKeys.lastName]} />
                      </FormHelperText>
                    ) : null}
                  </div>
                </div>
                {this.isTauromarketsMauritiusCompany ? (
                  <div className={classes.fieldsRow}>
                    <TextField
                      id="previousNames"
                      name="previousNames"
                      label={t(messages.previousNamesOptional.i18nKey, messages.previousNamesOptional.defaults)}
                      fullWidth
                      autoComplete="previousNames"
                      error={errors.previousNames}
                      value={previousNames}
                      onChange={(e) => this.handleChange('previousNames', e.target.value)}
                    />
                  </div>
                ) : null}
                <div className={classes.fieldsRow}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={get(dateFnsLocales, locale) || get(dateFnsLocales, fnsLocales[locale]) || get(dateFnsLocales, fnsLocales['default'])}
                  >
                    <KeyboardDatePicker
                      id="birthday"
                      label={t(messages.dateOfBirth.i18nKey, messages.dateOfBirth.defaults)}
                      format="dd/MM/yyyy"
                      value={birthday ? new Date(birthdayValidFormat) : null}
                      onChange={(selectedDate) => this.handleChange('birthday', moment(selectedDate).format('DD/MM/YYYY'))}
                      KeyboardButtonProps={{'aria-label': 'change date'}}
                      className={classes.dateInput}
                      maxDate={maxDate}
                      minDate={minDate}
                      error={this.state.errors.birthday}
                      helperText={null}
                      required={true}
                      okLabel={t(messages.submit.i18nKey, messages.submit.defaults)}
                      cancelLabel={t(messages.cancel.i18nKey, messages.cancel.defaults)}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={classes.fieldsColumn}>
                <div className={classes.fieldsRow}>
                  <TextField
                    required
                    id="email"
                    name="email"
                    label={t(messages.email.i18nKey, messages.email.defaults)}
                    fullWidth
                    autoComplete="email"
                    value={email}
                    error={errors.email}
                    onChange={(e) => this.handleChange('email', e.target.value)}
                    helperText={
                      <span className={classes.helperTextContainer}>
                        {!showSecondaryEmail && !secondaryEmail1 ? (
                          <button
                            onClick={() => this.getSecondaryEmail()}
                            className={classes.helperButton}
                          >
                            <Trans {...messages.secondaryEmailText} />
                          </button>
                        ) : null}
                        {showPendingConfirmation ? (
                          <span className={classes.helperTextError}>
                            <Trans {...messages.pendingConfirmation} />
                            {emailPendingChange}
                          </span>
                        ) : null}
                      </span>
                    }
                  />
                </div>
                {showSecondaryEmail || secondaryEmail1 ? (
                  <div className={classes.fieldsRow}>
                    <div className={classes.field}>
                      <TextField
                        id="secondaryEmail1"
                        name="secondaryEmail1"
                        label={t(messages.secondaryEmailLabel.i18nKey, messages.secondaryEmailLabel.defaults)}
                        fullWidth
                        autoComplete="secondaryEmail1"
                        value={secondaryEmail1}
                        onChange={(e) => this.handleChange('secondaryEmail1', e.target.value)}
                        error={errors.secondaryEmail1}
                      />
                    </div>
                  </div>
                ) : null}
                <div className={classes.fieldsRow}>
                  <TextField
                    required
                    id="phone"
                    name="phone"
                    label={t(messages.phone.i18nKey, messages.phone.defaults)}
                    fullWidth
                    autoComplete="phone"
                    value={phone}
                    error={errors.phone}
                    onChange={(e) => this.handleChange('phone', e.target.value)}
                    helperText={
                      <span className={classes.helperTextContainer}>
                        {!showSecondaryPhone && !secondaryPhone1 ? (
                          <button className={classes.helperButton} onClick={() => this.getSecondaryPhone()}>
                            <Trans {...messages.secondaryPhoneText} />
                          </button>
                        ) : null}
                      </span>
                    }
                  />
                  {phone && errors.phone && errorsKeys.phone ? (
                    <FormHelperText className={classes.errorMessage}>
                      <Trans {...messages.prefixValidation} />
                    </FormHelperText>
                  ) : null}
                </div>
                {(showSecondaryPhone || secondaryPhone1) ? (
                  <div className={classes.fieldsRow}>
                    <TextField
                      id="secondaryPhone1"
                      name="secondaryPhone1"
                      label={t(messages.secondaryPhoneLabel.i18nKey, messages.secondaryPhoneLabel.defaults)}
                      fullWidth
                      autoComplete="secondaryPhone1"
                      value={secondaryPhone1}
                      onChange={(e) => this.handleChange('secondaryPhone1', e.target.value)}
                      error={errors.secondaryPhone1}
                    />
                    {secondaryPhone1 && errors.secondaryPhone1 && errorsKeys.secondaryPhone1 ? (
                      <FormHelperText className={classes.errorMessage}>
                        <Trans {...messages.prefixValidation} />
                      </FormHelperText>
                    ) : null}
                  </div>
                ) : null}
                <div className={classes.fieldsRow}>
                  {this.isTauromarketsMauritiusCompany ? (
                    <div className={classes.field}>
                      <TextField
                        required
                        id="passportNumber"
                        name="passportNumber"
                        label={t(messages.passportNumber.i18nKey, messages.passportNumber.defaults)}
                        fullWidth
                        autoComplete="passportNumber"
                        value={passportNumber}
                        error={errors.passportNumber}
                        onChange={(e) => this.handleChange('passportNumber', e.target.value)}
                      />
                    </div>
                  ) : null}
                  <div className={classes.field}>
                    <CountriesSelect
                      countryList={nationalitiesList}
                      handleChangeField={this.handleChangeNationality}
                      handleChange={this.handleChange}
                      setStateOuter={this.setStateOuter}
                      errors={errors}
                      value={
                        nationalityChanged
                          ? nationalityFlag
                          : get(find(nationalitiesList,(a)=> nationality && a.keyCountry === nationality), 'label', '')
                      }
                      name="nationality"
                      label={t(messages.nationalityLabel.i18nKey, messages.nationalityLabel.defaults)}
                      returnKey
                      showRequired
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ResidentialAddressFields
            values={pick(form, Object.values(ADDRESS_FIELDS))}
            errors={errors}
            errorsKeys={errorsKeys}
            countryList={countryList}
            geocoderService={this.geocoderService}
            onChange={this.handleChange}
          />

          {this.isTauromarketsMauritiusCompany ? (
            <CorrespondenceAddressFields
              values={pick(form, Object.values(CORRESPONDENCE_ADDRESS_FIELDS_NAMES))}
              errors={errors}
              errorsKeys={errorsKeys}
              countryList={countryList}
              geocoderService={this.geocoderService}
              onChange={this.handleChange}
              isUseResidentialAddressAsCorrespondenceAddress={isUseResidentialAddressAsCorrespondenceAddress}
              onSwitchUseResidentialAddressAsCorrespondenceAddress={this.handleSwitchUseResidentialAddressAsCorrespondenceAddress}
            />
          ) : null}

          <div className={classes.container}>
            <div className={classes.buttonsContainer}>
              <LoadingButton
                fullWidth
                id='loadingButton'
                onClick={() => this.handleSubmit(countryList)}
                disabled={!isFormChanged || loading}
                hideProgressBar={!isFormChanged}
                status={status}
                className={isMobile() || register ? classes.button : ''}
              >
                <Trans {...messages.saveButtonSettings} />
              </LoadingButton>
              {status === 'failure' ? (
                <FormHelperText className={classes.errorMessage}>
                  {submitMessageError}
                </FormHelperText>
              ) : null}
              {register ? (
                <Button
                  className={classes.orangeButton}
                  variant="contained"
                  size="large"
                  onClick={() => this.props.history.push('/')}
                >
                  <Trans {...messages.skipButton}/>
                </Button>
              ) : null}
            </div>
          </div>
        </Grid>
      </React.Fragment>
    )

  }
}

export default compose (
  withStyles(styles),
  withNamespaces(),
  withRouter,
  graphql(CLIENT_PROFILE_QUERY, {
    props: (props) => {
      const viewer = get(props.data, 'viewer')
      const emailPendingChange = get(props.data, 'viewer.emailPendingChange')
      return {
        error:props.data?.error,
        viewerLoading: props.data?.loading,
        viewer: applyFormValues(viewer),
        emailPendingChange,
      }
    }
  }),
  graphql(UPDATE_OWN_DETAILS_MUTATION, {
    name: 'updateDetails',
    options: {
      refetchQueries: [{query: CLIENT_PROFILE_QUERY}],
    }, //@ts-ignore
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
  graphql(UPDATE_OWN_EMAIL_MUTATION, {
    name: 'updateOwnEmail',
    options: {
      refetchQueries: [{query: CLIENT_PROFILE_QUERY}],
    },//@ts-ignore
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: (props) => {
      const economicProfile = get(props.data, 'viewer.globalQuestionnaire')
      return {
        profileLoading: props.data?.loading,
        economicProfile
      }
    }
  })
)(PersonalDetails)
