import Tooltip from '@material-ui/core/Tooltip'
import {ReactComponent as Info} from './assets/info.svg'
import {ReactNode} from 'react'

type InfoTooltipProps = {
  content: NonNullable<ReactNode>;
};

export const InfoTooltip = ({content}: InfoTooltipProps) => (
  <Tooltip arrow id="fee-structure-tooltip" placement="top" title={content}>
    <Info />
  </Tooltip>
)

