/*@ts-check*/
import React, {Component} from 'react'
import Select from 'react-select'
import NoSsr from '@material-ui/core/NoSsr'
import {WithNamespaces, withNamespaces} from 'react-i18next'
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles'
import {find, flowRight as compose} from 'lodash'
import {Account, Client, Maybe} from '../../../graphql/types'
import {Control, Menu, NoOptionsMessage, Placeholder, SingleValue, ValueContainer, CustomOption as Option} from './components'
import {getLabel} from './utils'
import {styles} from './styles'



export type  OptionItem = {
  value?: Maybe<number> | string;
  label: string;
}

export type AccountSearchSelectProps = {
  accounts: Account[],
  clients: Client[],
  disabled: boolean,
  errors: Record<string, unknown>,
  hiddenBalance: boolean,
  label: string,
  locale: string,
  name: string,
  removeRequired: boolean,
  self: unknown,
  value: string | number | boolean,
  handleAccountChange: (propertyName: string, value: string) => void
} & WithStyles<ReturnType<typeof styles>> & WithNamespaces

export class AccountSearchSelect extends Component<AccountSearchSelectProps> {

  findValue(options: OptionItem[], value: string | number | boolean) {
    return find(options,(option) => option.value === value)
  }

  render() {
    const {classes, accounts, clients, label, locale, self, errors, value, hiddenBalance, disabled} = this.props

    const suggestions: (Account | Client)[] = accounts || clients
    const options: OptionItem[] = suggestions.map(suggestion => ({
      value: suggestion.id,
      label: getLabel(suggestion, locale)
    }))
    if (self) options.push({value: 'Self', label:'Self'})

    const components = {
      Control,
      Menu,
      NoOptionsMessage,
      Option,
      Placeholder,
      SingleValue,
      ValueContainer,
    }

    return <div>
      <NoSsr>
        <Select
          accounts={accounts}
          clients={clients}
          locale={locale}
          classes={classes}
          options={options}
          components={components}
          value={this.findValue(options,value) || ''}
          onChange={(e) => {
            this.props.handleAccountChange(this.props.name,e.value)
          }}
          placeholder={(value)?'':`${label}${this.props.removeRequired ? '' : ' *'}`}
          textFieldProps={{
            label: (!value)?' ':`${label}${this.props.removeRequired ? '' : ' *'}`,
            InputLabelProps: {
              shrink: true
            },
            error:errors[this.props.name],
            disabled: disabled
          }}
          hiddenBalance={hiddenBalance}
        />
      </NoSsr>
    </div>
  }
}

export default compose(withStyles(styles, {withTheme: true}), withNamespaces())(AccountSearchSelect)
