import React, {Component} from 'react'
import {map, find, get, isEmpty, flowRight as compose, includes} from 'lodash'
import {graphql} from 'react-apollo'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import {withStyles} from '@material-ui/core/styles'
import {withNamespaces, Trans} from 'react-i18next'
import {getFormattedAmount} from '../../../common/utils'
import messages from '../../../assets/messages'
import LoadingButton from '../../Common/LoadingButton'
import NotificationBar from '../../Common/NotificationBar'
import FormHelperText from '@material-ui/core/FormHelperText'
import AmountInput from '../../Common/AmountInput'
import AccountSelect from '../../Common/AccountSelect'
import PageSubTitle from '../../Common/PageSubTitle'
import {REFILL_DEMO_BALANCE} from '../../../graphql/mutations'
import {ACCOUNTS_QUERY} from '../../../graphql/queries'
import {accountSubtypes, accountTypes, currencies} from '@bdswiss/common-enums'
import ErrorIcon from '@material-ui/icons/ErrorOutlineRounded'
import Amount from '../../Common/Amount'
import {getAccountSubtype, isCentAccount} from '../../../common/utils/accounts'
import classNames from 'classnames'
import SwitchButton from '../../Common/SwitchButton'
import Badge from '@material-ui/core/Badge'
import {config} from '../../../config'
import {validateOnlyNumbers} from '../../../common/utils/validations'
import TextField from '@material-ui/core/TextField'

const style = (theme) => ({
  prefilledButton: {
    backgroundColor: theme.palette.extralightgreyBackground.color,
    textTransform: 'none' as const,
  },
  prefilledButtonSelected: {
    backgroundColor: theme.palette.lightgrey.color,
  },
  bottomSpacing: {
    marginBottom: theme.spacing(3)
  },
  highlightedText: {
    color: theme.palette.secondary.main
  },
  btnText:{
    color: theme.palette.grey.color
  },
  continueButton:{
    marginTop:30
  },
  textLink: {
    color: theme.palette.primary.main,
  },
  errorIcon: {
    color: theme.palette.yellow.color,
    verticalAlign: 'sub',
    marginRight: 5,
    marginBottom: '-2px'
  },
  notificationDiv: {
    padding: 30,
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 0 0',
    },
  },
  badge: {
    width: '100%'
  },
  badgeRoot: {
    width: 'auto',
    borderRadius: 6,
    padding: '5px 15px',
    backgroundColor: theme.palette.cyan.color,
    color: theme.palette.secondary.light,
  },
  greenText: {
    color: theme.palette.green.color,
  },
  invisible: {
    display: 'none'
  },
  pointer: {
    cursor: 'pointer',
  },
  topUp:{
    paddingBottom: 30
  },
  helpText:{
    color: theme.palette.grey[500],
    marginTop: 10
  },
  errorMessage:{
    color:  theme.palette.error.main,
    marginTop: 10
  },
  notificaiton: {
    marginLeft: -12
  },
})
export class AccountAmountSelector extends Component<any,any> {
  static propTypes = {
    accounts: PropTypes.arrayOf(PropTypes.shape({
      id:  PropTypes.number.isRequired,
      remoteId:  PropTypes.string.isRequired,
      balance: PropTypes.number,
      currency: PropTypes.string.isRequired,
      __typename: PropTypes.string.isRequired
    })).isRequired,
    selectedAccountId: PropTypes.number.isRequired,
    onSelectedAccountChanged: PropTypes.func.isRequired,
    onAmountChanged: PropTypes.func.isRequired,
    minimumDeposit: PropTypes.number.isRequired,
    preFilledAmounts: PropTypes.arrayOf(PropTypes.number),
    country: PropTypes.string,
  }

  state = {
    loading: false, status: '', submitMessageError:'', demoAmount: '', demoError: false
  } as any

  topUpDemoBalance(demoAccount) {
    const {t} = this.props
    const {demoAmount} = this.state
    this.setState({status: '', loading: true})
    this.props.resetDemoBalance({variables: {login: demoAccount.remoteId, topUpAmount: parseInt(demoAmount)}})
      .then(() => {
        this.setState({loading: false, status: 'success', submitMessageError: ''})
      })
      .catch((err) => {
        if (err.networkError) {
          this.setState({loading: false, status: 'failure',
            submitMessageError: t(messages.networkError.i18nKey, messages.networkError.defaults),
          })
        } else {
          this.setState({loading: false, status: 'failure',
            submitMessageError: get(err, 'graphQLErrors[0].message') || err.message,
          })
        }

      })
  }

  validateAmount(amount, selectedAccount) {
    const selectedAccountType = find(accountTypes, {key: get(selectedAccount, '__typename')})
    const topUpMax = get(selectedAccountType, 'topUpMax')
    const balance = get(selectedAccount, 'balance')
    const numbersError = validateOnlyNumbers(amount)
    const balanceError = topUpMax && (Number(amount) > topUpMax)
    const amountError = !amount || Number(amount) === balance
    this.setState({demoError: numbersError ? 'NumbersError' : balanceError ? 'BalanceError' : amountError})
    return !amount || numbersError || balanceError
  }

  render() {
    const {t, locale, amount, classes, accounts, onAmountChanged, preFilledAmounts, selectedAccountId,
      minimumDeposit = {amount: 0}, onSelectedAccountChanged, onNextStep, ftdDate,
      minimumDepositDefault, eligibleBonus, bonusAmount, bonus, showMaximumEquityWarning,
      maximumAccountEquity, showSelectAmountEquityWarning, country,
    } = this.props
    const {showAmountEditor, loading, submitMessageError, status, demoError, demoAmount} = this.state
    const {countriesBonusTerms} = config

    const selectedAccount = find(accounts, {id: selectedAccountId})

    const formattedPrefilledAmounts : any = []
    const accountSubtype = get(selectedAccount, 'accountSubtype')
    const selectedSubType = getAccountSubtype(accountSubtype)
    const selectedAccountType : any = find(accountTypes, {key: get(selectedAccount, '__typename')})
    const isReadOnly = get(selectedAccount, 'isReadOnly')

    const allowPartialDeposits = get(accountSubtypes[accountSubtype], 'allowedPartialDeposits')
    const finalMinimumDeposit = ((minimumDeposit > minimumDepositDefault) && allowPartialDeposits && isReadOnly) ? minimumDepositDefault : minimumDeposit
    const invalidAmount  = +amount < +finalMinimumDeposit

    let helperText = invalidAmount ? (
      <Trans
        {...messages.minimumDepositAmount}
        values={{
          amount: getFormattedAmount({
            currency: isCentAccount(selectedAccount) ? currencies.CUD.baseCurrency : selectedAccount.currency,
            amount: isCentAccount(selectedAccount) ? finalMinimumDeposit / (currencies.CUD.baseCurrencyRate ?? 1): finalMinimumDeposit,
            locale
          })
        }}
      />
    ) : ''
    const maximumAmountForEquity = maximumAccountEquity[selectedAccountType.subCategory]
    if (showMaximumEquityWarning && !invalidAmount) {
      helperText = (
        <Trans
          {...messages.equityWarning}
          values={{amount: getFormattedAmount({currency: selectedAccount.currency, amount: maximumAmountForEquity, locale})}}
        />
      )
    }

    if (preFilledAmounts && Array.isArray(preFilledAmounts) && preFilledAmounts.length > 0 && selectedAccount) {
      const {currency} = selectedAccount
      //@ts-ignore
      preFilledAmounts.sort((a, b) => a > b).forEach((p) => {
        const formattedValue = isCentAccount(selectedAccount)
          ? getFormattedAmount({locale, amount: p / (currencies.CUD.baseCurrencyRate ?? 1), currency: currencies.CUD.baseCurrency})
          : getFormattedAmount({locale, amount: p, currency})
        formattedPrefilledAmounts.push({value: p, formattedValue})
      })
      formattedPrefilledAmounts.push({showEditor: true, value: minimumDeposit, formattedValue: t(messages.other.i18nKey, messages.other.defaults)})
    }

    const showPartialMsg = showAmountEditor && allowPartialDeposits && selectedAccount.isReadOnly

    // TODO: Temporary change
    const bonusEligible = false // get(eligibleBonus, 'eligibleBonus') && get(selectedAccount, 'eligibleBonus')

    const bonusPercentage = get(eligibleBonus, 'percentage')
    const bonusMaxAmount = get(eligibleBonus, 'maxAmount')
    const bonusCountry = find(countriesBonusTerms, (bonus) => includes(bonus.countries, country))
    const bonusTerms =  bonusEligible && get((bonusCountry || config.weblinks), 'bonusTerms').replace('{lang}', locale)

    const topUpMax = get(selectedAccountType, 'topUpMax')

    const amountEditor = isCentAccount(selectedAccount) ? amount / (currencies.CUD.baseCurrencyRate ?? 1) : amount
    const currencyEditor = isCentAccount(selectedAccount) ? (currencies.CUD.baseCurrency ?? 'USD'): get(selectedAccount, 'currency')

    return (
      <Grid container spacing={3}>
        {status==='success' &&
        <Grid item xs={12} sm={12} className={classes.notificaiton}>
          <NotificationBar status='success' noMargin={true}>
            <Trans {...messages.resetBalanceSuccess} components={[<Link to={'/'} className={classes.textLink}>Dashboard</Link>]} />
          </NotificationBar>
        </Grid>
        }
        <Grid container>
          <Grid item xs={12} md={6} lg={6} >
            <Grid item xs={12}>
              <PageSubTitle><Trans {...messages.chooseAccount} /> </PageSubTitle>
            </Grid>
            <Grid item xs={12} className={classes.bottomSpacing}>
              <AccountSelect
                label={t(messages.selectAccountToTopUp.i18nKey, messages.selectAccountToTopUp.defaults)}
                accounts={accounts}
                value={selectedAccountId}
                locale={locale}
                id="payments-deposit-account-selector"
                name="payments-deposit-account-selector"
                onChange={(e) => onSelectedAccountChanged(e.target.value)}
              />
            </Grid>
            {bonusEligible && <React.Fragment>
              <Grid item xs={12}>
                <SwitchButton iOSSwitch={true} id={'bonusSwitch'} checked={bonus} value={bonus} onChange={() => this.props.changeSwitch()}
                  label={t(messages.receiveBonus.i18nKey, messages.receiveBonus.defaults)}/>
              </Grid>
              <Typography variant="caption" align='justify'>
                <Trans {...messages.receiveBonusNote} values={{percentage: `${bonusPercentage*100}%`, maxAmount: getFormattedAmount({locale, amount: bonusMaxAmount, currency: selectedAccount.currency})}} />
              </Typography>
              <Typography variant="caption" align='justify'>
                <Trans {...messages.bonusTerms} components={[
                  <a target="_blank" rel="noopener noreferrer" href={bonusTerms || ''} className={classNames(classes.textLink, classes.pointer)}>termsLink</a>
                ]} />
              </Typography>
            </React.Fragment>
            }
            {selectedAccount.isDemo === true && <Grid item xs={12}>
              <PageSubTitle><Trans {...messages.resetBalanceSubTitle} /></PageSubTitle>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="demoAmount"
                  name="demoAmount"
                  label={t(messages.enterAmount.i18nKey, messages.enterAmount.defaults)}
                  error={!!demoError}
                  onChange={(e) => {
                    this.setState({demoAmount: e.target.value, status: ''})
                    this.validateAmount(e.target.value, selectedAccount)
                  }}
                  value={demoAmount}
                />
                {(demoError && (messages[`topUp${demoError}`])) ?
                  <FormHelperText className={classes.errorMessage}><Trans {...messages[`topUp${demoError}`]}
                    values={{amount: getFormattedAmount({currency: selectedAccount.currency, amount: topUpMax, locale})}}/></FormHelperText> :
                  <FormHelperText className={classes.helpText}>
                    <i><Trans {...messages.totalAccountBalanceUpdated} /></i>
                  </FormHelperText>}
              </Grid>
              <Grid item xs={12} sm={12} className={classes.topUp}>
                <LoadingButton
                  id='loadingButton'
                  onClick={() => !this.validateAmount(demoAmount, selectedAccount) && !demoError && this.topUpDemoBalance(selectedAccount)}
                  disabled={loading || status === 'success'}
                  status={status}
                  hideProgressBar={status === 'success'}
                ><Trans {...messages.confirm} />
                </LoadingButton>
                {status==='failure' &&
                <FormHelperText className={classes.errorMessage}>{submitMessageError}</FormHelperText>}
              </Grid>
            </Grid>}
            {showSelectAmountEquityWarning && !showAmountEditor && <NotificationBar status="warning">
              <Trans
                {...messages.selectAmountEquityWarning}
                values={{amount: getFormattedAmount({currency: selectedAccount.currency, amount: maximumAmountForEquity, locale})}}
              />
            </NotificationBar>
            }
            {selectedAccount.isDemo === false && <Grid item xs={12}>
              <PageSubTitle><Trans {...messages.selectDepositAmount} /> </PageSubTitle>
            </Grid>}
            {
              (formattedPrefilledAmounts.length < 1 || showAmountEditor) && selectedAccount.isDemo === false &&
          (<div>
            <Grid item xs={12}>
              <AmountInput
                jumbo
                value={amountEditor}
                locale={locale}
                currency={currencyEditor}
                onChange={(e, newAmount) => {
                  if (isCentAccount(selectedAccount) && +newAmount * (currencies.CUD.baseCurrencyRate ?? 1) === +amount) {
                    return
                  }
                  onAmountChanged(isCentAccount(selectedAccount) ? +newAmount * (currencies.CUD.baseCurrencyRate ?? 1) : newAmount, false, false)
                }}
                id="payments-deposit-amount-input"
                name="payments-deposit-amount-input"
                TextFieldProps={{error: invalidAmount || showMaximumEquityWarning, helperText}}
              />
            </Grid>
            {!invalidAmount && bonusEligible && bonus && <Grid item xs={12}>
              <Typography variant="body1"><Trans {...messages.receiveAmountBonus} components={[<span className={classes.greenText}>amount</span>]}
                values={{amount: getFormattedAmount({locale, amount: bonusAmount, currency: selectedAccount.currency})}}/> </Typography></Grid>}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => onNextStep()}
                  disabled={!selectedAccountId || invalidAmount}
                  className={classes.continueButton}
                >
                  <Trans {...messages.continue} />
                </Button>
              </Grid>
            </Grid>
          </div>)
            }
            {
              (!showAmountEditor && formattedPrefilledAmounts.length > 0) && selectedAccount.isDemo === false &&
              <Grid container item xs={12} spacing={3}>
                {
                  map(formattedPrefilledAmounts as any, (p, i) => {
                    const prefilledButtonClasses = [classes.prefilledButton]
                    const bonusAmount = bonus && p.value * bonusPercentage
                    const finalBonusAmount = bonusAmount < get(eligibleBonus, 'maxAmount') ? bonusAmount
                      : eligibleBonus.maxAmount
                    let badgeContent = `+ ${getFormattedAmount({locale, amount: finalBonusAmount, currency: selectedAccount.currency})}`
                    let badgeClasses = classNames(classes.badgeRoot, (!bonusEligible || !bonus || p.showEditor) && classes.invisible)

                    if (isCentAccount(selectedAccount)) {
                      badgeContent = `${getFormattedAmount({locale, amount: p.value, currency: selectedAccount.currency})}`
                      badgeClasses = classNames(classes.badgeRoot, p.showEditor ? classes.invisible : '')
                    }

                    return (
                      <Grid item xs={6} md={4} key={`pre-selected-amount-${p.value}-${i}`}>
                        <Badge badgeContent={badgeContent} className={classes.badge}
                          classes={{badge: badgeClasses}}>
                          <Button
                            fullWidth
                            variant="contained"
                            className={classNames(prefilledButtonClasses)}
                            onClick={() => {
                              if (p.showEditor) {
                                this.setState({showAmountEditor: true})
                                onAmountChanged(p.value, false, false)
                              } else {
                                onAmountChanged(p.value, true, true)
                              }
                            }}
                          >
                            <Typography variant="body2" className={classes.btnText}>{p.formattedValue}</Typography>
                          </Button>
                        </Badge>
                      </Grid>
                    )
                  })
                }
              </Grid>
            }
            {isEmpty(ftdDate) && !selectedAccount.isDemo && <Grid item xs={12}>
              <Typography variant="caption" align='justify'>
                <Trans {...messages.minimumDepositFootnote}
                  values={{amount: getFormattedAmount({currency: 'USD', amount: minimumDepositDefault, locale})}}/>
              </Typography>
            </Grid>}
          </Grid>
          {showPartialMsg && <Grid item xs={12} md={6} lg={6} className={classes.notificationDiv}>
            <NotificationBar status="warning">
              <ErrorIcon className={classes.errorIcon} />
              <Trans {...messages.pleaseNote} /><br />
              <Trans {...messages.partialDepositNote} values={{type: selectedAccount.accountSubtype && get(selectedSubType, 'label')}}
                components={[<Amount key={'amount'} value={minimumDeposit} currency={selectedAccount.currency} locale='en-US' />]}/>
            </NotificationBar>
          </Grid>}
        </Grid>
      </Grid>
    )
  }
}


export default compose(
  withStyles(style, {withTheme: true}),
  withNamespaces(),
  graphql(REFILL_DEMO_BALANCE, {
    name: 'resetDemoBalance',
    options: {
      refetchQueries: [{query: ACCOUNTS_QUERY}],
    }
  })
)(AccountAmountSelector)
