import styled from '@emotion/styled'
import {useRef} from 'react'

const StyledDigitInput = styled.input`
  border-radius: 10px;
  border: 2px solid #007AFF;
  color: #007AFF;
  font-size: 60px;
  height: 4rem;
  line-height: 72px;
  padding: 0;
  text-align: center;
  width: 4rem;
`

type DigitInputProps = {
  onChange: (newValue: string) => void;
  value: string;
}

export const DigitInput = ({onChange, value}: DigitInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/\D/g, '')
    if (newValue.length > 1) return
    onChange(newValue)
    if (newValue.length === 1 && inputRef.current && inputRef.current.nextElementSibling) {
      (inputRef.current.nextElementSibling as HTMLElement).focus()
    }
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && value.length === 0) {
      onChange('Backspace')
      if (inputRef.current && inputRef.current.previousElementSibling) {
        (inputRef.current.previousElementSibling as HTMLElement).focus()
      }
    }
  }

  return (
    <StyledDigitInput
      onChange={handleChange}
      onKeyUp={handleKeyUp}
      ref={inputRef}
      type="number"
      value={value}
    />
  )}

