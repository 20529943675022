import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import {get, toUpper} from 'lodash'
import {Trans, WithNamespaces, withNamespaces} from 'react-i18next'
import messages from '../../../../../assets/messages'
import {withStyles} from '@material-ui/core'
import {ClassNameMap} from '@material-ui/core/styles/withStyles'
import {styles} from './styles'
import {MT5Badge} from './components/MT5Badge'
import {MT4Badge} from './components/MT4Badge'

type AccountType = {
  accountType: string;
  accountSubtype: string
  available: boolean;
  baseProduct: string;
  category: string;
  icon: string;
  isDemo: boolean;
  label: string;
  maxAllowed: number;
  showDemoLabel: boolean;
  subCategory: string;
}

type AccountTypeItemProps = {
  account: AccountType;
  classes?: Partial<ClassNameMap<keyof ReturnType<typeof styles>>>;
  key: React.Key;
  onSelectAccount: (accountType: string, accountSubtype: string) => void;
} & WithNamespaces

const _AccountTypeItem = ({account, classes, onSelectAccount, t}: AccountTypeItemProps) => <Card classes={{root: classes?.cardRoot}} className={classes?.pointer}
  onClick={()=> onSelectAccount(account.accountType,account.accountSubtype)}>
  <Grid container>
    <Grid item xs={2} className={classes?.badgeDiv}>
      <div className={classNames(classes?.typeBadge,classes?.[account.subCategory] || classes?.[account.accountSubtype]
  || classes?.[account.category])}>{account.icon}</div>
    </Grid>
    <Grid item xs={10}>
      <Typography variant='subtitle1' className={classes?.label}>
        {account.showDemoLabel ? `${toUpper(t(messages.demo.i18nKey))} ` : ''}{account.label}
        {<Typography variant='caption' className={classes?.availableAccounts}>
          {account.available && account.maxAllowed > 1 && <Trans {...messages.availableAccounts} values={{available: account.available, maxAllowed: account.maxAllowed}}/>}
          {account.category === 'forexMt5' && <MT5Badge>
            <Trans {...messages.mt5Account} />
          </MT5Badge>}
          {account.category === 'forexMt4' && <MT4Badge>
            <Trans {...messages.mt4Account} />
          </MT4Badge>}
        </Typography>}
      </Typography>
      <Typography variant='caption'>
        {(account.isDemo) && get(messages, `${account.category}${account.accountSubtype}DemoDesc`) ? t(messages[`${account.category}${account.accountSubtype}DemoDesc`].i18nKey)
          : get(messages, `${account.category}${account.accountSubtype}Desc`) ? t(messages[`${account.category}${account.accountSubtype}Desc`].i18nKey)
            :get(messages, `${account.baseProduct}Desc`)
              ? t(messages[`${account.baseProduct}Desc`].i18nKey, messages[`${account.baseProduct}Desc`].defaults)
              : t(messages[`${account.category}${account.subCategory}Desc`].i18nKey, messages[`${account.category}${account.subCategory}Desc`].defaults)
        }
      </Typography>
    </Grid>
  </Grid>
</Card>

export const AccountTypeItem = withNamespaces()<AccountTypeItemProps>(
  withStyles(styles)(
    _AccountTypeItem
  )
)
