import {PammTauroMarketsAccount} from '../../../../../graphql/types'
import {Action, ActionType} from './actions'

export type State = {
  account: PammTauroMarketsAccount;
  showMinimumDepositRequiredPopup: boolean;
  showPendingPopup: boolean;
}

export const reducer = (state: State, action: Action) => {
  const account = state.account
  const accountUnconnected = account.unconnected
  const accountBalance = account.balance ?? 0
  const isDepositNeeded = accountUnconnected && account.minimumDeposit && accountBalance < account.minimumDeposit
  const isUnconnected = account.unconnected && account.minimumDeposit && accountBalance >= account.minimumDeposit
  const isUnapproved = !isUnconnected && account.balance === 0
  switch (action.type) {
    case ActionType.Click: {
      if (isDepositNeeded) {
        return {
          ...state,
          showMinimumDepositRequiredPopup: true,
        }
      }
      if (isUnconnected || isUnapproved) {
        return {
          ...state,
          showPendingPopup: true,
        }
      }
      return state
    }
    case ActionType.CloseModal: {
      return {
        ...state,
        showMinimumDepositRequiredPopup: false,
        showPendingPopup: false,
      }
    }
    default: {
      return state
    }
  }
}
