import {ICompanyEnum} from '@bdswiss/common-enums'
import {useReducer} from 'react'
import AccountCard from '../../../AccountCard'
import {AccountCardProps} from '../types'
import {PammManagerUnapprovedPopup} from './components/Popups/PammManagerUnapprovedPopup'
import {reducer} from './reducer'
import {ActionType} from './actions'
import {initialState} from './initialState'
import {useHistory} from 'react-router-dom'
import {isApprovedAccount} from '../../../../../common/utils/accounts'

type PammTauroMarketsManagerAccountCardProps = AccountCardProps & {
  company: ICompanyEnum
}

export const PammTauroMarketsManagerAccountCard: React.FC<PammTauroMarketsManagerAccountCardProps> = ({account, company}) => {
  const [{showPammManagerUnapprovedPopup}, dispatch] = useReducer(reducer, {...initialState, account})
  const history = useHistory()

  const handleClick = () => {
    if (account != null && isApprovedAccount(account)) {
      history.push(`/accounts/pammManagerSettings/${account.id}`)
    } else {
      dispatch({type: ActionType.Click})
    }
  }

  const closeModals = () => {
    dispatch({type: ActionType.CloseModal})
  }

  return (
    <div
      data-testid='pamm-tauro-markets-manager-account-card'
    >
      <AccountCard
        account={account}
        hideCurrency
        onClick={handleClick}
      />
      <PammManagerUnapprovedPopup
        company={company}
        onAgree={closeModals}
        onClose={closeModals}
        open={showPammManagerUnapprovedPopup}
      />
    </div>
  )

}
