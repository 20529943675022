import styled from '@emotion/styled'

export const ServerNameMt4 = styled.div`
  align-items: center;
  background: #EBF7FD;
  border-radius: 4px;
  color: #999999;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 0px 9px;
  width: 164px;
`
