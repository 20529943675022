import React, {Component} from 'react'
import classNames from 'classnames'
import {get} from 'lodash'
import LockOutlined from '@material-ui/icons/LockOutlined'
import Jdenticon from 'react-jdenticon'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import {withNamespaces, Trans} from 'react-i18next'

import {UnableCopyTradeAlertDialog} from './components/UnableCopyTradeAlertDialog'
import {AccountTypeTooltip} from './components/AccountTypeTooltip'
import AppContext from '../../../Common/contexts/AppContext'
import PropTypes from 'prop-types'
import {Paper, Typography, Button} from '@material-ui/core'
import messages from '../../../../assets/messages'
import Amount from '../../../Common/Amount'
import {isMobile} from '../../../../common/utils'
import {getAccountSubtype} from '../../../../common/utils/accounts'

const fontRatio = isMobile() ? 0.5 : 0.7
const styles = theme => ({
  amountGreen: {
    color: theme.palette.green.color,
  },
  amountRed: {
    color: theme.palette.red.color,
  },
  amount: {
    marginTop: isMobile() ? 2 : 5,
    display: 'block',
  },
  amountCurrency: {
    fontSize: Math.floor(24 * fontRatio),
    fontWeight: 300,
    padding: '0 3px',
  },
  amountValue: {
    fontSize: Math.floor(36 * fontRatio),
    fontWeight: 300,
  },
  amountFraction: {
    fontSize: Math.floor(24 * fontRatio),
    fontWeight: 300,
  },
  financialInfoItem: {
    marginTop: 0,
    textAlign: 'left' as const,
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  copyTradingBtn: {
    marginTop:  isMobile() ? '1rem' : 0,
    marginLeft: 10,
    padding: 0,
    textAlign: 'center' as const,
  },
  strategiesPaper: {
    padding: 10,
  },
  strategyCardItem:{
    marginTop: isMobile() ? 2 : 5,
    display: 'block',
    fontSize: Math.floor(36 * fontRatio),
    fontWeight: 300,
  },
  strategyCardItemGreen:{
    color: theme.palette.green.color,
  },
  strategyCardItemRed:{
    color: theme.palette.red.color,
  },
  copying: {
    color: theme.palette.green.color,
    fontWeight: 400,
  },
  smallerFont:{
    fontSize: 16
  },
  loss: {
    color: theme.palette.red.color,
  },
  profit: {
    color: theme.palette.green.color,
  },
  textBlue: {
    color: theme.palette.primary.main
  },
  textCaption: {
    color: theme.palette.grey.color
  },
  main: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 20
    },
  },
  passwordProtected: {
    paddingTop: 5,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0
    },
  },
  lockIcon: {
    fontSize: 16,
    marginRight: 5,
    marginBottom: -3
  },
  strategyIcon: {
    borderRadius: '50%',
    verticalAlign: 'middle',
    overflow: 'hidden',
    width: 56,
    height: 56,
    '& img': {
      width: '100%',
    }
  },
  infoItem: {
    color: '#999'
  }
})

class StrategyCard extends Component<any,any> {
  static propTypes = {
    strategy: PropTypes.object,
    account: PropTypes.object,
    onClick: PropTypes.func,
    onStop: PropTypes.func,
  }
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      status: '',
      loading: false,
      isUnableCopyTradeAlertDialogOpen: false,
    }
  }

  jdenticon (accountName, customBackground) {
    const {classes} = this.props
    return <div className={classes.strategyIcon}>
      <Jdenticon size="56" value={accountName}/>
    </div>
  }

  get isWrongAccountSubtypeToCopyTrade() {
    const {account, strategy} = this.props
    const accountSubtype = account.accountSubtype
    const strategyAccountSubtype = strategy.accountSubType

    return accountSubtype !== strategyAccountSubtype
  }

  handleUnableCopyTradeAlertDialogOpen = () => {
    this.setState({isUnableCopyTradeAlertDialogOpen: true})
  }

  handleUnableCopyTradeAlertDialogClose = () => {
    this.setState({isUnableCopyTradeAlertDialogOpen: false})
  }

  handleButtonClick = () => {
    const {onClick, strategy} = this.props

    if (this.isWrongAccountSubtypeToCopyTrade) {
      return this.handleUnableCopyTradeAlertDialogOpen()
    }

    if (onClick) return onClick(strategy)
  }

  render() {
    const {classes, strategy, account, onStop, hasOpenPositions} = this.props
    const copyTradingDetails = account.copyTrading

    const disableOtherBtns = copyTradingDetails && (copyTradingDetails.isActive ||
      (!copyTradingDetails.isActive && copyTradingDetails.startRequestedDate && !copyTradingDetails.stopCopyingDate))

    const activeCopyingStrategy = copyTradingDetails && (strategy.login === copyTradingDetails.accountCopying.remoteId)
      && copyTradingDetails.isActive

    const enableStopBtn = activeCopyingStrategy

    const stopAlreadyRequested = copyTradingDetails && (strategy.login === copyTradingDetails.accountCopying.remoteId)
      && copyTradingDetails.stopRequestedDate && !copyTradingDetails.stopCopyingDate

    const stopBtnAction = activeCopyingStrategy && onStop ? () => onStop(copyTradingDetails) : ()=>{}

    const disableAction = !enableStopBtn && (account.isReadOnly || (hasOpenPositions && strategy.performanceFee>0))
    const disabledButton = !!(stopAlreadyRequested || disableOtherBtns|| disableAction)
    const renderButton = <div>
      <Button
        id='loadingButton'
        onClick={this.handleButtonClick}
        autoFocus
        color="primary"
        variant={!isMobile() ? 'contained' : 'text'}
        size="medium"
        disabled={disabledButton}
      >
        <Trans {...messages.startCopying}/>
      </Button>
      {!disabledButton && get(strategy, 'protectedPassword') && <Typography variant='caption' className={classes.passwordProtected}>
        <LockOutlined className={classes.lockIcon}/>
        <Trans {...messages.passwordProtected} />
      </Typography>}
    </div>
    const strategyAccountSubtype = get(getAccountSubtype(strategy.accountSubType), 'label')
    return (
      <>
        <UnableCopyTradeAlertDialog
          strategyAccountSubtype={strategyAccountSubtype}
          isOpen={this.state.isUnableCopyTradeAlertDialogOpen}
          onClose={this.handleUnableCopyTradeAlertDialogClose}
        />
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.strategiesPaper}>
              <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                <Grid item className={classNames(classes.financialInfoItem, classes.main)} xs={isMobile() ? 6 : 'auto'}>
                  {strategy.icon
                    ? <div className={classes.strategyIcon}><img src={strategy.icon} alt=""/></div>
                    : this.jdenticon(get(strategy, 'accountName', ''), get(strategy, 'login', ''))}
                </Grid>
                <Grid item className={classNames(classes.financialInfoItem, classes.main)} xs={isMobile() ? 6 : 'auto'}>
                  <Grid container>
                    <Typography variant='body1' className={classes.textBlue}>
                      {strategy.accountName}
                    </Typography>
                    <AccountTypeTooltip accountSubtype={strategyAccountSubtype} />
                  </Grid>
                  <Typography variant='caption' className={classes.textCaption}>
                    {strategy.performanceFee}% <Trans {...messages.performanceFee} />
                  </Typography>
                  <Typography variant='caption' className={classes.textCaption}>
                    <Trans {...messages.activeSince} /> {strategy.activeSince}
                  </Typography>
                </Grid>
                <Grid item className={classes.financialInfoItem} xs={isMobile() ? 6 : 'auto'}>
                  <Typography variant='caption'><Trans {...messages.totalTrades} /></Typography>
                  <Typography variant='body1' className={classes.strategyCardItem}>{strategy.numTrades}</Typography>
                </Grid>
                <Grid item className={classes.financialInfoItem} xs={isMobile() ? 6 : 'auto'}>
                  <Typography variant='caption'><Trans {...messages.totalWinningTradesCount} /></Typography>
                  <Typography variant='body1' className={classNames(classes.strategyCardItem, classes.strategyCardItemGreen)}>{strategy.winningTrades}</Typography>
                </Grid>
                <Grid item className={classes.financialInfoItem} xs={isMobile() ? 6 : 'auto'}>
                  <Typography variant='caption'><Trans {...messages.totalLosingTradesCount} /></Typography>
                  <Typography variant='body1' className={classNames(classes.strategyCardItem, classes.strategyCardItemRed)}>{strategy.losingTrades}</Typography>
                </Grid>
                <Grid item className={classes.financialInfoItem} xs={isMobile() ? 6 : 'auto'}>
                  <Typography variant='caption'><Trans {...messages.closedProfit} /></Typography>
                  <Amount
                    value={strategy.totalProfit} currency={strategy.currency} locale='en-US' classes={{
                      root: strategy.totalProfit < 0 ? classes.loss : classes.profit,
                      currency: classes.amountCurrency,
                      value: classes.amountValue,
                      fraction: classes.amountFraction
                    }} />
                </Grid>
                <Grid item className={classes.financialInfoItem} xs={isMobile() ? 6 : 'auto'}>
                  <Typography variant='caption'><Trans {...messages.roi} /></Typography>
                  <Typography variant='body1' className={classes.strategyCardItem}>{strategy.roi}<span className={classes.smallerFont}>%</span></Typography>
                </Grid>
                {strategy.description && <Grid item className={classes.infoItem} xs={isMobile() ? 6 : 'auto'}>
                  <Tooltip title={strategy.description} arrow>
                    <InfoIcon />
                  </Tooltip>
                </Grid>}
                {!enableStopBtn && <Grid item className={classes.copyTradingBtn} xs={isMobile() ? 12 : 'auto'}>
                  {stopAlreadyRequested && <Typography variant='caption'><Trans {...messages.pending} /></Typography>}
                  {renderButton}
                </Grid>}
                {enableStopBtn && <Grid item className={classes.copyTradingBtn} xs={isMobile() ? 12 : 'auto'}>
                  <Typography variant='caption' className={classes.copying}><Trans {...messages.copying} /></Typography>
                  <Button
                    id='loadingButton'
                    className={!isMobile() ? classes.stopTradingBtn : ''}
                    onClick={stopBtnAction}
                    autoFocus
                    color={!isMobile() ? 'secondary' : 'primary'}
                    variant={!isMobile() ? 'contained' : 'text'}
                    size="medium"
                    disabled={!!(stopAlreadyRequested) || disableAction}
                  >
                    {enableStopBtn ? <Trans {...messages.stopCopying}/> : <Trans {...messages.startCopying}/>}
                  </Button>
                  {stopAlreadyRequested && <Typography variant='caption'><Trans {...messages.pending} /></Typography>}
                </Grid>}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    )
  }
}


export default withStyles(styles, {withTheme: true})(withNamespaces()(StrategyCard))
