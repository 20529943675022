import {createTheme} from '@material-ui/core/styles'
import deepmerge from 'deepmerge'
import {isMobile} from '../common/utils'

const baseThemeProperties = {
  typography: {
    useNextVariants: true,
  },
  mainBodyWidth:'1400px',
  sidebarWidth: '300px',
  tabRootStyle: {
    marginBottom: 30,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  emptyListsIcon: {
    maxWidth: 280,
    minWidth:220,
    width:'85%'
  },
  palette: {
    lightdark: {
      color: '#062730',
    },
    extralightblue: {
      color: '#ECF3F5',
    },
    lightgold: {
      color: '#CFB484',
    },
    lydianGrey: {
      color: '#B0B5C0',
    },
    lydianLightGray: {
      color: '#D5D6D7',
    },
    lydianDark: {
      color: '#121927',
    },
  }
}

const bdSwiss = createTheme({
  ...baseThemeProperties,
  boxShadowStyle:'0px 3px 5px -3px #ccc, 0px 6px 10px 0px #f1f1f1, 0px 1px 18px 0px #eee',
  palette: {
    ...baseThemeProperties.palette,
    type: 'light',
    boxShadowStyle: 'unset',
    primary: {
      main: '#007AFF',
      dark: '#0055B2',
      light: '#3394FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#999999',
      dark: '#000000',
      light:'#FFFFFF',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#EF2D34',
      dark: '#ED1C24',
      light: '#FFD4D6'
    },
    background: {
      default: '#fff',
    },
    green: {
      color:'#1DCD9A',
      textColor: '#FFFFFF'
    },
    lightgreen:{
      color: '#E5F9F3',
      textColor:'#27b28a'
    },
    lightblue: {
      color:'#E8F7FE',
      textColor:'#297dd8'
    },
    yellow: {
      color:'#FF9F00'
    },
    lightyellow: {
      color:'#FF9F0024'
    },
    red: {
      color:'#ED1C24',
      textColor: '#FFFFFF'
    },
    lightred:{
      color:'#FFEDEE',
    },
    grey: {
      color:'rgba(0,0,0,0.75)'
    },
    lightgrey: {
      color:'#CCC'
    },
    extralightgrey: {
      color:'#fafafa'
    },
    extralightgreyBackground: {
      color:'#FFFFFF'
    },
    cardBackground: {
      color:'#FFFFFF'
    },
    black: {
      color:'rgba(0,0,0)'
    },
    purple: {
      color: '#6F00FF'
    },
    lightpurple: {
      color: '#EEE0FF'
    },
    cyan: {
      color: '#00D3FF'
    },
    lightcyan: {
      color: '#E0FAFF'
    },
    lightorange: {
      color: '#FFF6E6'
    },
    lightgreyBackgroundSolid:{
      color:'#FFFFFF'
    },
    gold: {
      color: '#E6C200'
    },
    harlequin: {
      color: '#3cd10b'
    },
    lightharlequin: {
      color: '#e8ffe0'
    },
    premium: {
      color: '#474644',
    },
    lightpremium: {
      color: '#DEDDDB',
    },
    lightdark: {
      color: '#062730',
    },
    extralightblue: {
      color: '#ECF3F5',
    },
  },
})

const lydian = createTheme({
  ...bdSwiss,
  lydianGradient: 'linear-gradient(180deg, #062A33 0%, #031417 100%)linear-gradient(180deg, #062A33 0%, #031417 100%)',
})
const tauroMarkets = createTheme({
  ...bdSwiss,
})

const swissMarkets = createTheme({
  ...baseThemeProperties,
  boxShadowStyle:'0px 3px 5px -3px #ccc, 0px 6px 10px 0px #f1f1f1, 0px 1px 18px 0px #eee',
  palette: {
    ...baseThemeProperties.palette,
    type: 'light',
    cardBackground: {color: 'unset'},
    boxShadowStyle: 'unset',
    primary: {
      main: '#007AFF',
      dark: '#0055B2',
      light: '#3394FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#999999',
      dark: '#000000',
      light:'#FFFFFF'
    },
    error: {
      main: '#EE242C',
      dark: '#ED1C24',
      light: '#FFD4D6'
    },
    background: {
      default: '#fff',
    },
    green: {
      color:'#1DCD9A',
      textColor: '#FFFFFF'
    },
    lightgreen:{
      color: '#E5F9F3',
      textColor:'#27b28a'
    },
    lightblue: {
      color:'#E8F7FE',
      textColor:'#297dd8'
    },
    yellow: {
      color:'#FF9F00'
    },
    lightyellow: {
      color:'#FF9F0024'
    },
    red: {
      color:'#EE242C',
      textColor: '#FFFFFF'
    },
    lightred:{
      color:'#FFEDEE'
    },
    grey: {
      color:'rgba(0,0,0,0.75)'
    },
    lightgrey: {
      color:'#CCC'
    },
    extralightgrey: {
      color:'#fafafa'
    },
    extralightgreyBackground: {
      color:'#FFFFFF'
    },
    black: {
      color:'rgba(0,0,0)'
    },
    purple: {
      color: '#6F00FF'
    },
    lightpurple: {
      color: '#EEE0FF'
    },
    cyan: {
      color: '#00D3FF'
    },
    lightcyan: {
      color: '#E0FAFF'
    },
    lightorange: {
      color: '#FFF6E6'
    },
    lightgreyBackgroundSolid:{
      color:'#FFFFFF'
    },
    gold: {
      color: '#E6C200'
    },
    harlequin: {
      color: '#3cd10b'
    },
    lightharlequin: {
      color: '#e8ffe0'
    },
    premium: {
      color: '#474644',
    },
    lightpremium:{
      color: '#DEDDDB',
    },
  },
})

const accuracyAccounts = createTheme({
  ...baseThemeProperties,
  boxShadowStyle: 'unset',
  palette: {
    ...baseThemeProperties.palette,
    cardBackground: {color: 'unset'},
    boxShadowStyle: 'unset',
    primary: {
      main: '#007AFF',
      dark: '#0055B2',
      light: '#3394FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#999999',
      dark: '#000000',
      light:'#FFFFFF'
    },
    error: {
      main: '#EE242C',
      dark: '#ED1C24',
      light: '#FFD4D6'
    },
    background: {
      default: '#fff',
    },
    green: {
      color:'#1DCD9A',
      textColor: '#FFFFFF'
    },
    lightgreen:{
      color: '#E5F9F3',
      textColor:'#27b28a'
    },
    lightblue: {
      color:'#E8F7FE',
      textColor:'#297dd8'
    },
    yellow: {
      color:'#FF9F00'
    },
    lightyellow: {
      color:'#FF9F0024'
    },
    red: {
      color:'#EE242C',
      textColor: '#FFFFFF'
    },
    lightred:{
      color:'#FFEDEE'
    },
    grey: {
      color:'rgba(0,0,0,0.75)'
    },
    lightgrey: {
      color:'#CCC'
    },
    extralightgrey: {
      color:'#fafafa'
    },
    extralightgreyBackground: {
      color:'#FFFFFF'
    },
    black: {
      color:'rgba(0,0,0)'
    },
    purple: {
      color: '#6F00FF'
    },
    lightpurple: {
      color: '#EEE0FF'
    },
    cyan: {
      color: '#00D3FF'
    },
    lightcyan: {
      color: '#E0FAFF'
    },
    lightorange: {
      color: '#FFF6E6'
    },
    lightgreyBackgroundSolid:{
      color:'#FFFFF'
    },
    gold: {
      color: '#E6C200'
    },
    harlequin: {
      color: '#3cd10b'
    },
    lightharlequin: {
      color: '#e8ffe0'
    },
    premium: {
      color: '#474644',
    },
    lightpremium:{
      color: '#DEDDDB',
    },
  },
})

const regalx = createTheme({
  ...baseThemeProperties,
  boxShadowStyle:'0px 3px 5px -3px #ccc, 0px 6px 10px 0px #f1f1f1, 0px 1px 18px 0px #eee',
  palette: {
    ...baseThemeProperties.palette,
    type: 'light',
    cardBackground: {color: 'unset'},
    boxShadowStyle: 'unset',
    primary: {
      main: '#007AFF',
      dark: '#0055B2',
      light: '#3394FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#999999',
      dark: '#000000',
      light:'#FFFFFF',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#EF2D34',
      dark: '#ED1C24',
      light: '#FFD4D6'
    },
    background: {
      default: '#fff',
    },
    green: {
      color:'#1DCD9A',
      textColor: '#FFFFFF'
    },
    lightgreen:{
      color: '#E5F9F3',
      textColor:'#27b28a'
    },
    lightblue: {
      color:'#E8F7FE',
      textColor:'#297dd8'
    },
    yellow: {
      color:'#FF9F00'
    },
    lightyellow: {
      color:'#FF9F0024'
    },
    red: {
      color:'#ED1C24',
      textColor: '#FFFFFF'
    },
    lightred:{
      color:'#FFEDEE',
    },
    grey: {
      color:'rgba(0,0,0,0.75)'
    },
    lightgrey: {
      color:'#CCC'
    },
    extralightgrey: {
      color:'#fafafa'
    },
    extralightgreyBackground: {
      color:'#FFFFFF'
    },
    black: {
      color:'rgba(0,0,0)'
    },
    purple: {
      color: '#6F00FF'
    },
    lightpurple: {
      color: '#EEE0FF'
    },
    cyan: {
      color: '#00D3FF'
    },
    lightcyan: {
      color: '#E0FAFF'
    },
    lightorange: {
      color: '#FFF6E6'
    },
    lightgreyBackgroundSolid:{
      color:'#FFFFFF'
    },
    gold: {
      color: '#E6C200'
    },
    harlequin: {
      color: '#3cd10b'
    },
    lightharlequin: {
      color: '#e8ffe0'
    },
    premium: {
      color: '#474644',
    },
    lightpremium:{
      color: '#DEDDDB',
    },
  },
})

const bdsTrading = createTheme({
  ...baseThemeProperties,
  boxShadowStyle:'0px 3px 5px -3px #ccc, 0px 6px 10px 0px #f1f1f1, 0px 1px 18px 0px #eee',
  palette: {
    ...baseThemeProperties.palette,
    type: 'light',
    cardBackground: {color: 'unset'},
    boxShadowStyle: 'unset',
    primary: {
      main: '#007AFF',
      dark: '#0055B2',
      light: '#3394FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#999999',
      dark: '#000000',
      light:'#FFFFFF',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#EF2D34',
      dark: '#ED1C24',
      light: '#FFD4D6'
    },
    background: {
      default: '#fff',
    },
    green: {
      color:'#1DCD9A',
      textColor: '#FFFFFF'
    },
    lightgreen:{
      color: '#E5F9F3',
      textColor:'#27b28a'
    },
    lightblue: {
      color:'#E8F7FE',
      textColor:'#297dd8'
    },
    yellow: {
      color:'#FF9F00'
    },
    lightyellow: {
      color:'#FF9F0024'
    },
    red: {
      color:'#ED1C24',
      textColor: '#FFFFFF'
    },
    lightred:{
      color:'#FFEDEE',
    },
    grey: {
      color:'rgba(0,0,0,0.75)'
    },
    lightgrey: {
      color:'#CCC'
    },
    extralightgrey: {
      color:'#fafafa'
    },
    extralightgreyBackground: {
      color:'#FFFFFF'
    },
    black: {
      color:'rgba(0,0,0)'
    },
    purple: {
      color: '#6F00FF'
    },
    lightpurple: {
      color: '#EEE0FF'
    },
    cyan: {
      color: '#00D3FF'
    },
    lightcyan: {
      color: '#E0FAFF'
    },
    lightorange: {
      color: '#FFF6E6'
    },
    lightgreyBackgroundSolid:{
      color:'#FFFFFF'
    },
    gold: {
      color: '#E6C200'
    },
    harlequin: {
      color: '#3cd10b'
    },
    lightharlequin: {
      color: '#e8ffe0'
    },
    premium: {
      color: '#474644',
    },
    lightpremium:{
      color: '#DEDDDB',
    },
  },
})

const bdSwissDark = createTheme({
  ...baseThemeProperties,
  boxShadowStyle: '0 1px 4px 0 rgba(0,0,0,0.04), 0 4px 16px 0 rgba(0,0,0,0.08)',
  palette: {
    ...baseThemeProperties.palette,
    boxShadowStyle: 'unset',
    type: 'dark',
    primary: {
      main: '#419CFF',
      dark: '#000000',
      light: '#3394FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#999999',
      dark: '#FFFFFF',
      light:'#FFFFFF'
    },
    error: {
      main: '#EF2D34',
      dark: '#FF7C81',
      light: '#FFD4D6'
    },
    background: {
      default: '#0C0C0C',
      paper: '#0C0C0C',
    },
    green: {
      color: '#57EBC0',
      textColor: '#FFFFFF'
    },
    lightgreen:{
      color:'#00614580',
      textColor:'#57EBC0'
    },
    lightblue: {
      color:'#00499980',
      textColor:'#FFFFFF'
    },
    yellow: {
      color:'#FF9F00'
    },
    lightyellow: {
      color:'#FF9F0024'
    },
    red: {
      color:'#FF7C81',
      textColor: '#FFFFFF'
    },
    lightred:{
      color:'#87101550',
    },
    grey: {
      color:'rgba(255,255,255,0.75)'
    },
    lightgrey: {
      color:'rgba(255,255,255,0.12)'
    },
    extralightgrey: {
      color:'rgba(255,255,255,0.05)'
    },
    extralightgreyBackground: {
      color:'rgba(255,255,255,0.12)'
    },
    cardBackground: {
      color:'rgba(255,255,255,0.05)'
    },
    black: {
      color:'#FFFFFF'
    },
    purple: {
      color: '#E65CE6'
    },
    lightpurple: {
      color: '#7A1F7A30'
    },
    cyan: {
      color: '#00D3FF'
    },
    lightcyan: {
      color: '#00CCFF24'
    },
    lightorange: {
      color: '#D7890824'
    },
    lightgreyBackgroundSolid: {
      color:'#333333'
    },
    gold: {
      color: '#E6C200'
    },
    harlequin: {
      color: '#3cd10b'
    },
    lightharlequin: {
      color: '#e8ffe030'
    },
    premium: {
      color: '#474644',
    },
    lightpremium:{
      color: '#DEDDDB',
    },
  },
})

const tauroMarketsDark = createTheme({
  ...bdSwissDark,
})

const swissMarketsDark = createTheme({
  ...baseThemeProperties,
  boxShadowStyle: '0 1px 4px 0 rgba(0,0,0,0.04), 0 4px 16px 0 rgba(0,0,0,0.08)',
  palette: {
    ...baseThemeProperties.palette,
    cardBackground: {color: 'unset'},
    boxShadowStyle: 'unset',
    type: 'dark',
    primary: {
      main: '#419CFF',
      dark: '#0055B2',
      light: '#3394FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#999999',
      dark: '#FFFFFF',
      light:'#FFFFFF'
    },
    error: {
      main: '#EF2D34',
      dark: '#FF7C81',
      light: '#FFD4D6'
    },
    background: {
      default: '#0C0C0C',
      paper: '#0C0C0C',
    },
    green: {
      color: '#57EBC0',
      textColor: '#FFFFFF'
    },
    lightgreen:{
      color:'#00614580',
      textColor:'#57EBC0'
    },
    lightblue: {
      color:'#00499980',
      textColor:'#FFFFFF'
    },
    yellow: {
      color:'#FF9F00'
    },
    lightyellow: {
      color:'#FF9F0024'
    },
    red: {
      color:'#FF7C81',
      textColor: '#FFFFFF'
    },
    lightred:{
      color:'#87101550',
    },
    grey: {
      color:'rgba(255,255,255,0.75)'
    },
    lightgrey: {
      color:'rgba(255,255,255,0.12)'
    },
    extralightgrey: {
      color:'rgba(255,255,255,0.05)'
    },
    extralightgreyBackground: {
      color:'rgba(255,255,255,0.12)'
    },
    purple: {
      color: '#E65CE6'
    },
    lightpurple: {
      color: '#7A1F7A30'
    },
    cyan: {
      color: '#00D3FF'
    },
    lightcyan: {
      color: '#00CCFF24'
    },
    lightorange: {
      color: '#D7890824'
    },
    lightgreyBackgroundSolid: {
      color:'#333333'
    },
    black: {
      color:'#FFFFFF'
    },
    gold: {
      color: '#E6C200'
    },
    harlequin: {
      color: '#3cd10b'
    },
    lightharlequin: {
      color: '#e8ffe030'
    },
    premium: {
      color: '#474644',
    },
    lightpremium:{
      color: '#DEDDDB',
    },
  },
})

const accuracyAccountsDark = createTheme({
  ...baseThemeProperties,
  boxShadowStyle: '0 1px 4px 0 rgba(0,0,0,0.04), 0 4px 16px 0 rgba(0,0,0,0.08)',
  palette: {
    ...baseThemeProperties.palette,
    cardBackground: {color: 'unset'},
    boxShadowStyle: 'unset',
    type: 'dark',
    primary: {
      main: '#419CFF',
      dark: '#0055B2',
      light: '#3394FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#999999',
      dark: '#FFFFFF',
      light:'#FFFFFF'
    },
    error: {
      main: '#EF2D34',
      dark: '#FF7C81',
      light: '#FFD4D6'
    },
    background: {
      default: '#0C0C0C',
      paper: '#0C0C0C',
    },
    green: {
      color: '#57EBC0',
      textColor: '#FFFFFF'
    },
    lightgreen:{
      color:'#00614580',
      textColor:'#57EBC0'
    },
    lightblue: {
      color:'#00499980',
      textColor:'#FFFFFF'
    },
    yellow: {
      color:'#FF9F00'
    },
    lightyellow: {
      color:'#FF9F0024'
    },
    red: {
      color:'#FF7C81',
      textColor: '#FFFFFF'
    },
    lightred:{
      color:'#87101550',
    },
    grey: {
      color:'rgba(255,255,255,0.75)'
    },
    lightgrey: {
      color:'rgba(255,255,255,0.12)'
    },
    extralightgrey: {
      color:'rgba(255,255,255,0.05)'
    },
    extralightgreyBackground: {
      color:'rgba(255,255,255,0.12)'
    },
    purple: {
      color: '#E65CE6'
    },
    lightpurple: {
      color: '#7A1F7A30'
    },
    cyan: {
      color: '#00D3FF'
    },
    lightcyan: {
      color: '#00CCFF24'
    },
    lightorange: {
      color: '#D7890824'
    },
    lightgreyBackgroundSolid: {
      color:'#333333'
    },
    black: {
      color:'#FFFFFF'
    },
    gold: {
      color: '#E6C200'
    },
    harlequin: {
      color: '#3cd10b'
    },
    lightharlequin: {
      color: '#e8ffe030'
    },
    premium: {
      color: '#474644',
    },
    lightpremium:{
      color: '#DEDDDB',
    },
  },
})

const regalxDark = createTheme({
  ...baseThemeProperties,
  boxShadowStyle: '0 1px 4px 0 rgba(0,0,0,0.04), 0 4px 16px 0 rgba(0,0,0,0.08)',
  palette: {
    ...baseThemeProperties.palette,
    boxShadowStyle: 'unset',
    cardBackground: {color: 'unset'},
    type: 'dark',
    primary: {
      main: '#419CFF',
      dark: '#0055B2',
      light: '#3394FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#999999',
      dark: '#FFFFFF',
      light:'#FFFFFF'
    },
    error: {
      main: '#EF2D34',
      dark: '#FF7C81',
      light: '#FFD4D6'
    },
    background: {
      default: '#0C0C0C',
      paper: '#0C0C0C',
    },
    green: {
      color: '#57EBC0',
      textColor: '#FFFFFF'
    },
    lightgreen:{
      color:'#00614580',
      textColor:'#57EBC0'
    },
    lightblue: {
      color:'#00499980',
      textColor:'#FFFFFF'
    },
    yellow: {
      color:'#FF9F00'
    },
    lightyellow: {
      color:'#FF9F0024'
    },
    red: {
      color:'#FF7C81',
      textColor: '#FFFFFF'
    },
    lightred:{
      color:'#87101550',
    },
    grey: {
      color:'rgba(255,255,255,0.75)'
    },
    lightgrey: {
      color:'rgba(255,255,255,0.12)'
    },
    extralightgrey: {
      color:'rgba(255,255,255,0.05)'
    },
    extralightgreyBackground: {
      color:'rgba(255,255,255,0.12)'
    },
    black: {
      color:'#FFFFFF'
    },
    purple: {
      color: '#E65CE6'
    },
    lightpurple: {
      color: '#7A1F7A30'
    },
    cyan: {
      color: '#00D3FF'
    },
    lightcyan: {
      color: '#00CCFF24'
    },
    lightorange: {
      color: '#D7890824'
    },
    lightgreyBackgroundSolid: {
      color:'#333333'
    },
    gold: {
      color: '#E6C200'
    },
    harlequin: {
      color: '#3cd10b'
    },
    lightharlequin: {
      color: '#e8ffe030'
    },
    premium: {
      color: '#474644',
    },
    lightpremium:{
      color: '#DEDDDB',
    },
  },
})

const bdsTradingDark = createTheme({
  boxShadowStyle: 'unset',
  ...baseThemeProperties,
  palette: {
    boxShadowStyle: 'unset',
    ...baseThemeProperties.palette,
    type: 'dark',
    cardBackground: {color: 'unset'},
    primary: {
      main: '#419CFF',
      dark: '#0055B2',
      light: '#3394FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#999999',
      dark: '#FFFFFF',
      light:'#FFFFFF'
    },
    error: {
      main: '#EF2D34',
      dark: '#FF7C81',
      light: '#FFD4D6'
    },
    background: {
      default: '#0C0C0C',
      paper: '#0C0C0C',
    },
    green: {
      color: '#57EBC0',
      textColor: '#FFFFFF'
    },
    lightgreen:{
      color:'#00614580',
      textColor:'#57EBC0'
    },
    lightblue: {
      color:'#00499980',
      textColor:'#FFFFFF'
    },
    yellow: {
      color:'#FF9F00'
    },
    lightyellow: {
      color:'#FF9F0024'
    },
    red: {
      color:'#FF7C81',
      textColor: '#FFFFFF'
    },
    lightred:{
      color:'#87101550',
    },
    grey: {
      color:'rgba(255,255,255,0.75)',
    },
    lightgrey: {
      color:'rgba(255,255,255,0.12)'
    },
    extralightgrey: {
      color:'rgba(255,255,255,0.05)'
    },
    extralightgreyBackground: {
      color:'rgba(255,255,255,0.12)'
    },
    black: {
      color:'#FFFFFF'
    },
    purple: {
      color: '#E65CE6'
    },
    lightpurple: {
      color: '#7A1F7A30'
    },
    cyan: {
      color: '#00D3FF'
    },
    lightcyan: {
      color: '#00CCFF24'
    },
    lightorange: {
      color: '#D7890824'
    },
    lightgreyBackgroundSolid: {
      color:'#333333'
    },
    gold: {
      color: '#E6C200'
    },
    harlequin: {
      color: '#3cd10b'
    },
    lightharlequin: {
      color: '#e8ffe030'
    },
    premium: {
      color: '#474644',
    },
    lightpremium:{
      color: '#DEDDDB',
    },
  },
})

swissMarkets.overrides = bdSwiss.overrides = accuracyAccounts.overrides = regalx.overrides = bdsTrading.overrides = lydian.overrides = tauroMarkets.overrides = {
  MuiSelect: {
    root: {
      lineHeight: '1.5rem'
    },
    selectMenu:{
      fontWeight: 300,
      fontSize: 18
    },
    select:{
      '&:focus':{
        background:bdSwiss.palette.extralightgrey.color
      }
    }
  },
  MuiInput: {
    root: {
      lineHeight: '1.5rem'
    },
    focused: {},
    disabled: {},
    error: {},
    underline:{
      '&:before':{
        borderBottom:`1px solid ${bdSwiss.palette.lightgrey.color}`
      },
      '&:after': {
        borderBottom: `1px solid ${bdSwiss.palette.secondary.main}`
      },
      '&:hover:not($disabled):not($focused):not($error):before': {
        borderBottom: `1px solid ${bdSwiss.palette.secondary.main}`
      },
      '&:hover:not($disabled):before': {
        borderBottom: `1px solid ${bdSwissDark.palette.secondary.main}`
      },
    }
  },
  MuiCard: {
    root: {
      padding: 20,
      boxShadow: '0 1px 4px 0 rgba(0,0,0,0.04), 0 4px 16px 0 rgba(0,0,0,0.08)',
      borderRadius: 8,
      '&:hover': {
        'cursor': 'pointer',
        backgroundColor: bdSwiss.palette.extralightgrey.color
      }
    },
  },
  MuiGrid: {
    'spacing-xs-3': { // Name of the rule
      width: '100%',
      margin:0,
    },
    'spacing-xs-1': {
      width: '100%',
      margin:0,
    },
    'spacing-xs-5': { // Name of the rule
      width: '100%',
      margin:0,
    },
  },
  MuiTypography: {
    h1: {
      fontSize: '2.1rem',
    },
    h2:{
      fontSize: '2rem'
    },
    h3: {
      color: bdSwiss.palette.black.color,
      fontSize: 24,
      fontWeight: 300,
      lineHeight: '42px',
      marginTop: 30,
      marginBottom: 20,
    },
    h4: {
      fontSize: '1.3rem',
      fontWeight: 300,
    },
    h5: {
      fontWeight: 300,
      fontSize: 18,
    },
    subtitle1: {
      color: bdSwiss.palette.black.color,
      fontWeight: 300,
      fontSize: 18,
    },
    subtitle2: {
      color: bdSwiss.palette.black.color,
      fontWeight: 300,
      fontSize: 14,
    },
    caption: {
      color: bdSwiss.palette.secondary.main,
      fontSize:'0.85rem',
      display: 'block',
    },
    body1 :{
      color: bdSwiss.palette.black.color,
      fontSize: 16,
      lineHeight: '1.8rem',
      letterSpacing: 'normal',
      fontWeight: 300,
      '&a':{
        fontWeight: 'normal',
      },
    },
    body2 :{
      color: bdSwiss.palette.black.color,
      fontSize: 16,
      lineHeight: '2.5rem',
      '&a':{
        fontWeight: 500
      },
      [bdSwiss.breakpoints.down('sm')]: {
        lineHeight: '1.5rem',
      }
    }
  },
  MuiTabs:{
    root: {
      marginBottom: 35
    },
    indicator: {
      backgroundColor: bdSwiss.palette.red.color,
    },
    flexContainer:{
      borderBottom: '1px solid #eee'
    },
  },
  MuiTab: {
    root: {
      textTransform: 'initial',
      color: bdSwiss.palette.secondary.main,
      fontSize:15,
      fontWeight:400,
      minWidth:10,
      [bdSwiss.breakpoints.up('md')]: {
        color: bdSwiss.palette.secondary.main,
        fontSize:15,
        minWidth:10,
        padding: '20px 24px',
      },
    },
    selected:{
      color: bdSwiss.palette.black.color,
      backgroundColor: 'transparent',
    },
    textColorInherit:{
      color: bdSwiss.palette.secondary.main,
      opacity:1
    },
    labelIcon:{
      paddingTop:20,
      paddingBottom:20
    },
    fullWidth: {
      flexBasis: 'auto'
    },
  },
  MuiTableCell: {
    body: {
      fontSize: 15,
      fontWeight: 300,
    },
    head: {
      color: bdSwiss.palette.secondary.main,
      fontSize: 14,
      textTransform: 'none',
      fontWeight:400
    },
    paddingCheckbox: {
      width: 'unset'
    }
  },
  MuiListItemText:{
    root:{
      fontSize:'16px',
    },
  },
  MuiListItem:{
    root:{
      '&$selected':{
        backgroundColor: bdSwiss.palette.extralightgrey.color
      },
      '&$selected:hover':{
        backgroundColor: bdSwiss.palette.extralightgrey.color
      },
      paddingTop: 11,
      paddingBottom: 11,
    },
    button:{
      '&:hover':{
        backgroundColor: bdSwiss.palette.extralightgrey.color
      }
    },
    selected:{
      backgroundColor: bdSwiss.palette.extralightgrey.color
    }
  },
  MuiButton: {
    root:{
      textTransform: 'none',
      fontWeight:'normal',
      borderRadius: 6,
    },
    sizeLarge: {
      'padding': '10px 50px',
      fontSize:'1rem'
    },
    contained:{
      color: bdSwiss.palette.grey.color,
      boxShadow: bdSwiss.boxShadowStyle,
      '&:hover': {
        backgroundColor: bdSwiss.palette.extralightgrey.color,
        '@media (hover:none)': {
          backgroundColor: bdSwiss.palette.extralightgrey.color,
          boxShadow: 'none',
        }
      },
    },
    containedSecondary: {
      color: bdSwiss.palette.grey.color,
      backgroundColor: bdSwiss.palette.secondary.light,
      '&:hover': {
        backgroundColor: bdSwiss.palette.extralightgrey.color,
      },
    },
  },
  MuiFormControlLabel:{
    label: {
      fontWeight: 300,
    }
  },
  MuiDialogTitle:{
    root: {
      textAlign:'center'
    }
  },
  MuiDialogContent:{
    root: {
      fontSize:16,
      fontWeight:300,
      padding: '0 24px 24px',
    }
  },
  MuiDialog:{
    paperFullScreen:{
      flexDirection: 'unset',
      [bdSwiss.breakpoints.down('sm')]: {
        display:'block'
      }
    }
  },
  MuiPaper:{
    elevation1: {
      boxShadow: bdSwiss.boxShadowStyle
    },
    elevation2: {
      boxShadow: bdSwiss.boxShadowStyle
    }
  },
  MuiTooltip:{
    tooltip:{
      backgroundColor: bdSwiss.palette.secondary.dark
    }
  },
  MuiFormLabel:{
    root: {
      color: bdSwiss.palette.grey[500],
    }
  },
  MuiFab:{
    root: {
      '&:hover': {
        backgroundColor:'#fafafa'
      }
    }
  },
  MuiInputBase: {
    input: {
      '&:-webkit-autofill': {
        transitionDelay: '9999s',
        transitionProperty: 'background-color, color',
        backgroundColor: bdSwissDark.palette.background.default,
      },
      height: 'unset',
    },
  },
  MuiBottomNavigationAction: {
    label: {
      '&.Mui-selected': {
        color: `${bdSwiss.palette.primary.main} !important`
      }
    }
  }
}

swissMarketsDark.overrides = bdSwissDark.overrides = accuracyAccountsDark.overrides = regalxDark.overrides = bdsTradingDark.overrides = tauroMarketsDark.overrides ={
  ...baseThemeProperties,
  MuiSelect: {
    root: {
      lineHeight: '1.5rem',
    },
    selectMenu:{
      fontWeight: 300,
      fontSize: 18,
    },
    select:{
      '&:focus':{
        backgroundColor: bdSwissDark.palette.background.default
      },
      '& option':{
        backgroundColor: bdSwissDark.palette.background.paper
      }
    }
  },
  MuiInput: {
    root: {
      lineHeight: '1.5rem',
    },
    focused: {},
    disabled: {},
    error: {},
    underline:{
      '&:before':{
        borderBottom:`1px solid ${bdSwissDark.palette.lightgrey.color}`
      },
      '&:after': {
        borderBottom: `1px solid ${bdSwissDark.palette.secondary.main}`
      },
      '&:hover:not($disabled):not($focused):not($error):before': {
        borderBottom: `1px solid ${bdSwissDark.palette.secondary.main}`
      },
      '&:hover:not($disabled):before': {
        borderBottom: `1px solid ${bdSwissDark.palette.secondary.main}`
      },
    }
  },
  MuiCard: {
    root: {
      padding: 20,
      boxShadow: '0 1px 4px 0 rgba(0,0,0,0.04), 0 4px 16px 0 rgba(0,0,0,0.08)',
      backgroundColor: bdSwissDark.palette.extralightgrey.color,
      borderRadius: 8,
      '&:hover': {
        'cursor': 'pointer',
        backgroundColor: bdSwissDark.palette.lightgrey.color
      }
    },
  },
  MuiGrid: {
    'spacing-xs-3': { // Name of the rule
      width: '100%',
      margin:0,
    },
    'spacing-xs-1': {
      width: '100%',
      margin:0,
    },
    'spacing-xs-5': { // Name of the rule
      width: '100%',
      margin:0,
    },
  },
  MuiTypography: {
    h1: {
      fontSize: '2.1rem',
      color: bdSwissDark.palette.black.color,
    },
    h2:{
      fontSize: '2rem',
      color: bdSwissDark.palette.black.color,
    },
    h3: {
      color: bdSwissDark.palette.black.color,
      fontSize: 24,
      fontWeight: 300,
      lineHeight: '42px',
      marginTop: 30,
      marginBottom: 20,
    },
    h4: {
      fontSize: '1.3rem',
      fontWeight: 300,
    },
    h5: {
      fontWeight: 300,
      fontSize: 18,
    },
    subtitle1: {
      color: bdSwissDark.palette.black.color,
      fontWeight: 300,
      fontSize: 18,
    },
    subtitle2: {
      color: bdSwissDark.palette.black.color,
      fontSize: 14,
    },
    caption: {
      color: bdSwissDark.palette.secondary.main,
      fontSize:'0.85rem',
      display: 'block',
    },
    body1 :{
      color: bdSwissDark.palette.black.color,
      fontSize: 16,
      lineHeight: '1.8rem',
      letterSpacing: 'normal',
      fontWeight: 300,
      '&a':{
        fontWeight: 400
      }
    },
    body2 :{
      color: bdSwissDark.palette.black.color,
      fontSize: 16,
      lineHeight: '2.5rem',
      '&a':{
        fontWeight: 500
      },
      [bdSwissDark.breakpoints.down('sm')]: {
        lineHeight: '1.5rem',
      }
    },
  },
  MuiTabs:{
    root: {
      marginBottom: 35
    },
    indicator: {
      backgroundColor: bdSwissDark.palette.red.color,
    },
    flexContainer:{
      boxShadow: 'inset 0 -1px 0 0 rgba(255,255,255,0.24)'
    },
  },
  MuiTab: {
    root: {
      textTransform: 'initial',
      color: bdSwissDark.palette.secondary.main,
      fontSize:15,
      fontWeight:400,
      minWidth:10,
      [bdSwiss.breakpoints.up('md')]: {
        color: bdSwissDark.palette.secondary.main,
        fontSize:15,
        minWidth:10,
        padding: '20px 24px',
      }
    },
    selected:{
      color: bdSwissDark.palette.black.color,
      backgroundColor: 'transparent',
    },
    textColorInherit:{
      color: bdSwissDark.palette.secondary.main,
      opacity:1
    },
    labelIcon:{
      paddingTop:20,
      paddingBottom:20
    },
    fullWidth: {
      flexBasis: 'auto'
    },
  },
  MuiTableCell: {
    body: {
      fontSize: 15,
      fontWeight: 300,
    },
    head: {
      color: bdSwissDark.palette.secondary.dark,
      fontSize: 14,
      textTransform: 'none',
      fontWeight:400
    },
    paddingCheckbox: {
      width: 'unset'
    }
  },
  MuiListItemText:{
    root:{
      fontSize:'16px',
    }
  },
  MuiListItem:{
    root:{
      '&$selected':{
        backgroundColor: bdSwissDark.palette.extralightgrey.color
      },
      '&$selected:hover':{
        backgroundColor: bdSwissDark.palette.extralightgrey.color
      },
      paddingTop: 11,
      paddingBottom: 11,
    },
    button:{
      '&:hover':{
        backgroundColor: bdSwissDark.palette.extralightgrey.color
      }
    },
    selected:{
      backgroundColor: bdSwissDark.palette.extralightgrey.color,
    }
  },
  MuiBackdrop: {
    root:{
      backgroundColor:  bdSwissDark.palette.extralightgrey.color,
    }
  },
  MuiButton: {
    root:{
      textTransform: 'none',
      fontWeight:'normal',
      borderRadius: 6,
    },
    sizeLarge: {
      'padding': '10px 50px',
      fontSize:'1rem'
    },
    contained:{
      color: bdSwissDark.palette.grey.color,
      backgroundColor:bdSwissDark.palette.lightgrey.color,
      '&:hover': {
        backgroundColor: bdSwissDark.palette.extralightgrey.color,
      },
      '&$disabled': {
        color: 'rgba(255,255,255,0.55)',
      },
    },
    containedSecondary: {
      color: bdSwissDark.palette.secondary.light,
      '&:hover': {
        backgroundColor: bdSwissDark.palette.extralightgrey.color,
      },
    },
  },
  MuiFormControlLabel:{
    label: {
      fontWeight: 300,
    }
  },
  MuiDialogTitle:{
    root: {
      textAlign:'center',
      color: bdSwissDark.palette.secondary.dark,
    }
  },
  MuiDialogContent:{
    root: {
      fontSize:16,
      fontWeight:300,
      color: bdSwissDark.palette.secondary.dark,
      padding: '0 24px 24px',
    }
  },
  MuiDialog:{
    paperFullScreen:{
      background: bdSwissDark.palette.background.paper,
      flexDirection: 'unset',
      [bdSwiss.breakpoints.down('sm')]: {
        display:'block'
      }
    },
    paper:{
      background: bdSwissDark.palette.lightgreyBackgroundSolid.color,
    }
  },
  MuiPaper:{
    root:{
      backgroundColor: bdSwissDark.palette.background.default,
    },
    elevation1: {
      boxShadowStyle: bdSwissDark.boxShadowStyle,
    },
    elevation2: {
      backgroundColor: bdSwissDark.palette.background.default,
      boxShadowStyle: bdSwissDark.boxShadowStyle,
      border: `1px solid ${ bdSwissDark.palette.lightgreyBackgroundSolid.color}`
    },
    elevation8: {
      boxShadowStyle: bdSwissDark.boxShadowStyle,
      border: `1px solid ${ bdSwissDark.palette.lightgreyBackgroundSolid.color}`
    }
  },
  MuiTooltip:{
    tooltip:{
      backgroundColor: bdSwissDark.palette.background.default,
    }
  },
  MuiFormLabel:{
    root: {
      color: bdSwissDark.palette.grey[500],
    }
  },
  MuiLinearProgress: {
    root: {
      height: 2,
    },
    colorPrimary: {
      backgroundColor: 'rgba(255,255,255,0.24)'
    },
  },
  MuiInputBase: {
    input: {
      '&:-webkit-autofill': {
        transitionDelay: '9999s',
        transitionProperty: 'background-color, color',
        backgroundColor: bdSwissDark.palette.background.default,
      },
      height: 'unset',
    },
  },
  MuiFab:{
    root: {
      '&:hover': {
        backgroundColor:'rgba(255,255,255,0.05)'
      }
    }
  },
}

const rtlOverrides = {
  MuiFormControlLabel:{
    root:{
      marginLeft: 16,
      marginRight: -14
    }
  },
  MuiInputLabel:{
    formControl:{
      right:0
    },
    shrink:{
      transformOrigin: 'top right'
    }
  },
  MuiListItem:{
    root:{
      textAlign: 'right',
      paddingTop: 11,
      paddingBottom: 11,
    },
  },
  MuiListItemIcon:{
    root:{
      marginLeft: 16,
      marginRight: 0
    }
  },
  MuiSwitch:{
    checked: {
      transform: 'translateX(-14px)'
    }
  },
  MuiLinearProgress:{
    root:{
      transform: 'scaleX(-1)'
    }
  },
  MuiSelect:{
    select:{
      paddingLeft: 32,
      paddingRight: 0
    },
    icon: {
      left: 0,
      right: 'unset'
    }
  },
  MuiInputBase: {
    input: {
      '&:-webkit-autofill': {
        transitionDelay: '9999s',
        transitionProperty: 'background-color, color',
        backgroundColor: bdSwissDark.palette.background.default,
      },
      height: 'unset',
    },
  },
}
const tauroMarketsRtlOverrides = {
  ...rtlOverrides,
  MuiCssBaseline: {
    '@global': {
      html: {
        '& *': {
          fontFamily: 'GothicArabicBold !important',
        },
      },
    },
  },
  MuiTypography: {
    h1: {
      fontFamily: 'GothicArabicHeavy !important'
    },
  },
}

let appThemes
if (isMobile()) {
  const mobileOverrides = {
    MuiTypography: {
      h1: {
        fontSize: '1.8rem',
      },
      h3: {
        fontSize: 18,
        fontWeight: 300,
        lineHeight: '32px',
        marginTop: 10,
        marginBottom: 5,
      },
      h4: {
        fontSize: '1.1rem'
      },
      h5:{
        fontSize:16
      },
      subtitle1:{
        fontSize:15,
        lineHeight: 1.4,
      },
      subtitle2:{
        fontSize:12
      },
      body1: {
        fontSize: 14,
        letterSpacing: 'normal'
      },
      body2: {
        fontSize: 14,
      },
      caption: {
        fontSize: 11,
        lineHeight: 1.5,
        display: 'block',
      }
    },
    MuiInput: {
      root: {
        lineHeight: 'auto'
      },
    },
    MuiTabs:{
      root: {
        marginTop: 0,
        marginBottom: 0
      },
      flexContainer:{
        borderBottom: 'none'
      },
    },
    MuiCard: {
      root: {
        padding: 15
      },
    },
    MuiDialog:{
      paper:{
        margin:25
      }
    },
    MuiFormLabel:{
      root: {
        fontSize: 14
      }
    },
  }
  const bdswissMobile = deepmerge(bdSwiss, {overrides: mobileOverrides})
  const bdswissDarkMobile = deepmerge(bdSwissDark, {overrides: mobileOverrides})
  const swissMarketsMobile = deepmerge(swissMarkets, {overrides: mobileOverrides})
  const swissMarketsDarkMobile = deepmerge(swissMarketsDark, {overrides: mobileOverrides})
  const accuracyAccountsMobile = deepmerge(accuracyAccounts, {overrides: mobileOverrides})
  const accuracyAccountsDarkMobile = deepmerge(accuracyAccounts, {overrides: mobileOverrides})
  const regalxMobile = deepmerge(regalx, {overrides: mobileOverrides})
  const regalxDarkMobile = deepmerge(regalx, {overrides: mobileOverrides})
  const bdsTradingMobile = deepmerge(bdsTrading, {overrides: mobileOverrides})
  const bdsTradingDarkMobile = deepmerge(bdsTradingDark, {overrides: mobileOverrides})
  let bdswissMobileRtl = {...bdswissMobile, direction:'rtl'}
  bdswissMobileRtl = deepmerge(bdswissMobileRtl, {overrides: rtlOverrides})
  let bdswissDarkMobileRtl = {...bdswissDarkMobile, direction:'rtl'}
  bdswissDarkMobileRtl = deepmerge(bdswissDarkMobileRtl, {overrides: rtlOverrides})
  let swissMarketsMobileRtl = {...swissMarketsMobile, direction:'rtl'}
  swissMarketsMobileRtl = deepmerge(swissMarketsMobileRtl, {overrides: rtlOverrides})
  let swissMarketsDarkMobileRtl = {...swissMarketsDarkMobile, direction:'rtl'}
  swissMarketsDarkMobileRtl = deepmerge(swissMarketsDarkMobileRtl, {overrides: rtlOverrides})
  let accuracyAccountsMobileRtl = {...accuracyAccountsMobile, direction:'rtl'}
  accuracyAccountsMobileRtl = deepmerge(accuracyAccountsMobileRtl, {overrides: rtlOverrides})
  let accuracyAccountsDarkMobileRtl = {...accuracyAccountsDarkMobile, direction:'rtl'}
  accuracyAccountsDarkMobileRtl = deepmerge(accuracyAccountsDarkMobileRtl, {overrides: rtlOverrides})
  let regalxMobileRtl = {...regalxMobile, direction:'rtl'}
  regalxMobileRtl = deepmerge(regalxMobileRtl, {overrides: rtlOverrides})
  let regalxDarkMobileRtl = {...regalxDarkMobile, direction:'rtl'}
  regalxDarkMobileRtl = deepmerge(regalxDarkMobileRtl, {overrides: rtlOverrides})
  let bdsTradingMobileRtl = {...bdsTradingMobile, direction:'rtl'}
  bdsTradingMobileRtl = deepmerge(bdsTradingMobileRtl, {overrides: rtlOverrides})
  let bdsTradingDarkMobileRtl = {...bdsTradingDarkMobile, direction:'rtl'}
  bdsTradingDarkMobileRtl = deepmerge(bdsTradingDarkMobileRtl, {overrides: rtlOverrides})
  appThemes = {swissMarkets: swissMarketsMobile, swissMarketsDark: swissMarketsDarkMobile,
    bdSwiss: bdswissMobile, bdSwissDark: bdswissDarkMobile,
    accuracyAccounts: accuracyAccountsMobile, accuracyDarkAccounts: accuracyAccountsDarkMobile,
    regalx: regalxMobile, regalxDarkAccounts: regalxDarkMobile, bdsTrading: bdsTradingMobile,
    bdsTradingDark: bdsTradingDarkMobile,
    bdSwissRtl: bdswissMobileRtl, swissMarketsRtl: swissMarketsMobileRtl , bdSwissDarkRtl: bdswissDarkMobileRtl, accuracyAccountsRtl: accuracyAccountsMobileRtl,
    accuracyAccountsDarkRt: accuracyAccountsDarkMobileRtl, swissMarketsDarkRtl: swissMarketsDarkMobileRtl, regalxRtl: regalxMobileRtl,
    regalxDarkRtl: regalxDarkMobileRtl, bdsTradingRtl: bdsTradingMobileRtl,
    bdsTradingDarkRtl: bdsTradingDarkMobileRtl,
    skyGround: bdswissMobile, skyGroundDark: bdswissDarkMobile,
    equityFlow: bdswissMobile, equityFlowDark: bdswissDarkMobile,
    lydian: bdswissMobile, lydianDark: bdswissDarkMobile,
    tauroMarkets: bdswissMobile, tauroMarketsDark: bdswissDarkMobile, tauroMarketsRtl: bdswissMobileRtl, tauroMarketsDarkRtl: bdswissDarkMobileRtl,
    tauroMarketsMauritius: tauroMarkets,
    tauroMarketsMauritiusDark:tauroMarketsDark,
  }
} else {
  let bdSwissRtl = {...bdSwiss, direction:'rtl'}
  bdSwissRtl = deepmerge(bdSwissRtl, {overrides: rtlOverrides})
  let bdSwissDarkRtl = {...bdSwissDark, direction:'rtl'}
  bdSwissDarkRtl = deepmerge(bdSwissDarkRtl, {overrides: rtlOverrides})
  let swissMarketsRtl = {...swissMarkets, direction:'rtl'}
  swissMarketsRtl = deepmerge(swissMarketsRtl, {overrides: rtlOverrides})
  let swissMarketsDarkRtl = {...swissMarketsDark, direction:'rtl'}
  swissMarketsDarkRtl = deepmerge(swissMarketsDarkRtl, {overrides: rtlOverrides})
  let accuracyAccountsRtl = {...accuracyAccounts, direction:'rtl'}
  accuracyAccountsRtl = deepmerge(accuracyAccountsRtl, {overrides: rtlOverrides})
  let accuracyAccountsDarkRtl = {...accuracyAccountsDark, direction:'rtl'}
  accuracyAccountsDarkRtl = deepmerge(accuracyAccountsDarkRtl, {overrides: rtlOverrides})
  let regalxRtl = {...regalx, direction:'rtl'}
  regalxRtl = deepmerge(regalxRtl, {overrides: rtlOverrides})
  let regalxDarkRtl = {...regalxDark, direction:'rtl'}
  regalxDarkRtl = deepmerge(regalxDarkRtl, {overrides: rtlOverrides})
  let bdsTradingRtl = {...bdsTrading, direction:'rtl'}
  bdsTradingRtl = deepmerge(bdsTradingRtl, {overrides: rtlOverrides})
  let bdsTradingDarkRtl = {...bdsTradingDark, direction:'rtl'}
  bdsTradingDarkRtl = deepmerge(bdsTradingDarkRtl, {overrides: rtlOverrides})
  let lydianRtl = {...lydian, direction:'rtl'}
  lydianRtl = deepmerge(bdSwissRtl, {overrides: lydianRtl})
  let tauroMarketsRtl = {...tauroMarkets, direction:'rtl'}
  tauroMarketsRtl = deepmerge(tauroMarketsRtl, {overrides: tauroMarketsRtlOverrides})
  let tauroMarketsDarkRtl = {...tauroMarketsDark, direction:'rtl'}
  tauroMarketsDarkRtl = deepmerge(tauroMarketsDarkRtl, {overrides: tauroMarketsRtlOverrides})

  appThemes = {
    // swissMarkets
    swissMarkets,
    swissMarketsDark,
    swissMarketsRtl,
    swissMarketsDarkRtl,
    // bdSwiss
    bdSwiss,
    bdSwissDark,
    bdSwissRtl,
    bdSwissDarkRtl,
    // accuracyAccounts
    accuracyAccounts,
    accuracyAccountsDark,
    accuracyAccountsRtl,
    accuracyAccountsDarkRtl,
    // regalx
    regalx,
    regalxDark,
    regalxRtl,
    regalxDarkRtl,
    // bdsTrading
    bdsTrading,
    bdsTradingDark,
    bdsTradingRtl,
    bdsTradingDarkRtl,
    // skyGround
    skyGround: bdSwiss,
    skyGroundDark: bdSwissDark,
    // equityFlow
    equityFlow: bdSwiss,
    equityFlowDark: bdSwissDark,
    // lydian
    lydian,
    lydianRtl,
    // tauroMarkets
    tauroMarkets,
    tauroMarketsDark,
    tauroMarketsRtl,
    tauroMarketsDarkRtl,
    // tauroMarketsMauritius
    tauroMarketsMauritius: tauroMarkets,
    tauroMarketsMauritiusDark:tauroMarketsDark,
    tauroMarketsMauritiusRtl:tauroMarketsRtl,
    tauroMarketsMauritiusDarkRtl:tauroMarketsDarkRtl,

  }
}

export default appThemes
