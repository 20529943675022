export const getPartnerMigrationInitialState = () => ({
  form: {
    email: '',
    password: '',
    acceptTerms: '',
  },
  errors: {
    emailExists: false,
    emailNotValidDomain: false,
    affiliateEmailExists: false,
    closedAccount: false,
    acceptTerms: false,},
  showMigrationPopup: true,
  status: '',
  loading: false,
  showPassword: false,
  passwordError: '',
  submitMessageError: ''
}
)
