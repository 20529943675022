import styled from '@emotion/styled'

export const Info = styled.div`
  background: #E8F7FE;
  border-radius: 0.5rem;
  color: #1C212E;
  font-family: Roboto;
  font-size: 0.875rem;
  padding: 1rem;
`
