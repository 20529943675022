import styled from '@emotion/styled'
import {ReactComponent as Tick} from './assets/tick.svg'

const TickIconContainer = styled.div`
  align-items: center;
  border-radius: 50%;
  border: 1.714px solid #4CAF50;
  display: flex;
  height: 72px;
  min-height: 72px;
  justify-content: center;
  width: 72px;

  & > svg {
    height: 19.038px;
    width: 26.664px;
  }
`

export const TickIcon = () => (
  <TickIconContainer>
    <Tick />
  </TickIconContainer>
)
