import styled from '@emotion/styled'

export const Container = styled.div`
  align-items: flex-start;
  border-radius: 0.375rem;
  box-shadow: -4px 4px 22px -2px rgba(67, 67, 67, 0.15);
  display: flex;
  flex-direction: row;
  gap: 2.75rem;
  padding: 1rem;

  & > div:first-child {
    min-width: 3rem;
  }

  & > div:nth-child(2) {
    flex-basis: 18%;
  }
`
