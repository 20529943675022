/* eslint-disable no-unused-vars */
export enum ActionType {
  Click = 'Click',
  CloseModal = 'CloseModal',
}

type ClickAction = {
  type: ActionType.Click;
}

type CloseModalAction = {
  type: ActionType.CloseModal;
}

export type Action = ClickAction | CloseModalAction;
