import {PropsWithChildren} from 'react'
import {Container} from './components/Container'
import {Presentation} from './components/CheckedPresentation'

export const Checkbox = ({children} : PropsWithChildren<unknown>) => (
  <Container>
    <input type="checkbox" />
    <Presentation />
    {children}
  </Container>
)
