import {Trans, withNamespaces, WithNamespaces} from 'react-i18next'
import messages from '../../../../../assets/messages'
import {Overlay} from '../components/shared/Overlay'
import {Modal} from '../components/shared/Modal'
import {ModalHeader} from '../components/shared/ModalHeader'
import {ModalBody} from '../components/shared/ModalBody'
import {Button} from '@material-ui/core'
import {useHistory} from 'react-router-dom'
import {Notes} from './components/Notes'
import {Content} from './components/Content'
import {logoutRequest} from '../../../../../common/utils/requests'
import {CloseButton} from '../components/shared/CloseButton'

type DifferentPartnerWarningModalProps = WithNamespaces

export const _DifferentPartnerWarningPopup = ({t}: DifferentPartnerWarningModalProps) => {
  const history = useHistory()
  const handleDismiss = () => {
    history.push('/accounts')
  }

  const handleLogout = async () => {
    await logoutRequest()
    window.location.href = '/register'
  }

  return (
    <>
      <Overlay onClick={handleDismiss} />
      <Modal>
        <ModalHeader>
          <Trans {...messages.differentPartnerWarningHeader} />
        </ModalHeader>
        <ModalBody>
          <Content>
            <p>
              <Trans {...messages.differentPartnerWarningLine1} />
            </p>
            <p>
              <Trans {...messages.differentPartnerWarningLine2} />
            </p>
            <Button variant="contained" color="primary" onClick={handleLogout}>
              <Trans {...messages.signOutAndCreateNewProfile} />
            </Button>
            <Notes>
              <Trans {...messages.differentPartnerWarningNotes} />
            </Notes>
          </Content>
          <CloseButton onClick={handleDismiss} />
        </ModalBody>
      </Modal>
    </>
  )}

export const DifferentPartnerWarningPopup = withNamespaces()<DifferentPartnerWarningModalProps>(
  _DifferentPartnerWarningPopup
)
