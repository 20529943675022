import styled from '@emotion/styled'

export const Container = styled('div')`
  display: flex;
  gap: 0.5rem;
  margin: 1rem 0;
  padding-left: 2rem;
  position: relative;

  & > input[type='checkbox'] {
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
  }
`
