
import {PropsWithChildren} from 'react'
import classNames from 'classnames'
import {Trans} from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import {getAccountLabel} from '../../../../common/utils/accounts'
import {getFormattedAmount} from '../../../../common/utils'
import messages from '../../../../assets/messages'
import {AccountSearchSelectProps} from '..'
import {Account, Client} from '../../../../graphql/types'

type SingleValueSelectProps = Pick<
  AccountSearchSelectProps,
  'classes' | 'clients' | 'accounts' | 'hiddenBalance'
>;

type SingleValueProps = {
  data: {value: string | number};
  innerProps: Parameters<typeof Typography>
  locale: string
  selectProps: SingleValueSelectProps
}

export function SingleValue(props: PropsWithChildren<SingleValueProps>) {
  const {selectProps: {accounts, clients, hiddenBalance}, locale} = props
  const suggestions = accounts || clients
  const option = accounts && (suggestions.filter(suggestion => suggestion.id === props.data.value))[0] as unknown as Account
  const optionClient = clients && (suggestions.filter(suggestion => suggestion.id === props.data.value))[0] as unknown as Client
  const accountsOption = accounts && option
  const formattedBalance = accountsOption && getFormattedAmount({locale, amount: option.balance, currency: option.currency})
  const hasBalance = accountsOption && option.balance != null && (option.balance || option.balance >= 0)
  return (
    <Typography className={props.selectProps.classes.singleValue} variant={'body2'} {...props.innerProps}>
      {(accountsOption)
        ?<span className={props.selectProps.classes.fontLight}>{getAccountLabel(option, locale)}
          <span className={props.selectProps.classes.grey}> - {option.remoteId}</span>
          {!hiddenBalance && hasBalance && <span className={classNames(props.selectProps.classes.grey,props.selectProps.classes.spanBalance)}>{formattedBalance}</span>}
        </span>
        :(clients && optionClient)
          ?<span className={props.selectProps.classes.fontLight}>{optionClient.firstName} {optionClient.lastName}
            <span className={classNames(props.selectProps.classes.grey,props.selectProps.classes.spanBalance)}>
              <Trans {...messages.login} />: {optionClient.id}
            </span>
          </span>
          :(props.children==='Self')?<span className={props.selectProps.classes.fontLight}>{props.children}</span>:''
      }
    </Typography>
  )
}
